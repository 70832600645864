.story-records {
	display: flex;
	flex-direction: column;
	padding: 0 150px;
}

.--our-story-intro {
	margin: 70px 0 0 0;
	text-align: center;
}

.story-record {
	//display: flex;
	//flex-direction: row;
	align-items: center;
	min-height: 200px;
	display: grid;
	grid-template-columns: 1fr 96px 1fr;
	grid-template-areas: "desc year image";
	grid-template-rows: 1fr;
	grid-column-gap: 35px;
	padding: 16px 0;
}

.story-record__description {
	//order: 1;
	//flex: 0 1 calc(50% - 48px);
	grid-area: desc;
	font-weight: bold;
}

.story-record__year {
	text-align: center;
	//order: 2;
	grid-area: year;
	display: flex;
	height: 100%;
	flex-direction: column;
	flex: 0 1 96px;
	position: relative;
	//margin: 10px 0;
	align-items: center;
	background: url('../../img/our-story/year.png') no-repeat;
	background-size: 100%;
	background-position: center;
	color: white;
	font-weight: bold;
	line-height: 100px;
	//margin: 0px 40px;
	&:after, &:before {
		content: "";
		display: block;
		flex: 1 1 auto;
		height: 100%;
		width: 1px;
		background: url('../../img/our-story/line.svg');
	}

}

.story-record__image {
	//order: 3;
	//flex: 0 1 calc(50% - 48px);
	grid-area: image;
	max-height: 250px;
	
	img {
		height: auto;
		object-fit: contain;
	}
	display: flex;
}

.story-record:first-child {
	.story-record__year:before {
		background: none;
	}
}

.story-record:last-child {
	.story-record__year:after {
		background: none;
	}
}

.story-record:nth-child(2n+1) {
	grid-template-areas: "image year desc";
}

body.ourStory {
	@include mq($until: tablet) {
		.story-records {
			padding: 0;
		}
		.story-record {
			height: 530px; /* There's a strange bug on Safari/iOS that breaks the grid layout if this isn't here */
			grid-template-areas: 
				"year image"
				"year desc" !important;
			grid-template-columns: 64px 1fr;
			grid-template-rows: 1fr auto;
		}
		.story-record__image {
			height: auto;
			object-fit: cover;
			width: 100%;
		}
		.story-record__year {
			width: 64px;
			flex: 0 1 64px;
			background-size: 100%;
			&:after, &:before {
				
			}
		}
	}


	.home__blog-and-events {
		margin-top: 100px;
		&:before, &:after {
			background: #FFF;
		}
		background: #FFF !important;
		h3 {
			text-align: center;
		}
	}
}

.grid-row.our-story-history {

	&:before, &:after {
		content: "";
		display: block;
		position: relative;
		width: 100vw;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&:before {
		height: calc(100vw * (145/1600));
		top: calc(0 - 100vw * (145/1600));
		background-image: url('../../img/our-story/blue-top.svg');
	}

	&:after {
		height: calc(100vw * (139/1600));	
		background-image: url('../../img/our-story/blue-bottom.svg');
		bottom: calc(0 - 100vw * (139/1600));
	}

	margin-top: calc(100vw * (145/1600));
	margin-bottom: calc(100vw * (139/1600));

	background: $lightblue;
	//padding-bottom: 30px;
	h3 {
		margin: -80px 0 0 0px;
		font-size: 36px;
		text-align: center;
		@include mq($until: tablet) {
			margin: 20px 0 40px 0;
			font-size: 25px;
		}
	}

}