.grid-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 48px;
	grid-column-gap: 72px;

	@include mq($until: tablet) {
		grid-template-columns: 1fr;
		grid-row-gap: 32px;
	}

	margin-top: 64px;
}

.grid-list__item {

	@include mq($until: tablet) {
		display: none;
		&:nth-child(1), &:nth-child(2), &:nth-child(3) {
			display: block;
		}
	}

	h3 {
		color: #000;
		font-size: 20px;
		line-height: 26px;
	}

	p {
		color: $darkblue;
		font-size: 18px;
		line-height: 27px;
	}

}