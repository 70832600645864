.site-footer {
	display: grid;
	grid-template-columns: 3fr 8fr 2fr;
	padding: 110px 0 100px 0;

	grid-template-areas:
		"logo nav social"
		"copy nav social";

	@include mq($until: tablet) {
		grid-template-columns: 1fr;
		grid-template-areas: "logo" "nav" "social" "copy";
		grid-row-gap: 36px;
	}
	//border-top: 1px solid #999;

	.border {
		width: 100%;
		height: 2px;
		background: rgba(#12141A, 0.1);
		left: 0px;
		content: " ";
		display: block;
		position: relative;
		grid-column: 1 / -1;
		grid-row: 1;
		top: -60px;
	}

	ul {
		list-style-type: none;
		margin: 0;
	}

	h3 {
		font-size: 15px;
		font-weight: 600;
		margin: 0;
	}

	li a {
		font-size: 13px;
		color: $footer-grey;
	}

}

.site-footer__logo {
	grid-area: logo;

	font-size: 12px;
	color: #12141A;
	font-family: $sans;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	img {
		max-width: 155px;
	}
}

.site-footer__nav {
	grid-area: nav;
	> ul {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
    > li {
      font-weight: 600;
    }
    > li > ul > li {
      font-weight: 400;
    } 
		
		@include mq($until: tablet) {
			> li > ul {
				display: none;
			}
			> li {
				margin-bottom: 16px;
				grid-column: 1 !important;
			}
			grid-template-columns: 1fr;
		}

		> li:nth-child(1) {
			grid-column: 1;
		}

		> li:nth-child(2) {
			grid-column: 2;
		}

		> li:nth-child(3) {
			grid-column: 3;
		}

		> li > a {
			font-size: 15px;
			color: black;
		}
	}
}

/*.site-footer__nav-2 {
	grid-column: 3;
}

.site-footer__nav-3 {
	grid-column: 4;
}*/

.site-footer__social {
	grid-area: social;
	display: flex;
	align-items: flex-end;

	ul {
		display: flex;
		flex-direction: row;
		li {
			width: 32px;
			height: 32px;
			background: #ACACAC;
			margin-right: 16px;
			border-radius: 5px;
			a {
				display: block;
				width: 32px;
				height: 32px;
				font-size: 20px;
				color: #FFF;
				display: flex;
				align-items: center;
				justify-content: center;
				//text-indent: -999em;
				position: relative;

				background-repeat: no-repeat;
				background-position: center;
				/*&:after {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0px;
					top: 0px;
					display: block;
					content: " ";
					background-repeat: no-repeat;
					background-position: center;

				}*/
			}

			&.--li a {
				background-image: url('/img/li.png');
			}

			&.--yt a {
				background-image: url('/img/yt.png');
			}

			&.--fb a {
				background-image: url('/img/fb.png');
			}

			&.--tw a {
				background-image: url('/img/tw.png');
			}
		}
	}
}

.site-footer__copyright {
	grid-area: copy;
	font-size: 12px;
	color: #12141A;
	font-family: $sans;

}