.photo-cloud {
	display: block;
	position: absolute;
	right: 0;
  left: 0;
	height: 100%;
	z-index: -1;
	transform: scale(1.25);
  @include mq($until: tablet) {
    right: 60px; // prevents horizontal scroll
    .universityListing & {
      display: none;
    }
  }
  @include mq($until: wide) {
    right: 115px; // prevents horizontal scroll
  }
}

.photo-cloud__photo {
	overflow: hidden;
	border-radius: 50px;
	display: none; // initially
	width: 100px;
	height: 100px;
	position: absolute;
	background: $blue;
}