.home {}


.home__how-it-works {

	.grid-row__cell:nth-child(1) {
		p {
			max-width: 525px;
		}
	}

	.content-grid {
		display: grid;
		grid-template-areas:
			"arrow .    . "
			"arrow list list  "
			"arrow .   button";
		grid-template-columns: 120px 30px auto;
		grid-template-rows: 20px auto auto;
		grid-row-gap: 20px;

		@include mq($until: tablet) {
			display: none;
		}
	}

	.content-grid__arrow {

		@include mq($until: tablet) {
			display: none;
		}
		grid-area: arrow;

		background: url("/img/home/how-it-works-arrow.png") no-repeat;
		background-size: contain;
		background-position: center calc(100% - 30px);
		/*background-position: left bottom;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: baseline;*/
	}

	.content-grid__list {
margin-bottom: 24px;
		@include mq($until: tablet) {
			display: none;
		}
		grid-area: list;
		position: relative;
		//left: -
	}

	.content-grid__button {
		grid-area: button;
		
		
	}

	ol.list{
		//margin: 60px 0 40px 0;
	}

	.button {
		position: relative;
		//left: 160px;
		@include mq($until: tablet) {
			margin-top: 40px;
		  }
	}
}


.home__mid-page-cloud {
	height: 250px;
  @include mq($until: wide) {
    height: 125px;
    .grid-row__cell {
      display: none;
    }
  }
  @include mq($until: desktop) {
    height: 50px;
  }
  .photo-cloud__photo:last-child {
    opacity: 0;
  }
}


.home__for-students {

	.grid-row__cell	img {
		position: relative;
		top: -80px;

		width: 100%;
	}

	@include mq($until: tablet) {

		.grid-row__cell img {
			top: 30px;
			width: 70vw;
			right: -5vw;
		}

		.grid-row__cell:nth-child(1) {
			order: 2;
		}
		.grid-row__cell:nth-child(2) {
			order: 1;
		}

	}

	@include mq($until: wide) {
		
	}


	.content-grid {
		display: grid;
		grid-template-areas:
			".    .      . arrow"
			"list list   . arrow"
			".    button . arrow";
		grid-template-columns: auto auto 33px 230px;
		grid-template-rows: fit-content 80px;
		
		@include mq($until: tablet) {
			grid-template-columns: 1fr;
			grid-template-rows: auto auto 0px;
			grid-template-areas:
				"list"
				"button"
				"arrow";

			ol {
				margin: 0;
				padding: 0;
			}
		}
	}

	.content-grid__arrow {
		grid-area: 1 / 3 / 3 / 5;

		background: url("/img/home/for-students-arrow.png") no-repeat;
		background-size: contain;
		background-position: center calc(100% - 30px);
		/*background-position: left bottom;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: baseline;*/
	}

	.content-grid__list {
		grid-area: 1 / 1 / 1 / 5;
		position: relative;

		@include mq($from: tablet) {
			ol {
				padding: 40px 0;
			}
		}
		//left: -
	}

	.content-grid__button {
		grid-area: button;
	}

}


.home__for-universities-and-agents {
	text-align: center;
	padding-bottom: 100px;

	.section__body {
		max-width: 512px;
	}
}


.home__blog-and-events {
	padding-bottom: 150px;
}


.home__wave-divider {
	height: 225px;
	margin-top: -225px;
	@include mq($until: tablet) {
		margin-top: -100px;
    height: 150px;
	}
	background: url('/img/home/wave.png');
	background-size: cover;
	background-position: top right;
}