.faq-items {

}

body.faqs {
	.page-header__content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		.pretitle {
			color: $blue !important;
		}
		.title {
			margin: 0 0 50px 0;
			color: #000;
		}
		grid-column: 1 / -1;
		padding-bottom: 150px;
	}
}

.faq-item {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	background: #FFFFFF;

	/* Shadows */
	box-shadow: 0px 6px 20px 7px rgba(0, 0, 0, 0.1);
	border-radius: 20px;

	/* Inside auto layout */
	flex: none;
	order: 4;
	align-self: stretch;
	flex-grow: 0;
	margin: 16px 0px;

	position: relative;


	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 30px;
		top: 30px;
		width: 20px;
		height: 20px;
		background: url('../../img/faq/expand-up.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;		
	}	

	&.active {
		.faq-item__answer {
			display: block
		}
		&:after {
			background-image: url('../../img/faq/expand-down.svg');
		}
	}
}


.faq-item__question {

	height: 24px;
	
	/* Large/Heading 2 */
	font-family: $sansBold;
	font-size: 20px;
	line-height: 24px;

	/* identical to box height, or 120% */
	letter-spacing: -0.01em;

	color: #000000;

	position: relative;

}

.faq-item__answer {
	display: none;
	margin-top: 20px;
	color: #000;
}

.faq-input {
	//border: 2px solid #444;
	border-radius: 10px;
	width: 100%;
	max-width: 560px;
	margin: 0 auto;
	line-height: 50px;
	height: 50px;
	padding: 0 20px;
	font-size: 16px;
	color: #2C5D90;
	border: 3px solid #EFEFEF;
	box-sizing: border-box;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
	border-radius: 10px;
}