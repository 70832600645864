.docked-bar {
	position: sticky;
	top: 0;
	background: white;
	box-shadow: 0px 54px 80px rgba(0, 0, 0, 0.07), 0px 16.2794px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.035), 0px 2.44555px 3.62304px rgba(0, 0, 0, 0.0243888);
	padding: 1em;
	z-index: 999;
	> .container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	p, h2 {
		margin: 0;
	}
	p {
		color: $grey;
	}
	h2 {
		font-weight: bold;
		font-size: 1.5em;
	}
}