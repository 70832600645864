.become-an-agent__what {
	margin-top: 200px;

	@include mq($until: tablet) {
		

		margin-top: 30px;
		.grid-row__cell:nth-child(1) {
			order: 2;
		}
		.grid-row__cell:nth-child(2) {
			order: 1;
		}		
	}
}

.become-an-agent__why {
	margin-top: 175px;
	@include mq($until: tablet) {
		margin-top: 100px;
		.grid-row__cell:nth-child(1) {
			order: 2;
		}
		.grid-row__cell:nth-child(2) {
			order: 1;
		}		
	}	
}

.become-an-agent__benefits {
	margin-top: 114px;
	header * {
		text-align: center;
	}
	img {
		width: auto;
	}
}

.become-an-agent__reviews {
	margin-top: 144px;

	p {
		color: black;
	}
}
