.cards {
	list-style: none;
}

.card {
	background: #FFFFFF;
	outline: 3px solid #EFEFEF;
	border-radius: 10px;
	display: inline-block;
	width: 100%;
	position: relative;

	.fave-icon {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 35px;
		height: 35px;
	}

	&.--shadow {
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
	}

  &.--home {
    border: none;
    box-shadow: 0px 100px 80px rgba(0,0,0,0.1);
  }

	a > img {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.card__image {

		height: 134px;
		box-sizing: content-box;
		position: relative;
		display: block;

		img {
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.card__meta {
		display: flex;
		height: 74px;
		@include mq($until: tablet) {
			height: auto;
		}
		align-items: center;
		box-sizing: content-box;
		padding: 1em 1em;
		.card__meta-left {
			max-width: 64px;
			width: 100%;
			height: 100%;
			margin-right: 16px;
			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		} 
		.card__meta-right {
			h4,h5 {
				font-weight: bold;
				font-size: 16px;
				line-height: 130%;
				color: $dark;	
				margin: 0;
			}
			p {
				margin-top: 0;
				font-size: 0.875em;
				color: $grey;
        &.--clean {
          margin: 0;
        }
			}
			.small-meta {
				color: $grey;
				font-size: 14px;
				font-family: $sans;
			} 
		}
	}


	&.--events, &.--blog {
		.card__image {
			height: 204px;
			padding: 30px 32px 16px;
			img {
				border-radius: 5px;
			}
		}
		
		.card__meta {
			padding: 0 32px 32px;
			min-height: 96px;
			@include mq($until: tablet) {
				min-height: 0px;
			}
		}
		.card__meta-left {
			min-height: 96px;
			@include mq($until: tablet) {
				min-height: 64px;	
				margin-right: 16px;			
			}
			margin-right: 32px;
		}
		.card__meta-right {
			h5 {
				font-size: 20px;
				@include mq($until: tablet) {
					font-size: 16px;
				}
				margin: 8px 0;
			}	
		}
	}

  &.--tight {
    .card__image {
      padding: 24px 22px 14px;
    }
    .card__meta {
      padding: 0 24px 24px;
    }
  }

	&.--blog {
		.card__meta {			
			align-items: center;
		}
	}


	.event-date {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		padding: 5px 10px;
		align-items: center;
		border-style: solid;
		border-color: lightgray;
		border-radius: 10px;
		border-width: 2px;

		
		.event-date__month {
			color: #F00;
			font-size: 16px;
			line-height: 1;
			font-weight: 600;
      margin-bottom: 5px;
			text-transform: uppercase;
			font-family: $sans;
		}

		.event-date__date {
			color: black;
			font-size: 30px;
			line-height: 1;
			font-weight: 600;
			font-family: $sans;
		}
	}
	
}


.card-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-top: 40px;

	@include mq($until: tablet) {
		grid-template-columns: 1fr;
		
	}
}

// Provides a "shuffle" effect, with a left-right margin
.card-shuffle {
	background: url('/img/home/photobg.png') no-repeat;
	background-fit: contain;
	height: 100%;

	.card {
		margin-left: auto;
		margin-right: auto;
		display: block;
	width: 60%;
	margin-bottom: 20px;


	@include mq($until: tablet) {
		width: 90%;
	}

		&:nth-child(3n + 1) {
			transform: rotate(2deg);
			@include mq($until: wide) {
				transform: rotate(2deg);
			  }  
		}

		&:nth-child(3n + 2) {
			transform: rotate(-4deg) translate(25%, -10%);
      @include mq($until: wide) {
        transform: rotate(-4deg);
      }
		}

		&:nth-child(3n + 3) {
			transform: rotate(3deg) translate(-25%, -20%);
      @include mq($until: wide) {
        transform: rotate(4deg);
      }
		}
	}
}