.grid-row__cell.--center-vertical.at-a-glance {
	@include mq(mobile, tablet) {
		align-items: flex-start !important;
	}
}

.at-a-glance {
	padding: 6em;
	text-align: center;
	color: #000;
	dt, dd, li, p, li > a {
		color: #000;
	}

	dt {
		flex: 0 0 55%;
	}

	dd {
		flex: 0 0 45%;
	}

	dt.empty {
		flex: 0;
		&+dd {
			flex-basis: 100%;
		}
	}

	h3 {
		margin-top: 0;
		font-weight: 800;
		font-size: 2.25em;
		letter-spacing: -0.3959px;
		margin-bottom: 0;
	}
	table {
		width: 100%;
		max-width: 540px;
		margin: 1em auto;
		text-align: left;
		.heading {
			font-weight: bold;
			font-size: 1.25em;
		}
		.content {
			font-size: 1.3125em;
		}
	}


	&.--financials {
		//margin: 128px 0;
	}


	display: flex;
	flex-direction: row;
	text-align: left;

	@include mq(mobile, tablet) {
		flex-direction: column;
    	padding: 2em 2em 4em;
    	width: calc(100% + 64px);
    	margin-left: -32px;
    	margin-bottom: 170px;
	}

	@include mq($until: tablet) {
		margin-bottom: 30px !important;
	}

	.at-a-glance__menu {
		flex: 1 0 40%;
		@include mq(mobile, tablet) {
			flex: 1 0 auto;
			margin: 15px 0;
		}
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0px;
		}
		li {
			opacity: 0.4;



			cursor: pointer;
			transition: opacity 0.25s ease-in-out;
			a {
				font-size: 20px;
				font-family: $sansBold;
				&:after {
					position: relative;
					display: block;
					width: 100%;
					opacity: 0;
					transition: opacity 0.25s ease-in-out;
					height: 24px;
					content: "";
					background: url('../../img/underline.png') no-repeat;
					background-size: contain;
				}
			}

			&:hover, &.active {
				opacity: 1;
				a:after {
					opacity: 1;
				}
				.at-a-glance__subheading {
					opacity: 1;
				}
			}
		}
	}

	.at-a-glance__content {
		flex: 1 0 60%;
		position: relative;
		display: grid;
		grid-template-areas: "content";
		grid-template-columns: 1fr;
		grid-template-rows: auto;

	}

	.at-a-glance__subheading {
		font-size: 18px;
		margin: 10px 0 0 0;
		opacity: 0.2;
		transition: all 0.25s ease-in-out;
	}

	.at-a-glance__heading {
		font-size: 36px;
		color: #000;
		font-family: $sansBold;
		margin-bottom: 20px;
	}

	.at-a-glance__tab {
		grid-area: content;
	}

	.at-a-glance__tab:not(:first-child) {
		display: none;
	}


}