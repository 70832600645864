.listing {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;

	&.--three {
		grid-template-columns: 1fr 1fr 1fr;
		@include mq($until: tablet) {
			grid-template-columns: 1fr;
		}
	}

	grid-gap: 32px;

	@include mq($until: tablet) {
		grid-template-columns: 1fr;
	}
}