.blog-card {
  margin-bottom: 0.75rem;

	a {
		display: contents;
	}

	display: grid;

	font-family: $sans;


	h2,h3 {
		margin: 0;
		padding: 0;
	}


	.blog-card__image {
		grid-area: image;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			min-height: 128px;
		}
	}

	.blog-card__title {
		grid-area: title;
		font-size: 14px;
		line-height: 24px;
		color: #000;
		display: flex;
		margin-bottom: 4px;
	}

	.blog-card__meta {
		grid-area: meta;
		font-size: 14px;
		line-height: 1;
    font-weight: 700;
		color: $dark-grey;
		text-transform: uppercase;
		display: flex;
    padding-bottom: 4px;
	}

	&.--featured {

		width: 550px;

		@include mq(tablet) {
			width: 700px;
		}
		@include mq($until: tablet) {
			width: auto;
		}

		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		background: white;
		border: 3px solid #E7F6FA;
		border-radius: 20px;

		grid-template-areas: 
			"image image image"
			".     title ."
			".     meta  .";

		grid-template-rows: 240px minmax(min-content, 94px) auto;	
		grid-template-columns: 32px auto 32px;

		@include mq(tablet) {

			.blog-card__image img {
				//border: 1px solid #ACACAC;
				min-height: 0px;
				border-top-right-radius: 10px;
				border-top-left-radius: 10px;
			}

			.blog-card__title {
				font-size: 32px;
				padding: 40px 0 16px 0;
				line-height: 1.3;
				align-items: flex-end;
			}

			.blog-card__meta {
				font-size: 16px;
				padding-bottom: 32px;
			}

		}

		@include mq(mobile, tablet) {

			grid-template-rows: 160px auto auto;	

			margin-bottom: 36px;

			.blog-card__image img {
				//border: 1px solid #ACACAC;
				border-top-right-radius: 10px;
				border-top-left-radius: 10px;
			}

			.blog-card__title {
				font-size: 19px;
				padding: 32px 0 32px 0;
				line-height: 1;
				align-items: flex-end;
			}

			.blog-card__meta {
				font-size: 14px;
				padding-bottom: 32px;
			}
		}

	}


	&.--small {

		//margin-bottom: 16px;

		grid-template-areas: 
			"meta meta"
			"image title";

		//grid-template-rows: auto auto;
		grid-template-columns: 80px auto;
		grid-template-rows: auto minmax(min-content, 80px);
		grid-column-gap: 16px;
		grid-row-gap: 8px;

		.blog-card__image img {
			border-radius: 10px;
			height: 80px;
			width: 80px;
			min-height: 0px;
		}

		.blog-card__title {
			align-items: center;
			font-size: 14px;
			line-height: 16px;
		}





	}



	&.--standard {


		&.--border {
			padding-bottom: 32px;
			border-bottom: 1px solid #ACACAC;
		}

		grid-template-areas: 
			"image title"
			"image meta";

		grid-template-columns: 128px auto;
		grid-column-gap: 32px;

		.blog-card__image img {
			border-radius: 10px;
		}

		.blog-card__title {
			align-items: flex-end;
			font-size: 20px;
			margin-bottom: 10px;
		}

		.blog-card__meta {
			align-items: flex-start;
			font-size: 19px;
			color: $selectblue;
		}

	}
	


}

html body .blog-listing div.blog-card {

	@include mq(mobile, tablet) {
		grid-template-areas: 
			"image meta"
			"image title";

		//grid-template-rows: auto auto;
		grid-template-columns: 80px auto;
		grid-template-rows: auto minmax(min-content, 48px);
		grid-column-gap: 32px;
		grid-row-gap: 8px;

		.blog-card__image img {
			border-radius: 10px;
			height: 80px;
			width: 80px;
			min-height: 0px;
		}

		.blog-card__title {
			align-items: flex-start;
			font-size: 14px;
			line-height: 16px;
		}

		.blog-card__meta {
			align-items: flex-end;
			font-size: 13px;
			color: #65666A;
		}
	}
}