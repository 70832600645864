@include mq($from:desktop) {
	body.how-it-works .grid-container.--header,
	body.how-it-works-2 .grid-container.--header {
		background-size: 57% auto;
		background-position: bottom right;
		background-image: url('../../img/how-it-works/header.png');
		background-repeat: no-repeat;
	}
	body.become-an-agent .grid-container.--header,
	body.become-an-agent-2 .grid-container.--header {
		background-size: 640px auto;
		background-position: bottom right;
		background-image: url('../../img/become-an-agent/header.png');
		background-repeat: no-repeat;
	}

	body.our-story .grid-container.--header,
	{
		background-size: 640px auto;
		background-position: bottom right;
		background-image: url('../../img/our-story/header.png');
		background-repeat: no-repeat;
	}


	@include mq(wide) {
		html body.how-it-works .grid-container.--header,
		html body.how-it-works-2 .grid-container.--header {
			background-position: right bottom !important;
		}
		html body .grid-container.--header {
			background-position: 65% bottom !important;
		}
	}

}

@include mq($from:ultrawide) {
	body .grid-container.--header {
		background-size: auto 85% !important;
	}
}

.page-header {
	grid-column: 1 / -1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	@include mq(mobile, desktop) {
		grid-template-columns: 1fr;
	}
	width: 100%;

	
}

body.page .grid-container.--header {
	position: relative;
	&:after {
		position: absolute;
		bottom: -3px; // slight glitch with a line at bottom
		height: 50px;
		width: 100vw;
		content: "";
		display: block;
		background: url('../../img/headerwave.png') no-repeat;
		background-size: cover;
		background-position: bottom center;
	}
}

.page-header__content {
	grid-column: 1;
  	padding-bottom: 200px;
	@include mq($until: tablet) {
		grid-column: 1/-1;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 40px;
    	padding-bottom: 100px;
		.title {
			align-self: flex-start;
		}
	}
  

	&.--single {
		grid-column: 1 / -1;
	}
}

.page-header__image {
	grid-column: 2;
}

.page-header__content {
 .pretitle {
	font-size: 20px;
	line-height: 26px;
	width: 100%;
	margin: 0;
	color: $darkestblue;
	padding-top: 100px;
	padding-bottom: 20px;
	@include mq($until: tablet) {
		padding-top: 64px;
		padding-bottom: 0;
	}
	font-family: $sans;
  }
  p {
  	color: #002868 !important;
  }
}


.page-header__text {

}

.page-header .title {
	color: $darkestblue;
	font-size: 63px;
	margin: 0;
	line-height: 67.2px;
	font-family: $sansBold;
	@include mq($until: wide) {
		font-size: 55px;
		line-height: 110%;
	}
	@include mq($until: tablet) {
		font-size: 38px;
	}
}

.page-header__text {
	padding: 10px 0;
}