.pagination {
	margin: 50px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.pagination__button {
	line-height: 32px;
	font-size: 14px;
	font-weight: bold;

	display: block;
	height: 32px;
	width: 32px;
	margin: 0 2px;
	border-radius: 10px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-position: center;
	background-repeat: no-repeat;
	transition: all 0.5s ease-in-out;

	font-family: $sans;

	color: black;

	&.current, &:hover {
		background-color: $selectblue;
		color: white;
	}

	&.--prev, &.--next {
		//background: $selectblue;
		text-indent: -999em;
	}

	&.--prev {
		background-image: url('../../img/page-prev.png');
	}

	&.--next {
		background-image: url('../../img/page-next.png');
	}
}