body .protip-container.protip-mixin--css-no-transition {
  transition: all 0s; }

body .protip-container.protip-mixin--css-bold {
  font-weight: bold; }

body .protip-container.protip-mixin--css-italic {
  font-style: italic; }

body .protip-container.protip-mixin--css-no-style {
  background: none;
  color: inherit; }
  body .protip-container.protip-mixin--css-no-style .protip-arrow {
    display: none; }

.protip-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  box-sizing: border-box; }
  .protip-container > i {
    position: absolute;
    left: 10px; }
    .protip-container > i + div {
      padding-left: 20px; }

.protip-arrow {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent; }

.protip-show {
  opacity: 1;
  pointer-events: auto; }

/* Include skins here which you would like to use */
.protip-skin-default--size-tiny {
  padding: 8px;
  font-size: 10px;
  border-radius: 3px; }

.protip-skin-default--size-tiny[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 4px 4px 0 4px; }

.protip-skin-default--size-tiny[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-tiny[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-tiny[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  top: -4px;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 0 4px 4px 4px; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-tiny[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-default--size-tiny[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 0 4px 4px; }

.protip-skin-default--size-tiny[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-tiny[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-default--size-tiny[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 4px 4px 0; }

.protip-skin-default--size-tiny[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-tiny[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-default--size-tiny[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-default--size-tiny[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -4px;
    border-width: 0 4px 4px 0; }

.protip-skin-default--size-tiny[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-default--size-tiny[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -4px;
    margin: 0 0 0 4px;
    border-width: 4px 4px 0 0; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-default--size-tiny[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -4px;
    left: 100%;
    margin: 0 0 0 -4px;
    border-width: 0 0 4px 4px; }

.protip-skin-default--size-tiny[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-default--size-tiny[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -4px;
    margin: -4px 0 0 4px;
    border-width: 4px 0 0 4px; }

.protip-skin-default--size-small {
  padding: 13px;
  font-size: 12px;
  border-radius: 4px; }

.protip-skin-default--size-small[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 6px 6px 0 6px; }

.protip-skin-default--size-small[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-small[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-small[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-default--size-small[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="bottom-right"] .protip-arrow {
  top: -6px;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 0 6px 6px 6px; }

.protip-skin-default--size-small[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-small[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-small[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-default--size-small[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 0 6px 6px; }

.protip-skin-default--size-small[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-small[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-default--size-small[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 6px 6px 0; }

.protip-skin-default--size-small[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-small[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-default--size-small[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-default--size-small[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -6px;
    border-width: 0 6px 6px 0; }

.protip-skin-default--size-small[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-default--size-small[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -6px;
    margin: 0 0 0 6px;
    border-width: 6px 6px 0 0; }

.protip-skin-default--size-small[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-default--size-small[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -6px;
    left: 100%;
    margin: 0 0 0 -6px;
    border-width: 0 0 6px 6px; }

.protip-skin-default--size-small[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-default--size-small[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -6px;
    margin: -6px 0 0 6px;
    border-width: 6px 0 0 6px; }

.protip-skin-default--size-normal {
  padding: 16px;
  font-size: 15px;
  border-radius: 6px; }

.protip-skin-default--size-normal[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 8px 8px 0 8px; }

.protip-skin-default--size-normal[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-normal[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-normal[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-default--size-normal[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  top: -8px;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 0 8px 8px 8px; }

.protip-skin-default--size-normal[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-normal[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-default--size-normal[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 0 8px 8px; }

.protip-skin-default--size-normal[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-normal[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-default--size-normal[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 8px 8px 0; }

.protip-skin-default--size-normal[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-normal[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-default--size-normal[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-default--size-normal[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -8px;
    border-width: 0 8px 8px 0; }

.protip-skin-default--size-normal[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-default--size-normal[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -8px;
    margin: 0 0 0 8px;
    border-width: 8px 8px 0 0; }

.protip-skin-default--size-normal[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-default--size-normal[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -8px;
    left: 100%;
    margin: 0 0 0 -8px;
    border-width: 0 0 8px 8px; }

.protip-skin-default--size-normal[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-default--size-normal[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -8px;
    margin: -8px 0 0 8px;
    border-width: 8px 0 0 8px; }

.protip-skin-default--size-big {
  padding: 20px;
  font-size: 18px;
  border-radius: 8px; }

.protip-skin-default--size-big[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 10px 10px 0 10px; }

.protip-skin-default--size-big[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-big[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-big[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-default--size-big[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="bottom-right"] .protip-arrow {
  top: -10px;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 0 10px 10px 10px; }

.protip-skin-default--size-big[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-big[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-big[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-default--size-big[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 0 10px 10px; }

.protip-skin-default--size-big[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-big[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-default--size-big[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 10px 10px 0; }

.protip-skin-default--size-big[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-big[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-default--size-big[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-default--size-big[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -10px;
    border-width: 0 10px 10px 0; }

.protip-skin-default--size-big[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-default--size-big[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -10px;
    margin: 0 0 0 10px;
    border-width: 10px 10px 0 0; }

.protip-skin-default--size-big[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-default--size-big[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -10px;
    left: 100%;
    margin: 0 0 0 -10px;
    border-width: 0 0 10px 10px; }

.protip-skin-default--size-big[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-default--size-big[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -10px;
    margin: -10px 0 0 10px;
    border-width: 10px 0 0 10px; }

.protip-skin-default--size-large {
  padding: 20px;
  font-size: 18px;
  border-radius: 8px; }

.protip-skin-default--size-large[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 12px 12px 0 12px; }

.protip-skin-default--size-large[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-large[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-large[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-default--size-large[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="bottom-right"] .protip-arrow {
  top: -12px;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 0 12px 12px 12px; }

.protip-skin-default--size-large[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-large[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-large[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-default--size-large[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 0 12px 12px; }

.protip-skin-default--size-large[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-large[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-default--size-large[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 12px 12px 0; }

.protip-skin-default--size-large[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-large[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-default--size-large[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-default--size-large[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -12px;
    border-width: 0 12px 12px 0; }

.protip-skin-default--size-large[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-default--size-large[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -12px;
    margin: 0 0 0 12px;
    border-width: 12px 12px 0 0; }

.protip-skin-default--size-large[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-default--size-large[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -12px;
    left: 100%;
    margin: 0 0 0 -12px;
    border-width: 0 0 12px 12px; }

.protip-skin-default--size-large[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-default--size-large[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -12px;
    margin: -12px 0 0 12px;
    border-width: 12px 0 0 12px; }

.protip-skin-default--scheme-pro.protip-container {
  color: #FFF;
  background: $blue;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-pro[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="top-right"] .protip-arrow {
  border-top-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: $blue; }

.protip-skin-default--scheme-pro[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: $blue; }

.protip-skin-default--scheme-blue.protip-container {
  color: #fff;
  background: #336699;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-blue[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-default--scheme-red.protip-container {
  color: #fff;
  background: #802731;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-red[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-default--scheme-aqua.protip-container {
  color: #fff;
  background: #339996;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-aqua[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-default--scheme-dark.protip-container {
  color: #fff;
  background: #333;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-dark[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-default--scheme-dark-transparent.protip-container {
  color: #fff;
  background: rgba(20, 20, 20, 0.8);
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-dark-transparent[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="top-right"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-black.protip-container {
  color: #fff;
  background: #000;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-black[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-default--scheme-leaf.protip-container {
  color: #fff;
  background: #012d6c;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-leaf[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-default--scheme-purple.protip-container {
  color: #fff;
  background: #613399;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-purple[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-default--scheme-pink.protip-container {
  color: #000;
  background: #D457AA;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-pink[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-default--scheme-orange.protip-container {
  color: #000;
  background: #E64426;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-orange[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-default--scheme-white.protip-container {
  color: #000;
  background: #FFF;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-white[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #FFF; }

.protip-skin-square--size-tiny {
  padding: 8px;
  font-size: 10px; }

.protip-skin-square--size-tiny[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 4px 4px 0 4px; }

.protip-skin-square--size-tiny[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-tiny[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-tiny[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  top: -4px;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 0 4px 4px 4px; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-tiny[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-square--size-tiny[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 0 4px 4px; }

.protip-skin-square--size-tiny[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-tiny[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-square--size-tiny[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 4px 4px 0; }

.protip-skin-square--size-tiny[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-tiny[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-square--size-tiny[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-square--size-tiny[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -4px;
    border-width: 0 4px 4px 0; }

.protip-skin-square--size-tiny[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-square--size-tiny[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -4px;
    margin: 0 0 0 4px;
    border-width: 4px 4px 0 0; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-square--size-tiny[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -4px;
    left: 100%;
    margin: 0 0 0 -4px;
    border-width: 0 0 4px 4px; }

.protip-skin-square--size-tiny[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-square--size-tiny[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -4px;
    margin: -4px 0 0 4px;
    border-width: 4px 0 0 4px; }

.protip-skin-square--size-small {
  padding: 13px;
  font-size: 12px; }

.protip-skin-square--size-small[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 6px 6px 0 6px; }

.protip-skin-square--size-small[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-small[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-small[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-square--size-small[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="bottom-right"] .protip-arrow {
  top: -6px;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 0 6px 6px 6px; }

.protip-skin-square--size-small[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-small[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-small[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-square--size-small[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 0 6px 6px; }

.protip-skin-square--size-small[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-small[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-square--size-small[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 6px 6px 0; }

.protip-skin-square--size-small[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-small[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-square--size-small[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-square--size-small[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -6px;
    border-width: 0 6px 6px 0; }

.protip-skin-square--size-small[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-square--size-small[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -6px;
    margin: 0 0 0 6px;
    border-width: 6px 6px 0 0; }

.protip-skin-square--size-small[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-square--size-small[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -6px;
    left: 100%;
    margin: 0 0 0 -6px;
    border-width: 0 0 6px 6px; }

.protip-skin-square--size-small[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-square--size-small[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -6px;
    margin: -6px 0 0 6px;
    border-width: 6px 0 0 6px; }

.protip-skin-square--size-normal {
  padding: 16px;
  font-size: 15px; }

.protip-skin-square--size-normal[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 8px 8px 0 8px; }

.protip-skin-square--size-normal[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-normal[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-normal[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-square--size-normal[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  top: -8px;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 0 8px 8px 8px; }

.protip-skin-square--size-normal[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-normal[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-square--size-normal[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 0 8px 8px; }

.protip-skin-square--size-normal[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-normal[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-square--size-normal[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 8px 8px 0; }

.protip-skin-square--size-normal[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-normal[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-square--size-normal[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-square--size-normal[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -8px;
    border-width: 0 8px 8px 0; }

.protip-skin-square--size-normal[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-square--size-normal[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -8px;
    margin: 0 0 0 8px;
    border-width: 8px 8px 0 0; }

.protip-skin-square--size-normal[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-square--size-normal[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -8px;
    left: 100%;
    margin: 0 0 0 -8px;
    border-width: 0 0 8px 8px; }

.protip-skin-square--size-normal[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-square--size-normal[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -8px;
    margin: -8px 0 0 8px;
    border-width: 8px 0 0 8px; }

.protip-skin-square--size-big {
  padding: 20px;
  font-size: 18px; }

.protip-skin-square--size-big[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 10px 10px 0 10px; }

.protip-skin-square--size-big[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-big[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-big[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-square--size-big[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="bottom-right"] .protip-arrow {
  top: -10px;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 0 10px 10px 10px; }

.protip-skin-square--size-big[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-big[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-big[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-square--size-big[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 0 10px 10px; }

.protip-skin-square--size-big[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-big[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-square--size-big[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 10px 10px 0; }

.protip-skin-square--size-big[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-big[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-square--size-big[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-square--size-big[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -10px;
    border-width: 0 10px 10px 0; }

.protip-skin-square--size-big[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-square--size-big[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -10px;
    margin: 0 0 0 10px;
    border-width: 10px 10px 0 0; }

.protip-skin-square--size-big[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-square--size-big[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -10px;
    left: 100%;
    margin: 0 0 0 -10px;
    border-width: 0 0 10px 10px; }

.protip-skin-square--size-big[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-square--size-big[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -10px;
    margin: -10px 0 0 10px;
    border-width: 10px 0 0 10px; }

.protip-skin-square--size-large {
  padding: 20px;
  font-size: 18px; }

.protip-skin-square--size-large[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 12px 12px 0 12px; }

.protip-skin-square--size-large[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-large[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-large[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-square--size-large[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="bottom-right"] .protip-arrow {
  top: -12px;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 0 12px 12px 12px; }

.protip-skin-square--size-large[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-large[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-large[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-square--size-large[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 0 12px 12px; }

.protip-skin-square--size-large[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-large[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-square--size-large[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 12px 12px 0; }

.protip-skin-square--size-large[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-large[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-square--size-large[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }
  .protip-skin-square--size-large[data-pt-position="top-left-corner"] .protip-arrow {
    top: 100%;
    left: 100%;
    margin: 0 0 0 -12px;
    border-width: 0 12px 12px 0; }

.protip-skin-square--size-large[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }
  .protip-skin-square--size-large[data-pt-position="top-right-corner"] .protip-arrow {
    top: 100%;
    left: -12px;
    margin: 0 0 0 12px;
    border-width: 12px 12px 0 0; }

.protip-skin-square--size-large[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }
  .protip-skin-square--size-large[data-pt-position="bottom-left-corner"] .protip-arrow {
    top: -12px;
    left: 100%;
    margin: 0 0 0 -12px;
    border-width: 0 0 12px 12px; }

.protip-skin-square--size-large[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }
  .protip-skin-square--size-large[data-pt-position="bottom-right-corner"] .protip-arrow {
    top: 0;
    left: -12px;
    margin: -12px 0 0 12px;
    border-width: 12px 0 0 12px; }

.protip-skin-square--scheme-pro.protip-container {
  color: #000;
  background: #da2e2b;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-pro[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #da2e2b; }

.protip-skin-square--scheme-blue.protip-container {
  color: #fff;
  background: #336699;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-blue[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-square--scheme-red.protip-container {
  color: #fff;
  background: #802731;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-red[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-square--scheme-aqua.protip-container {
  color: #fff;
  background: #339996;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-aqua[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-square--scheme-dark-transparent.protip-container {
  color: #fff;
  background: rgba(20, 20, 20, 0.8);
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-dark-transparent[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="top-right"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark.protip-container {
  color: #fff;
  background: #333;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-dark[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-square--scheme-black.protip-container {
  color: #fff;
  background: #000;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-black[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-square--scheme-leaf.protip-container {
  color: #fff;
  background: #012d6c;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-leaf[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-square--scheme-purple.protip-container {
  color: #fff;
  background: #613399;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-purple[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-square--scheme-pink.protip-container {
  color: #000;
  background: #D457AA;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-pink[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-square--scheme-orange.protip-container {
  color: #000;
  background: #E64426;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-orange[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-square--scheme-white.protip-container {
  color: #000;
  background: #FFF;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-white[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #FFF; }
