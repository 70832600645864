.tabs {
	display: inline-block;
	background: $light-grey;
	margin-bottom: 2em;
	border-radius: 6px;

	>li {
		display: inline;

		>a {
			display: inline-block;
			padding: 0.3em 0.6em;
			border-radius: 4px;
			font-weight: bold;
			font-size: 1.1875em;
			transition: all 0.16s ease;
			color: $black;
			@include mq($until: tablet) {
				display: block;
			}

			&.active,
			&:hover {
				background: $blue;
				color: #FFFFFF;
			}
		}
	}
}