.how-it-works{}

body.how-it-works .grid-container.--header {
	.page-header {
		grid-template-columns: 4fr 6fr;
	}
}


.how-it-works__3-tier {

	padding: 0px 60px;


	h2 {
		font-size: 48px;
		line-height: 50.4px;
		font-family: $sansBold;
		color: #000;
		max-width: 564px;
		@include mq($until: tablet) {
			font-size: 38px;
		}
	}

	ul {

		display: grid;
		grid-template-areas: "explore explore . meet meet . get get";
		grid-template-columns: 1fr 2fr 100px 1fr 2fr 100px 1fr 2fr;

		grid-template-rows: 1fr;

		grid-column-gap: 20px;

		width: 100%;

		list-style-type: none;

		li {

			display: flex;
			flex-direction: column;

			p, h4 {
				margin: 3px 0;
			}

			h4 {
				color: #222;
				font-size: 24px;
				line-height: 130%;
			}

			p {
				color: rgba(#2C5D90, 0.7);
			}

			&:before {
				width: 72px;
				height: 72px;
				display: block;
				content: "";
				margin-bottom: 20px; 
				border-radius: 8px;
				background-color: $selectblue;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		li.explore {
			grid-area: explore;
			&:before {
				background-image: url('../../img/how-it-works/explore.png');
			}
		}

		li.meet {
			grid-area: meet;
			&:before {
				background-image: url('../../img/how-it-works/meet.png');
			}
			padding: 100px 0;
		}

		li.get {
			grid-area: get;
			&:before {
				background-image: url('../../img/how-it-works/get.png');
			}

			@media screen and (min-width: 980px) {
				justify-content: flex-start;
				margin-top: 11em;
			}

		}

		&:before, &:after {
			display: block;
			content: "";
			position: relative;
			background-size: 80%;
			background-position: 50% 0%;
			background-repeat: no-repeat;
			grid-row: 1;
		}

		&:before {
			grid-column: 2 / 4;
			background-image: url('/img/how-it-works/path1.png');
			background-position: 50% 10px;
		}

		&:after {
			grid-column: 5 / 7;
			background-image: url('/img/how-it-works/path2.png');
			background-position: 50% 150px;
		}

	}


	@include mq($until: desktop) {
		padding: 0;

		h4 {
			font-size: 24px;
			line-height: 28px;
		}

		ul {
			grid-template-areas:
				"explore"
				"meet"
				"get";

			grid-template-columns: 1fr;

			&:before, &:after {
				display: none;
			}

			li {
				padding: 32px 0 0 0 !important;
			}

			li:before {
				width: 64px;
				height: 64px;
				margin-bottom: 16px; 
			}

		}
	}	

}



.how-it-works__unis {
	margin: 50px 0 160px 0;
	@include mq($until: tablet) {
		margin: 50px 0 90px 0;
	}
	img {
		width: 100%;
	}

	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 50px;

	img {
		width: 100%;
		height: auto;
		filter: grayscale(100%);
		opacity: 0.5;
		object-position: center;
		object-fit: contain;
	}

	@include mq(tablet, desktop) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto auto;
	}

	@include mq(mobile, tablet) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(1fr, 3);
	}
}


.how-it-works__where {
	padding-top: 105px;

	@include mq($until: tablet) {

		.grid-row__cell {
			align-items: flex-start;
		}


		div:first-child {
			order: 2;
			.button {
				align-self: center;
			}
		}
		div:nth-child(2) {
			order: 1;
			padding-bottom: 64px;
		}
	}
}

.how-it-works__stats {
	padding-top: 105px;
	@include mq($from: wide) {
		padding-left: 100px;
		padding-right: 100px;
	}
}

.how-it-works__why {
	margin-top: 133px;

	@include mq(mobile, tablet) {

		.button {
			margin-top: 50px;
		}
	}
}

.how-it-works__banner {
	background-image: url('../../img/how-it-works/banner.png');
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 240px;
	margin-bottom: 128px;
	padding-top: 128px;
	padding-bottom: 160px;
	@include mq($until: tablet) {
		padding-top: 90px;
		padding-bottom: 120px;
		margin-bottom: 20px;
	}

	@include mq(mobile, tablet) {
		margin-top: 100px;
	}

	h2 {
		font-size: 48px;
		line-height: 50px;
		margin: 0 0 30px 0;
		font-family: $sansBold;
		text-align: center;
		@include mq($until: tablet) {
			font-size: 38px;
		}
	}

	p {
		margin: 0 0 40px 0;
		text-align: center;
		max-width: 530px;
	}

	.grid__cell {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.button {
		margin-bottom: 60px;
		align-self: center;
	}
}