.logo-reel {
	margin-bottom: 2.6em;
	li {
		display: inline;
		a, img {
			display: inline-block;
			vertical-align: middle;
			margin: 0 2em;
			width: auto;
		}
	}
}