.the-team {

	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 60px 0;

}

.the-team__person {
	align-items: center;
}


.the-team__person {
	text-align: center;
	max-width: 200px;
	margin: 0 40px;
	&:first-child {
		margin-left: 0
	}
	&:last-child {
		margin-right: 0
	}
	flex: 0 0 auto;
	display: flex;
	position: relative;
	cursor: pointer;
	flex-direction: column;
	img {
		border-radius: 144px;
		max-width: 144px;
		max-height: 144px;
	}
	dt {
		display: none;
	}
	dd, dt {
		flex: 0 0 auto;
		margin-bottom: 5px
	}
}

.person__name {
	font-size: 14px;
}

.person__position {
	font-size: 12px;
	color: rgba(44, 93, 144, 0.7);
}

.person__photo {

	width: 144px;
	position: relative;

	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 0px;
		top: 110px;
		width: 30px;
		height: 30px;
		background: url(../../img/more.svg) no-repeat;
		background-size: contain;
		z-index: 1999;
	}
}

@include mq($until: tablet) {
	.the-team {
		flex-direction: column;
		align-items: center;
	}
	.the-team__person {
		flex: 0 1 50%;
		margin: 0 0 25px 0;
	}
}