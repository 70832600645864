@import '_fonts';
@import '_variables';
@import '_config';

@import 'utils/_mq';
@import 'utils/_helpers';
@import 'utils/freeform';
@import 'utils/rangeslider';
@import 'utils/protip';

@import '_autocomplete';
@import '_breadcrumb';
@import '_blog-card';
@import '_buttons';
@import '_content-page';
@import '_detail-table';
@import '_faves';
@import '_faq';
@import '_floating-nav-bar';
@import '_forms';
@import '_fun-facts';
@import '_header-buttons';
@import '_grid-list';
@import '_layout';
@import '_levels';
@import '_lists';
@import '_listing';
@import '_modal';
@import '_pagination';
@import '_page-header';
@import '_photo-cloud';
@import '_profile-dropdown';
@import '_reviews';
@import '_site-header';
@import '_site-footer';
@import '_stats';
@import '_story-records';
@import '_the-team';
@import '_typography';
@import '_university';
@import '_university-website-preview';
@import '_instagram-stack';
@import '_docked-bar';
@import '_at-a-glance';
@import '_tabs';
@import '_logo-reel';
@import '_cards';
@import '_ben';

@import "pages/_blog";
@import "pages/_home";
@import "pages/_newsroom";
@import 'pages/_page'; // generic page structure
@import 'pages/_how-it-works';
@import 'pages/_become-an-agent';


* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

a {
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
}

img {
	//max-width: 100%;
	width: 100%;
	height: auto;
}

body {
	margin: 0;
	padding: 0;
	line-height: 1.5;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

