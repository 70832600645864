
.blog__index-header {

	background: url('../../img/blog/splash.png') no-repeat;
	background-size: contain;
	background-position: center;

	padding-bottom: 0;

	.button {
		display: block;
		margin: 40px auto 70px auto;

		@include mq(mobile, tablet) {
			margin: 24px auto 24px auto;
		}
	}

	h1 {
		//font-size: 64px;
	}

	@include mq(mobile, desktop) {
		background: transparent;
		flex-direction: column;
		align-items: stretch;
	}

	

}

.blogPost, .event {
	.--blog-header {
		padding-top: 4em;
		@include mq($until: tablet) {
			padding-top: 2em;
		}
	}
}

.blog {
	[data-view] {
		display: content;
	}
}

.blog__toggle-hidden {
	display: none !important;
}

.blog__entry {

	header {
		padding: 100px 0 48px 0;
	}

}

.blog__content, .blog__entry {

	figure {
		margin: 20px 0;
	}

	.button {
		margin-top: 32px;
		align-self: flex-start;
	}

	figure, img {
		border-radius: 10px;
	}

	grid-template-columns: auto minmax(min-content, 768px) auto;
	grid-column-gap: 0px;
	
	.grid-row__cell {
		grid-column: 2;
	}

}

.blog__index-header-controls {

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@include mq($until: tablet) {
		flex-direction: column !important;
	}

	margin-bottom: 112px;

	.page-title {
		margin: 0;
	}

	@include mq(mobile, desktop) {
		flex-direction: row;
		align-items: flex-start;
		flex: 1 1 100%;

		margin-bottom: 36px;

		header {
			margin-top: 50px;
			margin-bottom: 100px;
		}
	}

	.toggle {

		@include mq(mobile, desktop) {
			display: none;
		}

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		label {
			a {
				color: inherit;
			}
			cursor: pointer;
			position: relative;
			padding: 0 50px;
			border-radius: 24px;
			height: 48px;
			display: flex;
			align-items: center;
			min-width: 160px;
			color: #222;
			background: white;
			font-size: 19px;
			font-family: $sans;
			z-index: 10;
		}

		input {
			position: absolute;
			left: -999em;
		}

		label[for=events] {
			left: -40px;
			z-index: 5;
			padding-left: 60px;
		}

		input:checked + label {
			background: $selectblue;
			color: white;
		}

		#events + label {

		}
	}

}


.blog__card-shuffle {

	@include mq($from: desktop) {

		min-height: 800px;
		position: relative;

		> div {
			position: absolute;
		}

		> div:nth-child(1) {
			top: 0px;
			z-index: 10;
			transform: rotate(5deg) translateX(50%);
		}

		> div:nth-child(2) {
			top: 350px;
			z-index: 8;
			transform: rotate(-10deg) translateX(25%) scale(0.8);
		}

		> div:nth-child(3) {
			top: 380px;
			z-index: 9;
			transform: rotate(10deg) translateX(90%) scale(0.6);
		}

	}

	@include mq($until: tablet) {

	}
		
}

.blog__top-five {


	margin-bottom: 100px;

	@include mq(mobile, tablet) {
		display: none;
	}

	header {
		display: contents;
	}

	.grid-row__cell {

		margin: 0 calc(50% - 100px - 736px / 2);

		padding: 80px 100px 120px;

		background: url('../../img/blog/top5.png') no-repeat;
		background-position: 50% 30%;
		background-size: 100%;

		display: grid;
		grid-template-columns: repeat(4, 1fr);

		grid-row-gap: 16px;
		grid-column-gap: 16px;

		header h2 {
			grid-column: 1/-1;
			font-size: 38px;
			margin: 0 0 2rem;
			color: #000;
			text-align: center;
		}

		> div {
			grid-column: span 2;
      max-width: 310px;
      align-content: flex-start;
      &:nth-of-type(1) {
        transform: translate(75px, -25px);
      }
      &:nth-of-type(2) {
        transform: translate(75px, 0px);
      }
      &:nth-of-type(3) {
        transform: translate(14px,-11px);
      }
      &:nth-of-type(4) {
        transform: translate(0, 0);
      }
      &:nth-of-type(5) {
        transform: translate(0, 0);
      }
		}

		> div:nth-child(6) {
			grid-column: 2 / 4;
		}

	}

}

.blog__featured {

	@include mq(mobile, tablet) {
		display: none;
	}

}


.blog__more {

}


.blog-listing {

	display: grid;
	grid-row-gap: 32px;

	grid-template-columns: 1fr;

	&:after {
		// Little bit of a hack, hide the last bottom-border of the list
		background: #fff;
		bottom: 0px;
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
	}

	&.--two-col {
		grid-template-columns: 1fr 1fr;
		margin-top: 40px;

		>div:nth-child(2n + 1) {
			padding-right: 32px;
		}

		>div:nth-child(2n) {
			padding-left: 32px;
		}
	}


}



/* Filter styling */

.filter {

	color: #000;

	form {
		display: contents;
	}

	display: flex;
	flex-direction: row;
	flex-direction: space-between;
	width: 100%;

	@include mq(mobile, desktop) {
		flex-direction: column;
	}

	.mobile-float {
		&:before, &:after {
			background: #E7F6FA; 
		}
	}

	> div {
		flex: 0 1 50%;
	}

	select {
		flex: 0 1 auto;
		background: none;
		border: none;
		font-size: 20px;
		height: 48px;
		color: #000;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-direction: space-between;

	}

	position: relative;

	/*.form__show-filters {
		@include mq(mobile, tablet) {
			position: absolute;
			top: 0px;
			right: 0px;
		}
	}*/

	.filter__select {

		.button {
			color: white;
			margin-top: 48px;
		}

		> div:not(.mobile-only) {
			//flex: 0 1 50%;
			font-size: 24px;
			font-family: $sansBold;
			height: 48px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		flex: 0 1 40%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		[data-mobile-toggle-target] {
			color: #000;
		}

		@include mq(mobile, tablet) {
			flex: 0 1 100%;
			background: #E7F6FA;
			padding-bottom: 64px;
			flex-direction: column;
			> .title {
				display: none;
			}
			select {
				height: 64px;
				border-bottom: 1px solid #D0D0D0;
			}
			* {
				font-size: 20px !important;
				color: #002868;
			}
		}

	}

	.filter__search {
		flex: 0 1 60%;
		display: flex;
		//border-style: solid;
		flex-direction: row;
		@include mq($until: tablet) {
			flex-direction: column;
			align-items: flex-start;
			.button {
				align-self: flex-start !important;
			}
		}
		@include mq(desktop) {
			justify-content: flex-end;
		}
		.button {
			align-self: center;
		}
	}


}

.blog__index-header-controls .search {
	.button {
		margin: 0 !important;
		align-self: center !important;
		min-width: 0px;
	}
	form {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
	}
}

.search, .search-container {

	display: flex;
	flex-direction: row;
	align-items: center;

	input {
		border: none;
		background: transparent;
		font-size: 18px;
		height: 48px;
		display: flex;
		align-items: center;
	
	}

	&:before {
		position: relative;
		left: 0px;
		top: 0px;
		height: 48px;
		width: 32px;
		content: "";
		background: url('../../img/search.png') no-repeat;
		background-position: left center;
	}
}


.blog-filter {
	flex: 1 1 70%;
	@include mq(mobile, tablet) {
		display: none;
		&.visible {
			display: flex;
		}
	}
}