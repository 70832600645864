.breadcrumbs {
	ul {
		display: flex;
		flex-direction: row;
		list-style-type: none;
		padding: 0;
    li {
      font-size: 1rem;
      font-weight: 700;
      &:last-child {
        opacity: 0.4;
      }
    }
	}

	.breadcrumbs__crumb {
		color: $logoblue;
		//opacity: 0.4;
		a {
			opacity: 1;
		}

		display: flex;
		flex-direction: row;
		align-items: center;

		&:not(:last-child) {
			&:after {
				position: relative;
				width: 13px;
				height: 11px;
				content: " ";
				margin: 0 9px;
				display: block;
				background: url(../../img/crumb-arrow.png) no-repeat;
			}
		}
	}

}

