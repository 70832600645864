.levels {
	/*display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
	}

	@include mq(tablet, desktop) {
		grid-template-columns: 1fr 1fr;
	}

	grid-gap: 12px;*/

	display: flex;
	justify-content: center;
	align-items: center;

	@include mq(mobile, desktop) {
		flex-direction: column;
	}

	width: 100%;
	margin: 50px 0;
	
}


.levels__arrow {
	width: 43.5px;
	height: 215px;
	background: url('../../img/universities/academics-arrow.png') no-repeat;
	background-size: contain;
	margin: 0 auto;
}

.level__panel {
	padding: 20px 27px;
	background: #FFFFFF;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
	border-radius: 4px;
	display: flex;
	flex-direction: column;	
	justify-content: center;
	align-items: center;
	margin: 0 10px;
}

.level__icon {
	width: 99px;
	height: 99px;
	border-radius: 99px;
	background: #D4EBFB;
	border: 7px solid #EDF8FF;
	margin: 23px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: auto;
		height: auto;
	}
}