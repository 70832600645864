$black: #000;
$dark: #12141A;
$darkblue: #2C5D90;
$blue: #21CBFF;
$lightblue: #E7F6FA;
$grey: #ACACAC;
$light-grey: #EFEFEF;
$dark-grey: #65666A;
$footer-grey: $dark-grey;
$offwhite: #F9FBFC;

$darkestblue: #002868;

$selectblue: #4FADF2;

$logoblue: #012D6C;

$content-width: 1152px; 


$sans: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
//$sans: Inter;
$sansBold: InterBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;

