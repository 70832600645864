.content-page {}

.content-page__block {
	&.image {
		
		width: 40%;
		margin: 10px 20px;
		&.left {
			margin-left: 0px;
			float: left;
		}
		&.right {
			margin-right: 0px;
			float: right;
		}
	}
}

.page-header.content-page {
	.title {
		padding: 30px 0 100px 0;	
	}
	.page-header__content {
		padding: 0;
	}
}