
html {
  font-size: 16px; /* for rem sizing */
}

body {
	overflow-x: hidden;
	&.home, &.universities, &.faqs {
		background: url(/img/home/circles.png) no-repeat, url(/img/home/bg-rainbow.jpg) no-repeat;
		background-position: top center, top center;
		background-size: 1200px 1200px, contain;
	}

	&.faqs {
		background-size: 1200px 1200px, cover;
	}

	&.blogIndex, &.eventsIndex {
		@include mq(tablet) {
			background: url(/img/blog/arrow.png) no-repeat,
						url(/img/home/circles.png) no-repeat,
						url(/img/home/bg-rainbow.jpg) no-repeat;
			background-position: center 950px, top center, top center;
			background-size: 356px 1073px, 1200px 1200px, contain;
		}
	}
  &.universityDetail {
    background: url(/img/bg-colour-blur.jpg), linear-gradient(180deg, rgba(255,255,255,1) 0%, $offwhite 100%);
    background-repeat: no-repeat;
    background-position: 0 100%;
  }
}


.container {
	width: 100%;
	max-width: 1182px;
	padding: 0 1em;
	margin-left: auto;
	margin-right: auto;
}


#popup-bg {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0px;
	left: 0px;
	z-index: 1;
	opacity: 1;
	transition: opacity 0.25s ease-in-out;
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

/**
 * Sitewide grid layout
 */

$grid-layout: calc( 50vw - #{$content-width} / 2 )
    	repeat( 8, 1fr )
    	calc( 50vw - #{$content-width} / 2 );

.grid-container {
	display: grid;
  	grid-template-columns: $grid-layout;
    
  	@include mq($until: tablet) { 
  		grid-template-columns: 20px calc(100% - 40px) 20px;
  	}

  	@include mq($from: tablet, $until: wide) { 
  		grid-template-columns: 32px calc(100% - 64px) 32px;
  	}

}

body.page .grid-container.--header {
	background: $lightblue;
}

.grid-row {

	grid-column: 2 / -2;
	display: grid;
	position: relative;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;

	@include mq($until: tablet) {
		grid-template-columns: 1fr;
	}

	&.--full {
		grid-column: 1 / -1;
	}

	&.--narrow {
		max-width: 792px;
		margin-left: auto;
		margin-right: auto;
	}

	&.--page-meta {
		grid-template-columns: 1fr auto;
	}

	&.--sidebar-and-content {
		grid-template-columns: 320px 1fr;
		grid-column-gap: 64px;

		@include mq($until: tablet) {
			grid-template-columns: 1fr;		
		}
	}

	&.--big-gap {
		grid-column-gap: 120px;
	}

	&.--single {
		grid-template-columns: 1fr;
	}

	&.--filled {
		background: $lightblue;
		&:before, &:after {
			content: "";
			display: block;
			position: absolute;
			width: calc(50vw - 50%);
			height: 100%;
			background: $lightblue;
		}
		&:before {
			left: calc(0px - (50vw - 50%))
		}
		&:after {
			right: calc(0px - (50vw - 50%))
		}
	}

	.button {
		align-self: flex-start;
	}

	@include mq(mobile, tablet) {
		.button {
			align-self: center;
		}
	}

}

.grid-row__cell {

	display: flex;
	flex-direction: column;
	
	&.--center-vertical {
		//display: flex;
		//flex-direction: column;
		//justify-content: center;
		align-items: center;
	}

	@include mq($until: tablet) {
		.grid-row__cell {
			//display: flex;
			//flex-direction: column;
			align-items: center;
		}
	}

	header {
		width: 100%;
	}

}


/* visibility helpers */


body {
	.mobile-only {
		@include mq($from: tablet) {
			display: none;
		}
	}

	.mobile-hidden {
		@include mq($until: tablet) {
			display: none;
		}	
	}
	.mobile-toggle {
		display: none;
	}

	[data-mobile-toggle-target] {
		cursor: pointer;
		@include mq(tablet) {
			display: none;
		}
	}

	@include mq($until: tablet) {
		.mobile-float {
			position: absolute;
			background: white;
			z-index: 100;
			width: 100%;
			&:before, &:after {
				content: "";
				height: 100%;
				width: 32px;
				position: absolute;
				background: white;
				top: 0px;
			}
			&:before {
				left: -32px;
			}
			&:after {
				right: -32px;
			}
		}
	}
}


.narrow__content {

	grid-template-columns: auto minmax(min-content, 768px) auto;
	grid-column-gap: 0px;
	
	.grid-row__cell {
		grid-column: 2;
	}

}

/* Shared Elements */

.bg-arrow {
  width: 141px;
  position: absolute;
  top: -320px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  &.--top {
    clip-path: inset(0 0 50% 0);
  }
  &.--bottom {
    clip-path: inset(50% 0 0 0);
    top: auto;
    bottom: -130px;
    @include mq($until: tablet) {
      bottom: 10px;
    }
  }
}

.relative {
  position: relative;
}