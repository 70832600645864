.modal-container {
	width: 100vw;
	height: 100vh;
	background: rgba(#012D6C, 0.8);
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 0px;
	z-index: 199999;
}

.modal {
	max-width: 900px;
	margin: 0 15%;
	background: white;
	border-radius: 20px;
	display: grid;
	grid-template-areas:
		"image content"
	;
	grid-template-columns: 1fr 1fr;
	position: relative;
}

@include mq($until: tablet) {
	.modal {
		grid-template-areas: "image" "content";
		grid-template-columns: 1fr;
		grid-template-rows: 240px auto;
		margin: 0 5%;
	}

	.modal__image {
		img {
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 0px !important;
		}
	}
	.modal__close {
		background: #FFF;
	}
}

.modal__content {
	padding: 20px;
	grid-area: content;
}

.modal__title {
	font-size: 24px;
	color: #000;
	margin: 0;
}

.modal__subtitle {
	font-size: 18px;
	color: #2C5D90;
	margin: 10px 0;
}

.modal__text {
	font-size: 14px;
	color: #000;
}

.modal__close {
	width: 35px;
	height: 35px;
	background: url('../../img/x.svg') no-repeat;
	background-size: contain;
	background-position: center;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.modal__image {
	grid-area: image;
	img {
		height: 100%;
		object-fit: cover;
		display: block;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
}