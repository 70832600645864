.university-header {
    padding-top: 5.2em;
    p {
        font-weight: bold;
        font-size: 1.5em;
        color: $blue;
        margin-bottom: 0;
    @include mq($until: tablet) {
      margin-bottom: .5em;
    }  
    }
    h1 {
        margin-top: 0;
        font-weight: 800;
        font-size: 4em;
        line-height: 105%;
    font-family: $sansBold;
    @include mq($until: wide) {
      font-size: 55px;
    }
    @include mq($until: tablet) {
      font-size: 38px;
      line-height: 115%;
    }
    }
    .button {
        margin-bottom: 1rem;
    }
}

.at-a-glance {
    background: url(../../img/bg-ataglance.svg) center center no-repeat;
    background-size: 100% 100%; // seems to stretch better than 'cover', we might be able to be find alternative ways of doing this if needed
  @include mq($until: tablet) {
    background-size: cover; // better for mobile
  }
}

.js-content {
    display: none;
    &.active {
        display: block;
    }
}

.student-experiences {
  @include mq(tablet) {
    //padding-top: 150px;
  }
  header {
    margin-bottom: 32px;
  }
  //margin-bottom: 256px;
}

.student-testimonials {
    .reviews {
        margin: 64px 0;
    }
    .button {
        align-self: center;
    }
}

.student-experiences__reel-content {
    display: none;
    max-width: 90vw;
    ul {
        display: flex;
        max-width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        padding: 0;
        margin: 0;
    }
    li {
        display: inline;
        white-space: normal;
        .tiktok-embed {
            width: auto;
            display: inline-block;
            vertical-align: middle;
            margin: 1em;
            @include mq($until: tablet) {
                margin: 1em 0;
            }
        }
    }
    &.active {
        display: block;
    }
}

.testimonials {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1em;
    margin: 0 auto;
    width: 100%;
    max-width: 1184px;
    list-style: none;
    li {
        width: 100%;
        max-width: 352px;
        margin-bottom: 2em;
    }
    .testimonial {
        font-style: italic;
        margin-bottom: 1em;
    }
    .testimonial__meta {
        display: flex;
        .testimonial__meta-left {
            max-width: 48px;
            margin-right: 1em;
            img {
                border: 1.5px solid;
                border-radius: 100%;
            }
        }
        .testimonial__meta-right {
            p {
                font-size: 1em;
                margin: 0;
                font-weight: bold;
                &.testimonial__location {
                    opacity: 0.6;
                    font-weight: normal;
                }
            }
        }
    }
}



#map {
    width: 100% !important;
    margin-bottom: 2.6em;
  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: 401;
    background-position: 50% 0;
    background-size: cover;
  }
  &:before {
    background-image: url("/img/map-border-top.svg");  
    top: 0;
    height: 77px;
  }
  &:after {
    background-image: url("/img/map-border-bottom.svg");  
    bottom: 0;
    height: 78px;
  }
}


body {
  &.universityListing {
    .listing-sidebar {
      display: flex;
      padding-bottom: 64px;
      padding-top: 0;

      @include mq(mobile, tablet) {
        display: none;
        &.visible {
          display: flex;
        }
      }
    }

    .button {
      align-self: center;
      margin-top: 0px;
    }
  }
}

.--similar-universities {
    @include mq($from: tablet) {
        margin: 128px 0;
    }
    header {
        margin-bottom: 64px;
    }
}


.university__social {
    ul {
        padding-bottom: 100px;
        display: flex;
        flex-direction: row;
        list-style-type: none;
        li {
            a {
                background: #4fadf2;
                border-radius: 100%;
                color: #fff;
                margin: 10px;
                padding: 10px;
                transition: all .25s ease-in-out;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                &:hover {
                    background: $darkblue;
                }
            }

        }
    }
}

.university__about {
    h1 {
        font-size: 28px;
        margin: 1rem 0;
    }
    //margin-bottom: 90px;
    @inculde mq(from: $mobile) {
        padding: 43px 87px 20px 87px; 
    }
    //background: #F9FBFC;

    display: flex;
    flex-direction: column;

    figure {
        width: auto;
        display: inline-block;
        padding: 0;
        margin: 20px auto;
    }
}

.university__arrow {
    display: flex;
    justify-content: center;
    margin: 64px 0;
    img {
        height: auto;
        width: auto;
    }
} 

.university-link a {
    font-weight: bold;
    font-size: 24px;
    line-height: 115%;
    color: #4FADF2;
    text-decoration: none;
    display: inline-block;
    margin: 2.6em 0 1em 0;

    &:hover {
        text-decoration: none;
    }
}

.university__report-links {
    display: flex;
    justify-content: center;
    margin: 0 0 2.6em 0;
    font-family: $sansBold;
    a {
        text-decoration: underline;
        display: inline-block;
        margin: 0 12px;
        color: #012D6C;
        opacity: 0.4;
    }
}


.university__about {
    position: relative;

    @include mq($until: tablet) {
        &, figure, iframe {
            width: calc(100vw - 40px) !important;
        }
    }

    &.readmore {



        height: 400px;
        overflow: hidden;

        &:after {
            display: block;
            content: "";
            height: 150px;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+91 */
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 91%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 91%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 91%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
            position: absolute;
            bottom: 0px;
            width: 100%;
        }
    }
}


.student-academics {
    .form__select.--heading {
        @include mq($until: tablet) {
            flex-direction: column;
            select {
                margin: 15px 0;
                text-indent: 30px
            }
        }
    }
}