.fun-facts {

	display: grid;
	grid-gap: 20px;
	grid-template-columns: 35% 65%;

	&__heading {
		font-size: 36px;
		color: #000;
		font-family: $sansBold;
		margin-bottom: 20px;
		margin-top: 0;
	}

	&__intro {
		grid-column: 1;		
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&__list {
		grid-column: 2;
		ul {
			list-style-type: none;
			li {
				display: grid;
				grid-template-columns: 75px auto;
				grid-gap: 20px;
				margin-bottom: 20px;
				i {
					grid-column: 1;
					height: 75px;
					width: 75px;
					border-radius: 10px;
					background: $blue;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 30px;
					color: #FFF;
				}
				span {
					grid-column: 2;
					display: flex;
					align-items: center;
				}
			}
		}

	}



	@include mq($until: tablet) {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		&__intro {
			grid-column: 1;
			grid-row: 1;
		}		

		&__list {
			grid-column: 1;
			grid-row: 2;
		}
	}	


}