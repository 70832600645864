.form, form {


	.wrapper {
		width: 100%;
		position: relative;
	}


}


.form__control {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@include mq($until: tablet) {
		flex-direction: column;
	}
}

.form__show-filters {
	display: flex;
	justify-content: flex-end;
	z-index: 20;
	* {
		color: #000;
	}
	h3 {
		
	}
}

.form__select {
	
	&:not(.--heading) {
		select {
			height: 32px;
			line-height: 32px;
			font-size: 14px;
			color: $dark;
			border: 1px solid rgba(18, 20, 26, 0.1);
			width: 100%;
			text-indent: 5px;
			border-radius: 5px;
		}
	}	


	select {
		padding-right: 100px;
	}

	&.--sort {
		@include mq($until:tablet) {
			button {
				display: none;
			}
		}
		justify-content: space-between;
		select {
			margin-right: auto;
		}
	}

	&.--academic {
		select {
			height: 57px;
			font-size: 18px;
			font-family: $sansBold;
			color: #000;
			background: #FFFFFF;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
			border-radius: 4px;
			margin: 0 26px 0 14px;
			text-indent: 4px;
		}
	}

	&.--heading {

		display: flex;
		

		h3 {
			display: inline-block;
			color: #777;
			padding-right: 10px;
			font-family: $sansBold;
		}

		h3, select{
			font-size: 18px;
			line-height: 1.3;
			font-family: $sansBold;
			appearance: none;
			font-weight: 600;
		}

		select {
			border: none;
			background: transparent;
			background-image: url('../../img/select-down.png');
			background-repeat: no-repeat;
			background-position: calc(100% - 78px) center;
			-webkit-appearance: none;
		}
	}
}

select[disabled] {
	opacity: 0.5;
}

.form__fieldset {

	padding: 0;
	margin: 0 0 40px 0;

	border: 2px solid rgba($dark, 0.1);
	border-radius: 16px;

	padding: 16px 26px 22px;

	&:not(.--no-border) {
		background: white;
	}

	&.--select {
		border: none;
		padding: 18px 30px;
		display: grid;
		grid-row-gap: 8px;
		padding: 0;
		font0size: 20px;
	}

	

}

.form__input-wrapper {
	&.--yes-no, &.--multi-select { 
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		//padding: 22px 30px calc(22px - 16px) 30px;
		input {
			display: none;
		}
		label:not(:last-child) {
			margin-right: 12px;
		}
		label {
			cursor: pointer;
			padding: 4px 12px;
			border: 2px solid rgba($dark, 0.1);
			border-radius: 8px;
			background: white;
			margin-bottom: 16px;
		}
		input:checked + label {
			background: $selectblue;
			color: white;
		}
	}
}

.form__label {
	border: 2px solid rgba($dark, 0.1);
	border-radius: 16px;
	padding: 16px 26px;
	background: white;
	display: flex;
  align-items: center;
	flex-direction: row;
	font-size: 14px;
	font-weight: 500;
	color: $dark;
	font-family: $sans;

	&.--no-border {
		border: none;
		padding: 0px;
		justify-content: center;
	}
}

.form__heading {
	font-size: 14px;
	line-height: 16px;
	color: $dark;
	display: block;
	flex: 1 1 100%;
	font-weight: 600;
	margin-bottom: 12px;
 }

.form__icon {
	width: 24px;
	height: 24px;
	display: inline-block;
	margin: 0 12px;

	background-position: center;
	background-repeat: no-repeat;

	&.--uk {
		background-image: url('../../img/uk.png');
	}

	&.--ireland {
		background-image: url('../../img/ireland.png');
	}

	&.--eu {
		background-image: url('../../img/eu.png');
	}

	&.--usa {
		background-image: url('../../img/usa.png');
	}
}

.form__submit {
	margin: 32px 0;
}

input[name=q] {
	max-width: 640px;
}