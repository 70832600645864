.button {
	display: inline-block;
	background: $selectblue;
	color: white;
	border-width: 0px;
	border-radius: 58px;
	font-family: $sans;
	font-size: 1.125em;
	font-weight: 700;
	padding: 0.4em 1.2em;
	min-width: 192px;
	text-align: center;
	&.--pointer {
		cursor: pointer;
	}
	&.--small {
		font-size: 1em;
	}
	&.--small-corner {
		border-radius: 10px;
	}
	&.--brush {
		background: url(../../img/button-brush.png) center center no-repeat;
		background-size: contain;
		padding: 1em 0.5em;
		border-radius: 0;
		min-width: 200px;
		font-size: 18px;
		font-weight: 800;
		text-align: center;
		&.--invert {
			background-image: url(../../img/button-brush-white.png);
			color: black;
		}
	}
	&.--brush-small {
		@extend .--brush;
		font-size: 1em;
	}
}


.show-more {
	font-family: $sans;
	cursor: pointer;
	width: 100%;
	text-align: center;
}

[data-currency-convert] {
	margin-top: 30px;
	button {
		width: 28px;
		height: 28px;
		border: 2px solid $black;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 14px;
		float: left;
		border-radius: 28px;
		background: transparent;
		text-indent: -999em;
		cursor: pointer;
		transition: all 0.25s ease-in-out;
		&:hover, &.active {
			border-color: $selectblue;
			background: $selectblue;
			&:after {
				color: #FFF;
			}
		}
		&:after {
			display: block;
			content: "";
			position: relative;
			font-size: 18px;
			text-indent: 0;
			font-family: $sansBold;
			color: #000;
		}
		&[data-currency=CNY] {
			&:after {
				content: "¥";
			}
		}
		&[data-currency=EUR] {
			&:after {
				content: "€";
			}
		}
		&[data-currency=GBP] {
			&:after {
				content: "£";
			}
		}
		&[data-currency=USD] {
			&:after {
				content: "$";
			}
		}
	}
}


.fave-icon {
	width: 50px;
	height: 50px;
	background-image: url(../../img/svg/star.svg);
	background-repeat: no-repeat;
	background-size: 50%;
	background-position: center;
	background-color: white;
	&.active {
		background-image: url(../../img/svg/star-white.svg);
		//border: 2px solid #4fadf2;
		background-color: #4fadf2
	}
	&.hidden {
		display: none;
	}
	&.no-session {
		border-color: #FFF;
	}
	cursor: pointer;
	z-index: 999;
	border: 2px solid #4fadf2;
	border-radius: 50px;
	outline: 0;
	display: block;
}

.fave-icon:hover {
	//background-image: url(../../img/svg/star.svg);
	opacity: 0.8;
	//border: 2px solid #4fadf2;
	
}

.fave-icon.active:hover {
	opacity: 0.8;
	/*background-image: url(../../img/svg/star-white.svg);
	border: 2px solid #fff;*/
}