.reviews {
	display: flex;
	flex-direction: row;

	@include mq($until: tablet) {
		flex-direction: column;

	}

}


.reviews__review {
	&:not(:last-child) {
		margin-right: 48px;
		@include mq($until: tablet) {
			display: none;
			text-align: center;
		}
	}


	flex: 0 1 calc(100% / 3);

	display: grid;
	grid-template-columns: 48px 1fr 1fr 1fr;
	grid-template-areas:
		"review review review review"
		"stars  stars  .      ."
		"face   name   name   name";
	grid-template-rows: auto 16px 48px;
	grid-row-gap: 16px;
	grid-column-gap: 16px;

	@include mq($until: tablet) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&.--light-bg {
		&, p, .review__content {
			color: black;
		}
		.review__bio strong {
			color: black;
		}
	}
}

.review__content {
	grid-area: review;
	font-style: italic;

	&, p {
		text-align: left !important;
		color: white;
	}

	@include mq($until: tablet) {
		text-align: center;
	}
}




.review__stars {
	grid-area: stars;
	display: flex;
	flex-direction: row;
	list-style-type: none;
	padding: 0;
	li {
		&:not(:last-child) {
			margin-right: 4px;
		}
		width: 16px;
		height: 15px;
		background: url('../../img/reviews/star.png');
	}
}

.--invert .review__stars li {
	background: url('../../img/reviews/star-white.png');
}

.review__photo {
	grid-area: face;
	img {
		border-radius: 50%;
		border: 1px solid white;
		width: 48px;
	}
}

.review__bio {
	grid-area: name;
	color: #666;
	strong {
		color: white;
	}
}

