.profile-dropdown {
	height: 46px;	
	
	
	display: grid;

	grid-template-columns: auto 32px 32px;
	grid-template-areas: "name dropdown profile" "menu menu menu";

	> * {
		display: flex;
		align-items: center;
	}

	border-radius: 10px;
	color: #012d6c;

	padding: 6px 8px;
	cursor: pointer;

	position: relative;

	&:hover {
		background: rgba(0, 0, 0, 0.04);
		.profile-dropdown__menu {
			opacity: 1;
		}	
	}

	z-index: 999999;


}

[data-profile-dropdown] {

	min-width: 150px;
	flex: 1 1 150px;

	opacity: 0;
	position: absolute;
	left: -999em;

	&.active {
		opacity: 1;
		left: 0px;
		position: relative;
		@include mq($from: tablet) {
			flex: 1 1 200px !important;
		}
	}
}

.profile-dropdown__name {
	grid-area: name;
	padding-right: 10px;
	justify-content: flex-end;
}

.profile-dropdown__dropdown {
	grid-area: dropdown;
	background-image: url('../../img/select-down.png');
	background-position: center;
	background-size: 12px 8px;
	background-repeat: no-repeat;
}

.profile-dropdown__profile {
	grid-area: profile;
	img {
		border-radius: 100%;
		width: 32px;
		height: 32px;
	}
}

.profile-dropdown:hover {
	
}

.profile-dropdown__menu {
	grid-area: menu;
	opacity: 0;
	transition: opacity 0.25s ease-in-out;
	position: absolute;
	left: 0px;
	display: flex !important;
	flex-direction: column !important;
	box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
	background: #FFF;
	border: 1px solid #ddd;
	align-items: flex-start !important;
	li {
		flex: 1 1 100%;
		
		width: 100%;
		svg, a {
			color: #012d6c !important;
			margin: 8px 6px;
		}
		a {
			display: flex;
			width: 100%;
		}
		/*span {
			margin: 8px 0 !important;
		}*/
		&:hover {
			background: #F5F5F5;
		}
		span > a {
			margin: 0px !important;
		}
		display: flex;
		flex-direction: row;
		svg {
			width: 24px;
			height: 24px;
			position: relative;
			top: 1px;
			margin: 0 16px 0 0 !important;
			color: #012d6c !important;
		}
		@include mq($until: tablet) {
			a {
				padding: 0 !important;
				justify-content: flex-start !important; 
				height: auto !important; 
			}
			[data-login-button] {
				padding: 0 !important;
			}
		}
	}
}