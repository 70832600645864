.site-header {
	margin: 70px 0;

	@include mq($until: tablet) {
		margin: 50px 0;
	}

	grid-column: 2 / -2;
	display: grid;
	grid-template-columns: 156px 1fr 50px;
	grid-template-rows: 1fr;
	grid-column-gap: 40px;
}

.site-header__logo {
	grid-template-column: 1;

	display: flex;
	align-items: center;
}


.site-header__nav {

	& > ul > li {

		> div {
			position: relative;
		}

		a:not([href]) {
			cursor: pointer;
			&:after {
				position: relative;
				display: inline-block;
				right: -8px;
				width: 8px;
				height: 4px;
				background: url(../../img/chevron-down.png) no-repeat;
				background-size: contain;
				top: -4px;
				content: "";			

			}
		}


		&:hover a:not([href]):after {
			background-image: url(../../img/chevron-up.png);
		}

		.down-arrow {
			width: 12px;
			height: 12px;
			background: #555;
			display: inline-block;
		}

		@include mq($from: tablet) {

			a:not([href]).hover:after {
				background-image: url(../../img/chevron-up.png);
			}

			.site-header__sub-nav {

				@include mq($until: desktop) {
					display: none;
				}

				z-index: 100000;
				opacity: 0;
				pointer-events: none;
				transition: opacity 0.2s ease-in-out;
				position: absolute;
				top: 0px;
				left: -1rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				background: #FFF;
				width: auto;
				border: 1px solid #777;
				border-radius: 8px;

				li {
					overflow: hidden;

					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}

					&:last-child {
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					}

					white-space: nowrap;
					width: 100%;
					a {
						position: relative;
						display: block;
						padding: 0.5rem 1rem;
						transition: background 0.25s ease-in-out;
						width: 100%;
						&:hover {
							background: #E7F6FA;
							color: #000;
						}
					}
				}
			}

		}
	}

	grid-column: 2;
	display: flex;

	ul {
		position: relative;
		list-style-type: none;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
    li {
    	height: 100%;
			justify-content: center;
    	flex: 1 1 auto;
      font-weight: 600;
      flex-direction: column;
      display: flex;
      .subnav {
      	top: 12px;
      }
      &:hover .site-header__sub-nav {
      	opacity: 1;
      	pointer-events: auto;
			}
    }
	}


	@include mq($until: tablet) {
		//display: none;
		grid-column: 1 / -1;
		grid-row: 2;

		.subnav {
			top: 0px !important;
			a {
				background: #E7F6FA;
			}
		}

		a:not([href]):not(.hover) ~ .subnav {
			display: none;
		}

		ul {
			flex-direction: column;
			align-items: stretch;
			text-align: center;
			background: white;

			li {
				a {
					&:not([href]):after {
						width: 10px;
						height: 5px;
						top: -1px !important;
					}
					padding: 1rem 0;
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	

}



.site-header__nav-toggle {
	display: none;
}



@include mq($until: tablet) {
	.site-header__nav-toggle:checked ~ .site-header__nav {
		display: block;
	}
	.site-header__nav-toggle:not(:checked) ~ .site-header__nav {
		display: none;
	}
}

.site-header__nav-burger {
	display: none;
	grid-column: 3;
	cursor: pointer;

	label {
		cursor: pointer;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background: url('/img/menu.png') no-repeat;
		background-size: 24px 24px;
		background-position: center;
		text-indent: -999em;
	}
}

@include mq($until: tablet) {
	.site-header__nav-burger {
		display: flex;
	}
}


.site-header__search {
	@include mq($until: tablet) {
		display: none;
	}
	grid-template-column: 3;

	display: flex;
	justify-content: center;
	align-items: center;

	a, button {
		width: 24px;
		height: 24px;
		background: url('/img/search.png') no-repeat;
		background-size: 24px 24px;
		background-position: center;
		text-indent: -999em;
	}

}


