.stats {
	display: flex;
	flex-direction: row;
	justify-content: space-around;

	@include mq($until: tablet) {
		flex-direction: column;
	}
}

.stats__stat {

	list-style-type: none;
	text-align: center;

	.stat__value {
		font-size: 65px;
		font-family: $sansBold;
		font-weight:600;
		line-height: 75px;
		margin-bottom: 16px;
		color: #000;
	}

	.stat__text {
		font-size: 22px;
		line-height: 26px;
		color: #222;
	}

}

