.page__header {

	.crumbs {
		
	}

	header {

		padding: 40px 0;

		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;

		form {
			width: 100%;
			max-width: 640px;
		}

		input {
			width: 100%;
			height: 64px;
			
			line-height: 64px;
			text-indent: 16px;
			border: 2px solid #EFEFEF;
			border-radius: 10px;
			font-size: 16px;
			font-weight: 500;
			box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

			@include mq($until: tablet) {
				text-align: left;
			}

		}

	}

	margin: 85px 0 284px 0;

	&.--small {
		margin: 40px 0;
	}

	@include mq($until: wide) {
		margin: 20px 0 150px 0;
	}
  @include mq($until: desktop) {
		margin: 20px 0 50px 0;
	}

	header > img {
		display: inline-block;
		height: 96px;
		width: 128px;
		margin-bottom: 29px;
	}


	input {
		//margin-top: 40px;
	}

	.help {
		margin-top: 32px;
		font-size: 18px;

		@include mq($until: tablet) {
			text-align: center;
			font-size: 15px;
		}
	}

}

.page__header h1 {
	text-align: center;
}

.page__header h1, header h1 {
	font-size: 72px;
	line-height: 1.1;

  @include mq($until: wide) {
    font-size: 55px;
  }

	@include mq($until: tablet) {
		font-size: 37px;
		line-height: 1.1;
		text-align: center;
	}

	font-family: $sansBold;
	font-weight: 800;
	color: black;
	margin: 0;
}