ol.list {

	width: 100%;

	counter-reset: list-counter;

	li {
		counter-increment: list-counter;
		line-height: 1.5;
		//display: flex;
		align-items: center;
		color: #012D6C;
	}


	&.--number-solid, &.--number-outline {

		margin: 25px 0;
		padding: 0;
		list-style: none;

		li {
			
			position: relative;
			padding-left: 62px;
			height: 48px;
			margin-bottom: 24px;

			&:before {	
				position: absolute;
				left: 0px;			
				display: inline-block;
				content: counter(list-counter);
				border: 2px solid white;
				border-radius: 28px;
				width: 28px;
				height: 28px;
				left: 8px;
				top: 8px;
				z-index: 5;
				line-height: 1;
				display: flex;
				color: white;
				justify-content: center;
				align-items: center;
			}

			&:nth-child(3n) {
				&:after {
					transform: rotate(5deg);
				}
			}

			&:nth-child(3n + 1) {
				&:after {
					transform: rotate(15deg);
				}
			}

			&:nth-child(3n + 2) {
				&:after {
					transform: rotate(-5deg);
				}
			}		
		}

	}


	&.--compressed {
		li {
			//height: 32px;
			height: auto;
			line-height: 32px;
			margin-bottom: 10px;
		}
	}


	&.--number-solid {

		li:after {
			position: absolute;
			left: 0px;
			top: 0px;
			content: "";
			display: inline-block;
			width: 48px;
			height: 48px;
			border-radius: 8px;
			background: $selectblue;
		}
	}

	&.--number-outline {

		li {
			padding-left: 52px;
			a {
				opacity: 0.5;
			}
		}

		li:before {
			color: $darkblue;
			border-color: $darkblue;
			width: 28px;
			height: 28px;
			top: 0px;
			left: 0px;
		}
	}



}