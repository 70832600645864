
.detail-tables {
	width: 100%;
}

.detail-table {

	border-collapse:separate; 
	border-spacing:4px;
	width: 100%;
	margin: 3rem 0;

	tbody tr {
		height: 50px;
		background: white;
		background: #FFFFFF;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
		border-radius: 4px;		
		margin-bottom: 4px; 
		/*display: flex;
		flex-direction: row;*/
	}
	td, th {
		height: 50px;
		font-size: 15px;
		font-family: $sansBold;
		font-weight: normal;
		text-align: left;
		//display: flex;
		//align-items: center;
		color: #000;
		padding: 0 4px;
		margin-top: 4px;
		&:first-child {
			padding-left: 12px;
		}
		&:last-child {
			padding-right: 12px;
		}
	}
}