.header-buttons {
	position: relative;
	top: -1rem;
	ul {
		list-style-type: none;
	}
	> ul {
		display: flex;
		flex-direction: row;
		> li {
			position: relative;
			background: #FFF;
			margin-left: 15px;
		}
		> li > a {
			display: block;
			width: 51px;
			height: 51px;
			border-radius: 50%;
			transition: all 0.25s ease-in-out;
			border: 2px solid #4FADF2;
			box-sizing: border-box;
			backdrop-filter: blur(15px);
			margin-left: 10px;
			text-indent: -999em;
			

			background: white;
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center;

			&.--share-icon {
				background-image: url(../../img/svg/share.svg);
			}

			&.--star-icon {
				background-image: url(../../img/svg/star.svg);
			}

			



		}

		li:hover {
			a.--share-icon {
				background-image: url(../../img/svg/share-white.svg);
			}

			a.--star-icon {
				background-image: url(../../img/svg/star-white.svg);
			}				
		}
	}



	.popup {
		display: none;
		position: absolute;
		top: 100%;
		right: 0px;
		padding: 24px;
		background: #FFFFFF;
		backdrop-filter: blur(15px);
		border-radius: 12px;
		border: 1px solid rgba(79, 173, 242, 0.26);
		z-index: 5;
		min-width: 250px;
		margin-top: 12px;
		&:before {
			position: absolute;
			content: "";
			z-index: 2;
			display: block;
			top: -12px;
			right: 12px;
			position: absolute;
			width: 23px;
			height: 12px;
			
			/*border: 1px solid rgba(79, 173, 242, 0.26);
			background: #FFFFFF;
			transform: rotate(-45deg);*/

			background: url(../../img/bubble-top.png);

		}
		&:after {
			display: block;
			position: absolute;
			width: 100%;
			top: -30px;
			height: 30px;
			background: transparent;
			content: "";
		}

		.icon {
			display: inline-block;
			width: 22px;
			height: 22px;
			margin-right: 8px;
			position: relative;
			top: 4px;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			&.--fb {
				background-image: url(../../img/universities/facebook.png);
			}
			&.--tw {
				background-image: url(../../img/universities/twitter.png);
			}
		}

		li {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			a {
				font-size: 15px;
				color: #012D6C;
				opacity: 0.4;
			}
		}
	}

	> ul > li:hover > a {
		background-color: $selectblue;
	}

	li:hover .popup {
		display: block;
	}
}

