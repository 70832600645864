@charset "UTF-8";
@font-face {
  font-family: 'SF Pro Display Regular';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Regular"), url("../../fonts/SFPRODISPLAYREGULAR.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Ultralight Italic';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Ultralight Italic"), url("../../fonts/SFPRODISPLAYULTRALIGHTITALIC.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Thin Italic"), url("../../fonts/SFPRODISPLAYTHINITALIC.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Light Italic"), url("../../fonts/SFPRODISPLAYLIGHTITALIC.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Medium"), url("../../fonts/SFPRODISPLAYMEDIUM.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Semibold Italic"), url("../../fonts/SFPRODISPLAYSEMIBOLDITALIC.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Bold';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Bold"), url("../../fonts/SFPRODISPLAYBOLD.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Heavy Italic"), url("../../fonts/SFPRODISPLAYHEAVYITALIC.woff") format("woff"); }

@font-face {
  font-family: 'SF Pro Display Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Black Italic"), url("../../fonts/SFPRODISPLAYBLACKITALIC.woff") format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: local("Inter"), url("../../fonts/inter/Inter-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'InterBold';
  font-style: normal;
  font-weight: normal;
  src: local("InterBold"), url("../../fonts/inter/Inter-Bold.ttf") format("truetype"); }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

body .freeform {
  margin-top: 50px; }

.freeform-alert {
  font-size: 16px;
  padding: 1rem;
  border-radius: 0.5rem;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #fff;
  background: #4FADF2;
  margin-bottom: 1rem; }

.freeform-alert.freeform-alert-success ~ div {
  display: none; }

body .freeform-row {
  margin-bottom: 32px; }
  body .freeform-row .freeform-column {
    padding: 0; }
  body .freeform-row .freeform-column .freeform-label.freeform-required::after {
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: #012D6C; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    body .freeform-row {
      flex-direction: column;
      margin-bottom: 0px; }
      body .freeform-row .freeform-column {
        margin-bottom: 20px; } }
  body .freeform-row .freeform-input {
    height: 48px;
    font-size: 18px;
    line-height: 27px;
    border: 2px solid #acacac;
    border-radius: 10px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: #65666A; }
    body .freeform-row .freeform-input[type=text], body .freeform-row .freeform-input[type=email] {
      padding: 0 16px; }
  body .freeform-row label {
    padding-bottom: 8px;
    color: #012D6C; }
  body .freeform-row textarea.freeform-input {
    min-height: 400px;
    padding: 32px; }
  @media (min-width: 46.25em) {
    body .freeform-row .freeform-column.freeform-column-content-align-left {
      justify-content: flex-start; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    body .freeform-row .freeform-column.freeform-column-content-align-left {
      justify-content: center; } }

.rangeslider,
.rangeslider__fill {
  display: block;
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; }

.rangeslider {
  background: #e6e6e6;
  position: relative;
  margin-bottom: 50px; }

.rangeslider--horizontal {
  height: 4px;
  width: 100%; }

.rangeslider--vertical {
  width: 20px;
  min-height: 150px;
  max-height: 100%;
  height: 100%; }

.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4; }

.rangeslider__fill {
  background: #4FADF2;
  position: absolute; }

.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%; }

.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%; }

.rangeslider__handle {
  background: white;
  border: 2px solid #4FADF2;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  /*background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.1)));
  background-image: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);*/
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; }

.rangeslider__handle:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.13)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
  background-image: linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));*/
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; }

.rangeslider__handle:active, .rangeslider--active .rangeslider__handle {
  /*background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));*/ }

.rangeslider--horizontal .rangeslider__handle {
  top: -8px;
  touch-action: pan-y;
  -ms-touch-action: pan-y; }

.rangeslider--vertical .rangeslider__handle {
  left: -10px;
  touch-action: pan-x;
  -ms-touch-action: pan-x; }

input[type="range"]:focus + .rangeslider .rangeslider__handle {
  /*-moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);*/ }

.rangeslider__bubble {
  position: absolute;
  color: white;
  border-radius: 5px;
  padding: 3px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  background: black;
  top: 20px;
  width: 44px;
  text-align: center;
  left: -22px; }
  .rangeslider__bubble:before {
    position: absolute;
    top: -5px;
    left: 18px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    display: block;
    border-bottom: 5px solid black; }

body .protip-container.protip-mixin--css-no-transition {
  transition: all 0s; }

body .protip-container.protip-mixin--css-bold {
  font-weight: bold; }

body .protip-container.protip-mixin--css-italic {
  font-style: italic; }

body .protip-container.protip-mixin--css-no-style {
  background: none;
  color: inherit; }

body .protip-container.protip-mixin--css-no-style .protip-arrow {
  display: none; }

.protip-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  box-sizing: border-box; }

.protip-container > i {
  position: absolute;
  left: 10px; }

.protip-container > i + div {
  padding-left: 20px; }

.protip-arrow {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent; }

.protip-show {
  opacity: 1;
  pointer-events: auto; }

/* Include skins here which you would like to use */
.protip-skin-default--size-tiny {
  padding: 8px;
  font-size: 10px;
  border-radius: 3px; }

.protip-skin-default--size-tiny[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 4px 4px 0 4px; }

.protip-skin-default--size-tiny[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-tiny[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-tiny[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  top: -4px;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 0 4px 4px 4px; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-tiny[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-default--size-tiny[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 0 4px 4px; }

.protip-skin-default--size-tiny[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-tiny[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-default--size-tiny[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 4px 4px 0; }

.protip-skin-default--size-tiny[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-tiny[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-default--size-tiny[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-default--size-tiny[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -4px;
  border-width: 0 4px 4px 0; }

.protip-skin-default--size-tiny[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-default--size-tiny[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -4px;
  margin: 0 0 0 4px;
  border-width: 4px 4px 0 0; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-default--size-tiny[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -4px;
  left: 100%;
  margin: 0 0 0 -4px;
  border-width: 0 0 4px 4px; }

.protip-skin-default--size-tiny[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-default--size-tiny[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -4px;
  margin: -4px 0 0 4px;
  border-width: 4px 0 0 4px; }

.protip-skin-default--size-small {
  padding: 13px;
  font-size: 12px;
  border-radius: 4px; }

.protip-skin-default--size-small[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 6px 6px 0 6px; }

.protip-skin-default--size-small[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-small[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-small[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-default--size-small[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="bottom-right"] .protip-arrow {
  top: -6px;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 0 6px 6px 6px; }

.protip-skin-default--size-small[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-small[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-small[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-default--size-small[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 0 6px 6px; }

.protip-skin-default--size-small[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-small[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-default--size-small[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 6px 6px 0; }

.protip-skin-default--size-small[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-small[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-default--size-small[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-default--size-small[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -6px;
  border-width: 0 6px 6px 0; }

.protip-skin-default--size-small[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-default--size-small[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -6px;
  margin: 0 0 0 6px;
  border-width: 6px 6px 0 0; }

.protip-skin-default--size-small[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-default--size-small[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -6px;
  left: 100%;
  margin: 0 0 0 -6px;
  border-width: 0 0 6px 6px; }

.protip-skin-default--size-small[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-default--size-small[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -6px;
  margin: -6px 0 0 6px;
  border-width: 6px 0 0 6px; }

.protip-skin-default--size-normal {
  padding: 16px;
  font-size: 15px;
  border-radius: 6px; }

.protip-skin-default--size-normal[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 8px 8px 0 8px; }

.protip-skin-default--size-normal[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-normal[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-normal[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-default--size-normal[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  top: -8px;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 0 8px 8px 8px; }

.protip-skin-default--size-normal[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-normal[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-default--size-normal[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 0 8px 8px; }

.protip-skin-default--size-normal[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-normal[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-default--size-normal[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 8px 8px 0; }

.protip-skin-default--size-normal[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-normal[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-default--size-normal[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-default--size-normal[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -8px;
  border-width: 0 8px 8px 0; }

.protip-skin-default--size-normal[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-default--size-normal[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -8px;
  margin: 0 0 0 8px;
  border-width: 8px 8px 0 0; }

.protip-skin-default--size-normal[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-default--size-normal[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -8px;
  left: 100%;
  margin: 0 0 0 -8px;
  border-width: 0 0 8px 8px; }

.protip-skin-default--size-normal[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-default--size-normal[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -8px;
  margin: -8px 0 0 8px;
  border-width: 8px 0 0 8px; }

.protip-skin-default--size-big {
  padding: 20px;
  font-size: 18px;
  border-radius: 8px; }

.protip-skin-default--size-big[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 10px 10px 0 10px; }

.protip-skin-default--size-big[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-big[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-big[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-default--size-big[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="bottom-right"] .protip-arrow {
  top: -10px;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 0 10px 10px 10px; }

.protip-skin-default--size-big[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-big[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-big[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-default--size-big[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 0 10px 10px; }

.protip-skin-default--size-big[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-big[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-default--size-big[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 10px 10px 0; }

.protip-skin-default--size-big[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-big[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-default--size-big[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-default--size-big[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -10px;
  border-width: 0 10px 10px 0; }

.protip-skin-default--size-big[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-default--size-big[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -10px;
  margin: 0 0 0 10px;
  border-width: 10px 10px 0 0; }

.protip-skin-default--size-big[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-default--size-big[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -10px;
  left: 100%;
  margin: 0 0 0 -10px;
  border-width: 0 0 10px 10px; }

.protip-skin-default--size-big[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-default--size-big[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -10px;
  margin: -10px 0 0 10px;
  border-width: 10px 0 0 10px; }

.protip-skin-default--size-large {
  padding: 20px;
  font-size: 18px;
  border-radius: 8px; }

.protip-skin-default--size-large[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="top"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 12px 12px 0 12px; }

.protip-skin-default--size-large[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-large[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-large[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-default--size-large[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="bottom-right"] .protip-arrow {
  top: -12px;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 0 12px 12px 12px; }

.protip-skin-default--size-large[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-default--size-large[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-default--size-large[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-default--size-large[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="left"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 0 12px 12px; }

.protip-skin-default--size-large[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-large[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-default--size-large[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="right"] .protip-arrow,
.protip-skin-default--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 12px 12px 0; }

.protip-skin-default--size-large[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-default--size-large[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-default--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-default--size-large[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-default--size-large[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -12px;
  border-width: 0 12px 12px 0; }

.protip-skin-default--size-large[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-default--size-large[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -12px;
  margin: 0 0 0 12px;
  border-width: 12px 12px 0 0; }

.protip-skin-default--size-large[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-default--size-large[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -12px;
  left: 100%;
  margin: 0 0 0 -12px;
  border-width: 0 0 12px 12px; }

.protip-skin-default--size-large[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-default--size-large[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -12px;
  margin: -12px 0 0 12px;
  border-width: 12px 0 0 12px; }

.protip-skin-default--scheme-pro.protip-container {
  color: #FFF;
  background: #21CBFF;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-pro[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #21CBFF; }

.protip-skin-default--scheme-pro[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #21CBFF; }

.protip-skin-default--scheme-blue.protip-container {
  color: #fff;
  background: #336699;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-blue[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-blue[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-default--scheme-blue[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-default--scheme-red.protip-container {
  color: #fff;
  background: #802731;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-red[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-red[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-default--scheme-red[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-default--scheme-aqua.protip-container {
  color: #fff;
  background: #339996;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-aqua[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-aqua[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-default--scheme-aqua[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-default--scheme-dark.protip-container {
  color: #fff;
  background: #333;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-dark[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-dark[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-default--scheme-dark[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-default--scheme-dark-transparent.protip-container {
  color: #fff;
  background: rgba(20, 20, 20, 0.8);
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-dark-transparent[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="top-right"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-dark-transparent[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-dark-transparent[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-default--scheme-black.protip-container {
  color: #fff;
  background: #000;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-black[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-black[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-default--scheme-black[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-default--scheme-leaf.protip-container {
  color: #fff;
  background: #012d6c;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-leaf[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-leaf[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-default--scheme-leaf[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-default--scheme-purple.protip-container {
  color: #fff;
  background: #613399;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-purple[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-purple[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-default--scheme-purple[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-default--scheme-pink.protip-container {
  color: #000;
  background: #D457AA;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-pink[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-pink[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-default--scheme-pink[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-default--scheme-orange.protip-container {
  color: #000;
  background: #E64426;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-orange[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-orange[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-default--scheme-orange[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-default--scheme-white.protip-container {
  color: #000;
  background: #FFF;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-default--scheme-white[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="bottom"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="left-top"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="left"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-white[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-default--scheme-white[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #FFF; }

.protip-skin-square--size-tiny {
  padding: 8px;
  font-size: 10px; }

.protip-skin-square--size-tiny[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 4px 4px 0 4px; }

.protip-skin-square--size-tiny[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-tiny[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-tiny[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  top: -4px;
  left: auto;
  margin: 0 0 0 -4px;
  border-width: 0 4px 4px 4px; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-tiny[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-tiny[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -4px; }

.protip-skin-square--size-tiny[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 0 4px 4px; }

.protip-skin-square--size-tiny[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-tiny[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-tiny[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-square--size-tiny[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -4px 0 0 0;
  border-width: 4px 4px 4px 0; }

.protip-skin-square--size-tiny[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-tiny[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-tiny[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -4px; }

.protip-skin-square--size-tiny[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-square--size-tiny[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -4px;
  border-width: 0 4px 4px 0; }

.protip-skin-square--size-tiny[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-square--size-tiny[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -4px;
  margin: 0 0 0 4px;
  border-width: 4px 4px 0 0; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-square--size-tiny[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -4px;
  left: 100%;
  margin: 0 0 0 -4px;
  border-width: 0 0 4px 4px; }

.protip-skin-square--size-tiny[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-square--size-tiny[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -4px;
  margin: -4px 0 0 4px;
  border-width: 4px 0 0 4px; }

.protip-skin-square--size-small {
  padding: 13px;
  font-size: 12px; }

.protip-skin-square--size-small[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 6px 6px 0 6px; }

.protip-skin-square--size-small[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-small[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-small[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-square--size-small[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="bottom-right"] .protip-arrow {
  top: -6px;
  left: auto;
  margin: 0 0 0 -6px;
  border-width: 0 6px 6px 6px; }

.protip-skin-square--size-small[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-small[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-small[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -6px; }

.protip-skin-square--size-small[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 0 6px 6px; }

.protip-skin-square--size-small[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-small[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-small[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-square--size-small[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -6px 0 0 0;
  border-width: 6px 6px 6px 0; }

.protip-skin-square--size-small[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-small[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-small[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -6px; }

.protip-skin-square--size-small[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-square--size-small[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -6px;
  border-width: 0 6px 6px 0; }

.protip-skin-square--size-small[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-square--size-small[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -6px;
  margin: 0 0 0 6px;
  border-width: 6px 6px 0 0; }

.protip-skin-square--size-small[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-square--size-small[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -6px;
  left: 100%;
  margin: 0 0 0 -6px;
  border-width: 0 0 6px 6px; }

.protip-skin-square--size-small[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-square--size-small[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -6px;
  margin: -6px 0 0 6px;
  border-width: 6px 0 0 6px; }

.protip-skin-square--size-normal {
  padding: 16px;
  font-size: 15px; }

.protip-skin-square--size-normal[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 8px 8px 0 8px; }

.protip-skin-square--size-normal[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-normal[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-normal[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-square--size-normal[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  top: -8px;
  left: auto;
  margin: 0 0 0 -8px;
  border-width: 0 8px 8px 8px; }

.protip-skin-square--size-normal[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-normal[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-normal[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -8px; }

.protip-skin-square--size-normal[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 0 8px 8px; }

.protip-skin-square--size-normal[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-normal[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-normal[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-square--size-normal[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -8px 0 0 0;
  border-width: 8px 8px 8px 0; }

.protip-skin-square--size-normal[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-normal[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-normal[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -8px; }

.protip-skin-square--size-normal[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-square--size-normal[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -8px;
  border-width: 0 8px 8px 0; }

.protip-skin-square--size-normal[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-square--size-normal[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -8px;
  margin: 0 0 0 8px;
  border-width: 8px 8px 0 0; }

.protip-skin-square--size-normal[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-square--size-normal[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -8px;
  left: 100%;
  margin: 0 0 0 -8px;
  border-width: 0 0 8px 8px; }

.protip-skin-square--size-normal[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-square--size-normal[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -8px;
  margin: -8px 0 0 8px;
  border-width: 8px 0 0 8px; }

.protip-skin-square--size-big {
  padding: 20px;
  font-size: 18px; }

.protip-skin-square--size-big[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 10px 10px 0 10px; }

.protip-skin-square--size-big[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-big[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-big[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-square--size-big[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="bottom-right"] .protip-arrow {
  top: -10px;
  left: auto;
  margin: 0 0 0 -10px;
  border-width: 0 10px 10px 10px; }

.protip-skin-square--size-big[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-big[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-big[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -10px; }

.protip-skin-square--size-big[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 0 10px 10px; }

.protip-skin-square--size-big[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-big[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-big[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-square--size-big[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -10px 0 0 0;
  border-width: 10px 10px 10px 0; }

.protip-skin-square--size-big[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-big[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-big[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -10px; }

.protip-skin-square--size-big[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-square--size-big[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -10px;
  border-width: 0 10px 10px 0; }

.protip-skin-square--size-big[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-square--size-big[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -10px;
  margin: 0 0 0 10px;
  border-width: 10px 10px 0 0; }

.protip-skin-square--size-big[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-square--size-big[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -10px;
  left: 100%;
  margin: 0 0 0 -10px;
  border-width: 0 0 10px 10px; }

.protip-skin-square--size-big[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-square--size-big[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -10px;
  margin: -10px 0 0 10px;
  border-width: 10px 0 0 10px; }

.protip-skin-square--size-large {
  padding: 20px;
  font-size: 18px; }

.protip-skin-square--size-large[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="top"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="top-right"] .protip-arrow {
  top: 100%;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 12px 12px 0 12px; }

.protip-skin-square--size-large[data-pt-position="top-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-large[data-pt-position="top"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-large[data-pt-position="top-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-square--size-large[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="bottom-right"] .protip-arrow {
  top: -12px;
  left: auto;
  margin: 0 0 0 -12px;
  border-width: 0 12px 12px 12px; }

.protip-skin-square--size-large[data-pt-position="bottom-left"] .protip-arrow {
  left: 20px; }

.protip-skin-square--size-large[data-pt-position="bottom"] .protip-arrow {
  left: 50%; }

.protip-skin-square--size-large[data-pt-position="bottom-right"] .protip-arrow {
  left: auto;
  right: 20px;
  margin-right: -12px; }

.protip-skin-square--size-large[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="left"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  left: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 0 12px 12px; }

.protip-skin-square--size-large[data-pt-position="left-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-large[data-pt-position="left"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-large[data-pt-position="left-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-square--size-large[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="right"] .protip-arrow,
.protip-skin-square--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  right: 100%;
  margin: -12px 0 0 0;
  border-width: 12px 12px 12px 0; }

.protip-skin-square--size-large[data-pt-position="right-top"] .protip-arrow {
  top: 20px; }

.protip-skin-square--size-large[data-pt-position="right"] .protip-arrow {
  top: 50%; }

.protip-skin-square--size-large[data-pt-position="right-bottom"] .protip-arrow {
  top: auto;
  bottom: 20px;
  margin-bottom: -12px; }

.protip-skin-square--size-large[data-pt-position="top-left-corner"] {
  border-bottom-right-radius: 0; }

.protip-skin-square--size-large[data-pt-position="top-left-corner"] .protip-arrow {
  top: 100%;
  left: 100%;
  margin: 0 0 0 -12px;
  border-width: 0 12px 12px 0; }

.protip-skin-square--size-large[data-pt-position="top-right-corner"] {
  border-bottom-left-radius: 0; }

.protip-skin-square--size-large[data-pt-position="top-right-corner"] .protip-arrow {
  top: 100%;
  left: -12px;
  margin: 0 0 0 12px;
  border-width: 12px 12px 0 0; }

.protip-skin-square--size-large[data-pt-position="bottom-left-corner"] {
  border-top-right-radius: 0; }

.protip-skin-square--size-large[data-pt-position="bottom-left-corner"] .protip-arrow {
  top: -12px;
  left: 100%;
  margin: 0 0 0 -12px;
  border-width: 0 0 12px 12px; }

.protip-skin-square--size-large[data-pt-position="bottom-right-corner"] {
  border-top-left-radius: 0; }

.protip-skin-square--size-large[data-pt-position="bottom-right-corner"] .protip-arrow {
  top: 0;
  left: -12px;
  margin: -12px 0 0 12px;
  border-width: 12px 0 0 12px; }

.protip-skin-square--scheme-pro.protip-container {
  color: #000;
  background: #da2e2b;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-pro[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-pro[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #da2e2b; }

.protip-skin-square--scheme-pro[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #da2e2b; }

.protip-skin-square--scheme-blue.protip-container {
  color: #fff;
  background: #336699;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-blue[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-blue[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #336699; }

.protip-skin-square--scheme-blue[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #336699; }

.protip-skin-square--scheme-red.protip-container {
  color: #fff;
  background: #802731;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-red[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-red[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #802731; }

.protip-skin-square--scheme-red[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #802731; }

.protip-skin-square--scheme-aqua.protip-container {
  color: #fff;
  background: #339996;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-aqua[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-aqua[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #339996; }

.protip-skin-square--scheme-aqua[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #339996; }

.protip-skin-square--scheme-dark-transparent.protip-container {
  color: #fff;
  background: rgba(20, 20, 20, 0.8);
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-dark-transparent[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="top-right"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-dark-transparent[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark-transparent[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: rgba(20, 20, 20, 0.8); }

.protip-skin-square--scheme-dark.protip-container {
  color: #fff;
  background: #333;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-dark[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-dark[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #333; }

.protip-skin-square--scheme-dark[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #333; }

.protip-skin-square--scheme-black.protip-container {
  color: #fff;
  background: #000;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-black[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-black[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #000; }

.protip-skin-square--scheme-black[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #000; }

.protip-skin-square--scheme-leaf.protip-container {
  color: #fff;
  background: #012d6c;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-leaf[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-leaf[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #012d6c; }

.protip-skin-square--scheme-leaf[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #012d6c; }

.protip-skin-square--scheme-purple.protip-container {
  color: #fff;
  background: #613399;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-purple[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-purple[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #613399; }

.protip-skin-square--scheme-purple[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #613399; }

.protip-skin-square--scheme-pink.protip-container {
  color: #000;
  background: #D457AA;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-pink[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-pink[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #D457AA; }

.protip-skin-square--scheme-pink[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #D457AA; }

.protip-skin-square--scheme-orange.protip-container {
  color: #000;
  background: #E64426;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-orange[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-orange[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #E64426; }

.protip-skin-square--scheme-orange[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #E64426; }

.protip-skin-square--scheme-white.protip-container {
  color: #000;
  background: #FFF;
  font-family: sans-serif;
  transition: opacity 0.2s ease-out; }

.protip-skin-square--scheme-white[data-pt-position="top-left"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="top"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="top-right"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="bottom-left"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="bottom"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="bottom-right"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="left-top"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="left"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="left-bottom"] .protip-arrow {
  border-left-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="right-top"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="right"] .protip-arrow,
.protip-skin-square--scheme-white[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="top-left-corner"] .protip-arrow {
  border-right-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="top-right-corner"] .protip-arrow {
  border-top-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="bottom-left-corner"] .protip-arrow {
  border-bottom-color: #FFF; }

.protip-skin-square--scheme-white[data-pt-position="bottom-right-corner"] .protip-arrow {
  border-left-color: #FFF; }

.autocomplete-suggestions {
  box-sizing: border-box;
  border: 1px solid #999;
  background: #FFF;
  cursor: default;
  overflow: auto;
  display: block;
  max-width: 640px;
  top: 100%; }

.autocomplete-suggestion {
  padding: 0.1rem 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
  min-height: 40px;
  color: #2c5d90;
  font-size: 18px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  text-align: left;
  margin: 10px;
  border-radius: 7px;
  transition: all 0.25s ease-in-out;
  cursor: pointer; }
  .autocomplete-suggestion .subheading {
    color: rgba(44, 93, 144, 0.75); }
  .autocomplete-suggestion:hover {
    background: #4FADF2;
    color: white; }
    .autocomplete-suggestion:hover .subheading {
      color: rgba(255, 255, 255, 0.75); }
    .autocomplete-suggestion:hover strong {
      color: #FFF; }

.autocomplete-no-suggestion {
  padding: 0.1rem 0.3rem; }

.autocomplete-selected {
  background: #F0F0F0; }

.autocomplete-suggestions strong {
  font-weight: bold;
  color: #21cbff;
  transition: all 0.25s ease-in-out; }

.autocomplete-group {
  padding: 0.1rem 0.3rem;
  font-weight: bold;
  font-size: 1rem;
  color: #000;
  display: block;
  border-bottom: 1px solid #000; }

.autocomplete-suggestion .subheading {
  font-size: 0.85rem;
  font-weight: normal; }

.js-search-wrapper {
  margin-top: 40px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; }
  .js-search-wrapper button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1rem;
    text-indent: -999rem;
    height: calc(100% - 2rem);
    padding: 0 1rem;
    color: #FFF;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
    font-size: .8rem;
    border: none;
    background: url(../../img/search.png) no-repeat center center; }

.autocomplete-suggestion {
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-column-gap: 20px;
  grid-template-areas: "image title" "image location" "image course"; }

.autocomplete-suggestion__image {
  grid-area: image; }
  .autocomplete-suggestion__image img {
    width: 55px;
    height: 55px;
    object-fit: contain; }

.autocomplete-suggestion__title {
  margin-top: -6px;
  grid-area: title; }

.autocomplete-suggestion__location {
  grid-area: location; }

.autocomplete-suggestion__course {
  grid-area: course; }

.js-search-wrapper {
  position: relative;
  max-width: 640px; }
  .js-search-wrapper:after {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 3rem;
    top: 18px;
    content: "";
    display: block;
    background-size: contain; }
  .js-search-wrapper.searching:after {
    background-image: url(../../img/spinner.svg); }

.breadcrumbs ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0; }
  .breadcrumbs ul li {
    font-size: 1rem;
    font-weight: 700; }
    .breadcrumbs ul li:last-child {
      opacity: 0.4; }

.breadcrumbs .breadcrumbs__crumb {
  color: #012D6C;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .breadcrumbs .breadcrumbs__crumb a {
    opacity: 1; }
  .breadcrumbs .breadcrumbs__crumb:not(:last-child):after {
    position: relative;
    width: 13px;
    height: 11px;
    content: " ";
    margin: 0 9px;
    display: block;
    background: url(../../img/crumb-arrow.png) no-repeat; }

.blog-card {
  margin-bottom: 0.75rem;
  display: grid;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .blog-card a {
    display: contents; }
  .blog-card h2, .blog-card h3 {
    margin: 0;
    padding: 0; }
  .blog-card .blog-card__image {
    grid-area: image; }
    .blog-card .blog-card__image img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      min-height: 128px; }
  .blog-card .blog-card__title {
    grid-area: title;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    display: flex;
    margin-bottom: 4px; }
  .blog-card .blog-card__meta {
    grid-area: meta;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #65666A;
    text-transform: uppercase;
    display: flex;
    padding-bottom: 4px; }
  .blog-card.--featured {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    background: white;
    border: 3px solid #E7F6FA;
    border-radius: 20px;
    grid-template-areas: "image image image" ".     title ." ".     meta  .";
    grid-template-rows: 240px minmax(min-content, 94px) auto;
    grid-template-columns: 32px auto 32px; }
    @media (min-width: 46.25em) {
      .blog-card.--featured {
        width: 700px; } }
    @media (max-width: 46.24em) {
      .blog-card.--featured {
        width: auto; } }
    @media (min-width: 46.25em) {
      .blog-card.--featured .blog-card__image img {
        min-height: 0px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px; }
      .blog-card.--featured .blog-card__title {
        font-size: 32px;
        padding: 40px 0 16px 0;
        line-height: 1.3;
        align-items: flex-end; }
      .blog-card.--featured .blog-card__meta {
        font-size: 16px;
        padding-bottom: 32px; } }
    @media (min-width: 20em) and (max-width: 46.24em) {
      .blog-card.--featured {
        grid-template-rows: 160px auto auto;
        margin-bottom: 36px; }
        .blog-card.--featured .blog-card__image img {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px; }
        .blog-card.--featured .blog-card__title {
          font-size: 19px;
          padding: 32px 0 32px 0;
          line-height: 1;
          align-items: flex-end; }
        .blog-card.--featured .blog-card__meta {
          font-size: 14px;
          padding-bottom: 32px; } }
  .blog-card.--small {
    grid-template-areas: "meta meta" "image title";
    grid-template-columns: 80px auto;
    grid-template-rows: auto minmax(min-content, 80px);
    grid-column-gap: 16px;
    grid-row-gap: 8px; }
    .blog-card.--small .blog-card__image img {
      border-radius: 10px;
      height: 80px;
      width: 80px;
      min-height: 0px; }
    .blog-card.--small .blog-card__title {
      align-items: center;
      font-size: 14px;
      line-height: 16px; }
  .blog-card.--standard {
    grid-template-areas: "image title" "image meta";
    grid-template-columns: 128px auto;
    grid-column-gap: 32px; }
    .blog-card.--standard.--border {
      padding-bottom: 32px;
      border-bottom: 1px solid #ACACAC; }
    .blog-card.--standard .blog-card__image img {
      border-radius: 10px; }
    .blog-card.--standard .blog-card__title {
      align-items: flex-end;
      font-size: 20px;
      margin-bottom: 10px; }
    .blog-card.--standard .blog-card__meta {
      align-items: flex-start;
      font-size: 19px;
      color: #4FADF2; }

@media (min-width: 20em) and (max-width: 46.24em) {
  html body .blog-listing div.blog-card {
    grid-template-areas: "image meta" "image title";
    grid-template-columns: 80px auto;
    grid-template-rows: auto minmax(min-content, 48px);
    grid-column-gap: 32px;
    grid-row-gap: 8px; }
    html body .blog-listing div.blog-card .blog-card__image img {
      border-radius: 10px;
      height: 80px;
      width: 80px;
      min-height: 0px; }
    html body .blog-listing div.blog-card .blog-card__title {
      align-items: flex-start;
      font-size: 14px;
      line-height: 16px; }
    html body .blog-listing div.blog-card .blog-card__meta {
      align-items: flex-end;
      font-size: 13px;
      color: #65666A; } }

.button {
  display: inline-block;
  background: #4FADF2;
  color: white;
  border-width: 0px;
  border-radius: 58px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.125em;
  font-weight: 700;
  padding: 0.4em 1.2em;
  min-width: 192px;
  text-align: center; }
  .button.--pointer {
    cursor: pointer; }
  .button.--small {
    font-size: 1em; }
  .button.--small-corner {
    border-radius: 10px; }
  .button.--brush, .button.--brush-small {
    background: url(../../img/button-brush.png) center center no-repeat;
    background-size: contain;
    padding: 1em 0.5em;
    border-radius: 0;
    min-width: 200px;
    font-size: 18px;
    font-weight: 800;
    text-align: center; }
    .button.--brush.--invert, .button.--invert.--brush-small {
      background-image: url(../../img/button-brush-white.png);
      color: black; }
  .button.--brush-small {
    font-size: 1em; }

.show-more {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  cursor: pointer;
  width: 100%;
  text-align: center; }

[data-currency-convert] {
  margin-top: 30px; }
  [data-currency-convert] button {
    width: 28px;
    height: 28px;
    border: 2px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    float: left;
    border-radius: 28px;
    background: transparent;
    text-indent: -999em;
    cursor: pointer;
    transition: all 0.25s ease-in-out; }
    [data-currency-convert] button:hover, [data-currency-convert] button.active {
      border-color: #4FADF2;
      background: #4FADF2; }
      [data-currency-convert] button:hover:after, [data-currency-convert] button.active:after {
        color: #FFF; }
    [data-currency-convert] button:after {
      display: block;
      content: "";
      position: relative;
      font-size: 18px;
      text-indent: 0;
      font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      color: #000; }
    [data-currency-convert] button[data-currency=CNY]:after {
      content: "¥"; }
    [data-currency-convert] button[data-currency=EUR]:after {
      content: "€"; }
    [data-currency-convert] button[data-currency=GBP]:after {
      content: "£"; }
    [data-currency-convert] button[data-currency=USD]:after {
      content: "$"; }

.fave-icon {
  width: 50px;
  height: 50px;
  background-image: url(../../img/svg/star.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  background-color: white;
  cursor: pointer;
  z-index: 999;
  border: 2px solid #4fadf2;
  border-radius: 50px;
  outline: 0;
  display: block; }
  .fave-icon.active {
    background-image: url(../../img/svg/star-white.svg);
    background-color: #4fadf2; }
  .fave-icon.hidden {
    display: none; }
  .fave-icon.no-session {
    border-color: #FFF; }

.fave-icon:hover {
  opacity: 0.8; }

.fave-icon.active:hover {
  opacity: 0.8;
  /*background-image: url(../../img/svg/star-white.svg);
	border: 2px solid #fff;*/ }

.content-page__block.image {
  width: 40%;
  margin: 10px 20px; }
  .content-page__block.image.left {
    margin-left: 0px;
    float: left; }
  .content-page__block.image.right {
    margin-right: 0px;
    float: right; }

.page-header.content-page .title {
  padding: 30px 0 100px 0; }

.page-header.content-page .page-header__content {
  padding: 0; }

.detail-tables {
  width: 100%; }

.detail-table {
  border-collapse: separate;
  border-spacing: 4px;
  width: 100%;
  margin: 3rem 0; }
  .detail-table tbody tr {
    height: 50px;
    background: white;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-bottom: 4px;
    /*display: flex;
		flex-direction: row;*/ }
  .detail-table td, .detail-table th {
    height: 50px;
    font-size: 15px;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: normal;
    text-align: left;
    color: #000;
    padding: 0 4px;
    margin-top: 4px; }
    .detail-table td:first-child, .detail-table th:first-child {
      padding-left: 12px; }
    .detail-table td:last-child, .detail-table th:last-child {
      padding-right: 12px; }

.faves {
  background: rgba(231, 246, 250, 0.8);
  backdrop-filter: blur(50px);
  padding: 16px;
  width: 100%;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px; }
  .faves__fave {
    display: grid;
    position: relative;
    grid-template-columns: 89px 1fr;
    grid-column-gap: 24px;
    grid-template-areas: "img content";
    border-radius: 10px;
    overflow: hidden;
    background: white; }
    .faves__fave a {
      display: contents; }
    .faves__fave:not(:last-child) {
      margin-bottom: 16px; }
  .faves__img {
    width: 96px;
    height: 96px;
    object-fit: cover;
    grid-area: img; }
  .faves__content {
    grid-area: content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .faves__title {
    font-size: 14px;
    margin: 0;
    color: #12141A;
    max-width: 80%;
    line-height: 1.2;
    margin-bottom: 0.3em; }
  .faves__location {
    font-size: 12px;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
    color: #12141A;
    opacity: 0.4;
    max-width: 80%; }
  .faves__close {
    position: absolute;
    display: block;
    text-indent: -999em;
    color: white;
    width: 16px;
    height: 16px;
    right: 16px;
    top: 16px;
    border-radius: 16px;
    background: #ACACAC;
    background-image: url("../../img/universities/x.png");
    background-position: center center;
    background-repeat: no-repeat; }

body.faqs .page-header__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-column: 1 / -1;
  padding-bottom: 150px; }
  body.faqs .page-header__content .pretitle {
    color: #21CBFF !important; }
  body.faqs .page-header__content .title {
    margin: 0 0 50px 0;
    color: #000; }

.faq-item {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: #FFFFFF;
  /* Shadows */
  box-shadow: 0px 6px 20px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  /* Inside auto layout */
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  position: relative; }
  .faq-item:after {
    content: "";
    display: block;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 20px;
    height: 20px;
    background: url("../../img/faq/expand-up.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  .faq-item.active .faq-item__answer {
    display: block; }
  .faq-item.active:after {
    background-image: url("../../img/faq/expand-down.svg"); }

.faq-item__question {
  height: 24px;
  /* Large/Heading 2 */
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: -0.01em;
  color: #000000;
  position: relative; }

.faq-item__answer {
  display: none;
  margin-top: 20px;
  color: #000; }

.faq-input {
  border-radius: 10px;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  line-height: 50px;
  height: 50px;
  padding: 0 20px;
  font-size: 16px;
  color: #2C5D90;
  border: 3px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 10px; }

.floating-nav-bar {
  position: relative;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 128px 0;
  z-index: 10; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .floating-nav-bar {
      display: none; } }
  .floating-nav-bar ul {
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 0 10vw; }
    .floating-nav-bar ul.detached {
      position: fixed;
      background: white;
      top: 0px;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0182725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0096802); }
    .floating-nav-bar ul li, .floating-nav-bar ul a {
      font-size: 19px;
      font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

.form .wrapper, form .wrapper {
  width: 100%;
  position: relative; }

.form__control {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 46.24em) {
    .form__control {
      flex-direction: column; } }

.form__show-filters {
  display: flex;
  justify-content: flex-end;
  z-index: 20; }
  .form__show-filters * {
    color: #000; }

.form__select:not(.--heading) select {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #12141A;
  border: 1px solid rgba(18, 20, 26, 0.1);
  width: 100%;
  text-indent: 5px;
  border-radius: 5px; }

.form__select select {
  padding-right: 100px; }

.form__select.--sort {
  justify-content: space-between; }
  @media (max-width: 46.24em) {
    .form__select.--sort button {
      display: none; } }
  .form__select.--sort select {
    margin-right: auto; }

.form__select.--academic select {
  height: 57px;
  font-size: 18px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #000;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 0 26px 0 14px;
  text-indent: 4px; }

.form__select.--heading {
  display: flex; }
  .form__select.--heading h3 {
    display: inline-block;
    color: #777;
    padding-right: 10px;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .form__select.--heading h3, .form__select.--heading select {
    font-size: 18px;
    line-height: 1.3;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    appearance: none;
    font-weight: 600; }
  .form__select.--heading select {
    border: none;
    background: transparent;
    background-image: url("../../img/select-down.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 78px) center;
    -webkit-appearance: none; }

select[disabled] {
  opacity: 0.5; }

.form__fieldset {
  padding: 0;
  margin: 0 0 40px 0;
  border: 2px solid rgba(18, 20, 26, 0.1);
  border-radius: 16px;
  padding: 16px 26px 22px; }
  .form__fieldset:not(.--no-border) {
    background: white; }
  .form__fieldset.--select {
    border: none;
    padding: 18px 30px;
    display: grid;
    grid-row-gap: 8px;
    padding: 0;
    font0size: 20px; }

.form__input-wrapper.--yes-no, .form__input-wrapper.--multi-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .form__input-wrapper.--yes-no input, .form__input-wrapper.--multi-select input {
    display: none; }
  .form__input-wrapper.--yes-no label:not(:last-child), .form__input-wrapper.--multi-select label:not(:last-child) {
    margin-right: 12px; }
  .form__input-wrapper.--yes-no label, .form__input-wrapper.--multi-select label {
    cursor: pointer;
    padding: 4px 12px;
    border: 2px solid rgba(18, 20, 26, 0.1);
    border-radius: 8px;
    background: white;
    margin-bottom: 16px; }
  .form__input-wrapper.--yes-no input:checked + label, .form__input-wrapper.--multi-select input:checked + label {
    background: #4FADF2;
    color: white; }

.form__label {
  border: 2px solid rgba(18, 20, 26, 0.1);
  border-radius: 16px;
  padding: 16px 26px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  color: #12141A;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .form__label.--no-border {
    border: none;
    padding: 0px;
    justify-content: center; }

.form__heading {
  font-size: 14px;
  line-height: 16px;
  color: #12141A;
  display: block;
  flex: 1 1 100%;
  font-weight: 600;
  margin-bottom: 12px; }

.form__icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 0 12px;
  background-position: center;
  background-repeat: no-repeat; }
  .form__icon.--uk {
    background-image: url("../../img/uk.png"); }
  .form__icon.--ireland {
    background-image: url("../../img/ireland.png"); }
  .form__icon.--eu {
    background-image: url("../../img/eu.png"); }
  .form__icon.--usa {
    background-image: url("../../img/usa.png"); }

.form__submit {
  margin: 32px 0; }

input[name=q] {
  max-width: 640px; }

.fun-facts {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 35% 65%; }
  .fun-facts__heading {
    font-size: 36px;
    color: #000;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    margin-bottom: 20px;
    margin-top: 0; }
  .fun-facts__intro {
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .fun-facts__list {
    grid-column: 2; }
    .fun-facts__list ul {
      list-style-type: none; }
      .fun-facts__list ul li {
        display: grid;
        grid-template-columns: 75px auto;
        grid-gap: 20px;
        margin-bottom: 20px; }
        .fun-facts__list ul li i {
          grid-column: 1;
          height: 75px;
          width: 75px;
          border-radius: 10px;
          background: #21CBFF;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: #FFF; }
        .fun-facts__list ul li span {
          grid-column: 2;
          display: flex;
          align-items: center; }
  @media (max-width: 46.24em) {
    .fun-facts {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto; }
      .fun-facts__intro {
        grid-column: 1;
        grid-row: 1; }
      .fun-facts__list {
        grid-column: 1;
        grid-row: 2; } }

.header-buttons {
  position: relative;
  top: -1rem; }
  .header-buttons ul {
    list-style-type: none; }
  .header-buttons > ul {
    display: flex;
    flex-direction: row; }
    .header-buttons > ul > li {
      position: relative;
      background: #FFF;
      margin-left: 15px; }
    .header-buttons > ul > li > a {
      display: block;
      width: 51px;
      height: 51px;
      border-radius: 50%;
      transition: all 0.25s ease-in-out;
      border: 2px solid #4FADF2;
      box-sizing: border-box;
      backdrop-filter: blur(15px);
      margin-left: 10px;
      text-indent: -999em;
      background: white;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center; }
      .header-buttons > ul > li > a.--share-icon {
        background-image: url(../../img/svg/share.svg); }
      .header-buttons > ul > li > a.--star-icon {
        background-image: url(../../img/svg/star.svg); }
    .header-buttons > ul li:hover a.--share-icon {
      background-image: url(../../img/svg/share-white.svg); }
    .header-buttons > ul li:hover a.--star-icon {
      background-image: url(../../img/svg/star-white.svg); }
  .header-buttons .popup {
    display: none;
    position: absolute;
    top: 100%;
    right: 0px;
    padding: 24px;
    background: #FFFFFF;
    backdrop-filter: blur(15px);
    border-radius: 12px;
    border: 1px solid rgba(79, 173, 242, 0.26);
    z-index: 5;
    min-width: 250px;
    margin-top: 12px; }
    .header-buttons .popup:before {
      position: absolute;
      content: "";
      z-index: 2;
      display: block;
      top: -12px;
      right: 12px;
      position: absolute;
      width: 23px;
      height: 12px;
      /*border: 1px solid rgba(79, 173, 242, 0.26);
			background: #FFFFFF;
			transform: rotate(-45deg);*/
      background: url(../../img/bubble-top.png); }
    .header-buttons .popup:after {
      display: block;
      position: absolute;
      width: 100%;
      top: -30px;
      height: 30px;
      background: transparent;
      content: ""; }
    .header-buttons .popup .icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 8px;
      position: relative;
      top: 4px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center; }
      .header-buttons .popup .icon.--fb {
        background-image: url(../../img/universities/facebook.png); }
      .header-buttons .popup .icon.--tw {
        background-image: url(../../img/universities/twitter.png); }
    .header-buttons .popup li:not(:last-child) {
      margin-bottom: 10px; }
    .header-buttons .popup li a {
      font-size: 15px;
      color: #012D6C;
      opacity: 0.4; }
  .header-buttons > ul > li:hover > a {
    background-color: #4FADF2; }
  .header-buttons li:hover .popup {
    display: block; }

.grid-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 48px;
  grid-column-gap: 72px;
  margin-top: 64px; }
  @media (max-width: 46.24em) {
    .grid-list {
      grid-template-columns: 1fr;
      grid-row-gap: 32px; } }

@media (max-width: 46.24em) {
  .grid-list__item {
    display: none; }
    .grid-list__item:nth-child(1), .grid-list__item:nth-child(2), .grid-list__item:nth-child(3) {
      display: block; } }

.grid-list__item h3 {
  color: #000;
  font-size: 20px;
  line-height: 26px; }

.grid-list__item p {
  color: #2C5D90;
  font-size: 18px;
  line-height: 27px; }

html {
  font-size: 16px;
  /* for rem sizing */ }

body {
  overflow-x: hidden; }
  body.home, body.universities, body.faqs {
    background: url(/img/home/circles.png) no-repeat, url(/img/home/bg-rainbow.jpg) no-repeat;
    background-position: top center, top center;
    background-size: 1200px 1200px, contain; }
  body.faqs {
    background-size: 1200px 1200px, cover; }
  @media (min-width: 46.25em) {
    body.blogIndex, body.eventsIndex {
      background: url(/img/blog/arrow.png) no-repeat, url(/img/home/circles.png) no-repeat, url(/img/home/bg-rainbow.jpg) no-repeat;
      background-position: center 950px, top center, top center;
      background-size: 356px 1073px, 1200px 1200px, contain; } }
  body.universityDetail {
    background: url(/img/bg-colour-blur.jpg), linear-gradient(180deg, white 0%, #F9FBFC 100%);
    background-repeat: no-repeat;
    background-position: 0 100%; }

.container, .instagram-stack {
  width: 100%;
  max-width: 1182px;
  padding: 0 1em;
  margin-left: auto;
  margin-right: auto; }

#popup-bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.25s ease-in-out; }
  #popup-bg.hidden {
    opacity: 0;
    pointer-events: none; }

/**
 * Sitewide grid layout
 */
.grid-container {
  display: grid;
  grid-template-columns: calc( 50vw - 1152px / 2) repeat(8, 1fr) calc( 50vw - 1152px / 2); }
  @media (max-width: 46.24em) {
    .grid-container {
      grid-template-columns: 20px calc(100% - 40px) 20px; } }
  @media (min-width: 46.25em) and (max-width: 81.24em) {
    .grid-container {
      grid-template-columns: 32px calc(100% - 64px) 32px; } }

body.page .grid-container.--header {
  background: #E7F6FA; }

.grid-row {
  grid-column: 2 / -2;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px; }
  @media (max-width: 46.24em) {
    .grid-row {
      grid-template-columns: 1fr; } }
  .grid-row.--full {
    grid-column: 1 / -1; }
  .grid-row.--narrow {
    max-width: 792px;
    margin-left: auto;
    margin-right: auto; }
  .grid-row.--page-meta {
    grid-template-columns: 1fr auto; }
  .grid-row.--sidebar-and-content {
    grid-template-columns: 320px 1fr;
    grid-column-gap: 64px; }
    @media (max-width: 46.24em) {
      .grid-row.--sidebar-and-content {
        grid-template-columns: 1fr; } }
  .grid-row.--big-gap {
    grid-column-gap: 120px; }
  .grid-row.--single {
    grid-template-columns: 1fr; }
  .grid-row.--filled {
    background: #E7F6FA; }
    .grid-row.--filled:before, .grid-row.--filled:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(50vw - 50%);
      height: 100%;
      background: #E7F6FA; }
    .grid-row.--filled:before {
      left: calc(0px - (50vw - 50%)); }
    .grid-row.--filled:after {
      right: calc(0px - (50vw - 50%)); }
  .grid-row .button {
    align-self: flex-start; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .grid-row .button {
      align-self: center; } }

.grid-row__cell {
  display: flex;
  flex-direction: column; }
  .grid-row__cell.--center-vertical {
    align-items: center; }
  @media (max-width: 46.24em) {
    .grid-row__cell .grid-row__cell {
      align-items: center; } }
  .grid-row__cell header {
    width: 100%; }

/* visibility helpers */
@media (min-width: 46.25em) {
  body .mobile-only {
    display: none; } }

@media (max-width: 46.24em) {
  body .mobile-hidden {
    display: none; } }

body .mobile-toggle {
  display: none; }

body [data-mobile-toggle-target] {
  cursor: pointer; }
  @media (min-width: 46.25em) {
    body [data-mobile-toggle-target] {
      display: none; } }

@media (max-width: 46.24em) {
  body .mobile-float {
    position: absolute;
    background: white;
    z-index: 100;
    width: 100%; }
    body .mobile-float:before, body .mobile-float:after {
      content: "";
      height: 100%;
      width: 32px;
      position: absolute;
      background: white;
      top: 0px; }
    body .mobile-float:before {
      left: -32px; }
    body .mobile-float:after {
      right: -32px; } }

.narrow__content {
  grid-template-columns: auto minmax(min-content, 768px) auto;
  grid-column-gap: 0px; }
  .narrow__content .grid-row__cell {
    grid-column: 2; }

/* Shared Elements */
.bg-arrow {
  width: 141px;
  position: absolute;
  top: -320px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1; }
  .bg-arrow.--top {
    clip-path: inset(0 0 50% 0); }
  .bg-arrow.--bottom {
    clip-path: inset(50% 0 0 0);
    top: auto;
    bottom: -130px; }
    @media (max-width: 46.24em) {
      .bg-arrow.--bottom {
        bottom: 10px; } }

.relative {
  position: relative; }

.levels {
  /*display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
	}

	@include mq(tablet, desktop) {
		grid-template-columns: 1fr 1fr;
	}

	grid-gap: 12px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .levels {
      flex-direction: column; } }

.levels__arrow {
  width: 43.5px;
  height: 215px;
  background: url("../../img/universities/academics-arrow.png") no-repeat;
  background-size: contain;
  margin: 0 auto; }

.level__panel {
  padding: 20px 27px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px; }

.level__icon {
  width: 99px;
  height: 99px;
  border-radius: 99px;
  background: #D4EBFB;
  border: 7px solid #EDF8FF;
  margin: 23px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .level__icon img {
    width: auto;
    height: auto; }

ol.list {
  width: 100%;
  counter-reset: list-counter; }
  ol.list li {
    counter-increment: list-counter;
    line-height: 1.5;
    align-items: center;
    color: #012D6C; }
  ol.list.--number-solid, ol.list.--number-outline {
    margin: 25px 0;
    padding: 0;
    list-style: none; }
    ol.list.--number-solid li, ol.list.--number-outline li {
      position: relative;
      padding-left: 62px;
      height: 48px;
      margin-bottom: 24px; }
      ol.list.--number-solid li:before, ol.list.--number-outline li:before {
        position: absolute;
        left: 0px;
        display: inline-block;
        content: counter(list-counter);
        border: 2px solid white;
        border-radius: 28px;
        width: 28px;
        height: 28px;
        left: 8px;
        top: 8px;
        z-index: 5;
        line-height: 1;
        display: flex;
        color: white;
        justify-content: center;
        align-items: center; }
      ol.list.--number-solid li:nth-child(3n):after, ol.list.--number-outline li:nth-child(3n):after {
        transform: rotate(5deg); }
      ol.list.--number-solid li:nth-child(3n + 1):after, ol.list.--number-outline li:nth-child(3n + 1):after {
        transform: rotate(15deg); }
      ol.list.--number-solid li:nth-child(3n + 2):after, ol.list.--number-outline li:nth-child(3n + 2):after {
        transform: rotate(-5deg); }
  ol.list.--compressed li {
    height: auto;
    line-height: 32px;
    margin-bottom: 10px; }
  ol.list.--number-solid li:after {
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background: #4FADF2; }
  ol.list.--number-outline li {
    padding-left: 52px; }
    ol.list.--number-outline li a {
      opacity: 0.5; }
  ol.list.--number-outline li:before {
    color: #2C5D90;
    border-color: #2C5D90;
    width: 28px;
    height: 28px;
    top: 0px;
    left: 0px; }

.listing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 32px; }
  .listing.--three {
    grid-template-columns: 1fr 1fr 1fr; }
    @media (max-width: 46.24em) {
      .listing.--three {
        grid-template-columns: 1fr; } }
  @media (max-width: 46.24em) {
    .listing {
      grid-template-columns: 1fr; } }

.modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(1, 45, 108, 0.8);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0px;
  z-index: 199999; }

.modal {
  max-width: 900px;
  margin: 0 15%;
  background: white;
  border-radius: 20px;
  display: grid;
  grid-template-areas: "image content";
  grid-template-columns: 1fr 1fr;
  position: relative; }

@media (max-width: 46.24em) {
  .modal {
    grid-template-areas: "image" "content";
    grid-template-columns: 1fr;
    grid-template-rows: 240px auto;
    margin: 0 5%; }
  .modal__image img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px !important; }
  .modal__close {
    background: #FFF; } }

.modal__content {
  padding: 20px;
  grid-area: content; }

.modal__title {
  font-size: 24px;
  color: #000;
  margin: 0; }

.modal__subtitle {
  font-size: 18px;
  color: #2C5D90;
  margin: 10px 0; }

.modal__text {
  font-size: 14px;
  color: #000; }

.modal__close {
  width: 35px;
  height: 35px;
  background: url("../../img/x.svg") no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer; }

.modal__image {
  grid-area: image; }
  .modal__image img {
    height: 100%;
    object-fit: cover;
    display: block;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; }

.pagination {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.pagination__button {
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
  display: block;
  height: 32px;
  width: 32px;
  margin: 0 2px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: black; }
  .pagination__button.current, .pagination__button:hover {
    background-color: #4FADF2;
    color: white; }
  .pagination__button.--prev, .pagination__button.--next {
    text-indent: -999em; }
  .pagination__button.--prev {
    background-image: url("../../img/page-prev.png"); }
  .pagination__button.--next {
    background-image: url("../../img/page-next.png"); }

@media (min-width: 61.25em) {
  body.how-it-works .grid-container.--header,
  body.how-it-works-2 .grid-container.--header {
    background-size: 57% auto;
    background-position: bottom right;
    background-image: url("../../img/how-it-works/header.png");
    background-repeat: no-repeat; }
  body.become-an-agent .grid-container.--header,
  body.become-an-agent-2 .grid-container.--header {
    background-size: 640px auto;
    background-position: bottom right;
    background-image: url("../../img/become-an-agent/header.png");
    background-repeat: no-repeat; }
  body.our-story .grid-container.--header {
    background-size: 640px auto;
    background-position: bottom right;
    background-image: url("../../img/our-story/header.png");
    background-repeat: no-repeat; } }

@media (min-width: 61.25em) and (min-width: 81.25em) {
  html body.how-it-works .grid-container.--header,
  html body.how-it-works-2 .grid-container.--header {
    background-position: right bottom !important; }
  html body .grid-container.--header {
    background-position: 65% bottom !important; } }

@media (min-width: 125em) {
  body .grid-container.--header {
    background-size: auto 85% !important; } }

.page-header {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .page-header {
      grid-template-columns: 1fr; } }

body.page .grid-container.--header {
  position: relative; }
  body.page .grid-container.--header:after {
    position: absolute;
    bottom: -3px;
    height: 50px;
    width: 100vw;
    content: "";
    display: block;
    background: url("../../img/headerwave.png") no-repeat;
    background-size: cover;
    background-position: bottom center; }

.page-header__content {
  grid-column: 1;
  padding-bottom: 200px; }
  @media (max-width: 46.24em) {
    .page-header__content {
      grid-column: 1/-1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 100px; }
      .page-header__content .title {
        align-self: flex-start; } }
  .page-header__content.--single {
    grid-column: 1 / -1; }

.page-header__image {
  grid-column: 2; }

.page-header__content .pretitle {
  font-size: 20px;
  line-height: 26px;
  width: 100%;
  margin: 0;
  color: #002868;
  padding-top: 100px;
  padding-bottom: 20px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  @media (max-width: 46.24em) {
    .page-header__content .pretitle {
      padding-top: 64px;
      padding-bottom: 0; } }

.page-header__content p {
  color: #002868 !important; }

.page-header .title {
  color: #002868;
  font-size: 63px;
  margin: 0;
  line-height: 67.2px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  @media (max-width: 81.24em) {
    .page-header .title {
      font-size: 55px;
      line-height: 110%; } }
  @media (max-width: 46.24em) {
    .page-header .title {
      font-size: 38px; } }

.page-header__text {
  padding: 10px 0; }

.photo-cloud {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  transform: scale(1.25); }
  @media (max-width: 46.24em) {
    .photo-cloud {
      right: 60px; }
      .universityListing .photo-cloud {
        display: none; } }
  @media (max-width: 81.24em) {
    .photo-cloud {
      right: 115px; } }

.photo-cloud__photo {
  overflow: hidden;
  border-radius: 50px;
  display: none;
  width: 100px;
  height: 100px;
  position: absolute;
  background: #21CBFF; }

.profile-dropdown {
  height: 46px;
  display: grid;
  grid-template-columns: auto 32px 32px;
  grid-template-areas: "name dropdown profile" "menu menu menu";
  border-radius: 10px;
  color: #012d6c;
  padding: 6px 8px;
  cursor: pointer;
  position: relative;
  z-index: 999999; }
  .profile-dropdown > * {
    display: flex;
    align-items: center; }
  .profile-dropdown:hover {
    background: rgba(0, 0, 0, 0.04); }
    .profile-dropdown:hover .profile-dropdown__menu {
      opacity: 1; }

[data-profile-dropdown] {
  min-width: 150px;
  flex: 1 1 150px;
  opacity: 0;
  position: absolute;
  left: -999em; }
  [data-profile-dropdown].active {
    opacity: 1;
    left: 0px;
    position: relative; }
    @media (min-width: 46.25em) {
      [data-profile-dropdown].active {
        flex: 1 1 200px !important; } }

.profile-dropdown__name {
  grid-area: name;
  padding-right: 10px;
  justify-content: flex-end; }

.profile-dropdown__dropdown {
  grid-area: dropdown;
  background-image: url("../../img/select-down.png");
  background-position: center;
  background-size: 12px 8px;
  background-repeat: no-repeat; }

.profile-dropdown__profile {
  grid-area: profile; }
  .profile-dropdown__profile img {
    border-radius: 100%;
    width: 32px;
    height: 32px; }

.profile-dropdown__menu {
  grid-area: menu;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  position: absolute;
  left: 0px;
  display: flex !important;
  flex-direction: column !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: #FFF;
  border: 1px solid #ddd;
  align-items: flex-start !important; }
  .profile-dropdown__menu li {
    flex: 1 1 100%;
    width: 100%;
    /*span {
			margin: 8px 0 !important;
		}*/
    display: flex;
    flex-direction: row; }
    .profile-dropdown__menu li svg, .profile-dropdown__menu li a {
      color: #012d6c !important;
      margin: 8px 6px; }
    .profile-dropdown__menu li a {
      display: flex;
      width: 100%; }
    .profile-dropdown__menu li:hover {
      background: #F5F5F5; }
    .profile-dropdown__menu li span > a {
      margin: 0px !important; }
    .profile-dropdown__menu li svg {
      width: 24px;
      height: 24px;
      position: relative;
      top: 1px;
      margin: 0 16px 0 0 !important;
      color: #012d6c !important; }
    @media (max-width: 46.24em) {
      .profile-dropdown__menu li a {
        padding: 0 !important;
        justify-content: flex-start !important;
        height: auto !important; }
      .profile-dropdown__menu li [data-login-button] {
        padding: 0 !important; } }

.reviews {
  display: flex;
  flex-direction: row; }
  @media (max-width: 46.24em) {
    .reviews {
      flex-direction: column; } }

.reviews__review {
  flex: 0 1 calc(100% / 3);
  display: grid;
  grid-template-columns: 48px 1fr 1fr 1fr;
  grid-template-areas: "review review review review" "stars  stars  .      ." "face   name   name   name";
  grid-template-rows: auto 16px 48px;
  grid-row-gap: 16px;
  grid-column-gap: 16px; }
  .reviews__review:not(:last-child) {
    margin-right: 48px; }
    @media (max-width: 46.24em) {
      .reviews__review:not(:last-child) {
        display: none;
        text-align: center; } }
  @media (max-width: 46.24em) {
    .reviews__review {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .reviews__review.--light-bg, .reviews__review.--light-bg p, .reviews__review.--light-bg .review__content {
    color: black; }
  .reviews__review.--light-bg .review__bio strong {
    color: black; }

.review__content {
  grid-area: review;
  font-style: italic; }
  .review__content, .review__content p {
    text-align: left !important;
    color: white; }
  @media (max-width: 46.24em) {
    .review__content {
      text-align: center; } }

.review__stars {
  grid-area: stars;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0; }
  .review__stars li {
    width: 16px;
    height: 15px;
    background: url("../../img/reviews/star.png"); }
    .review__stars li:not(:last-child) {
      margin-right: 4px; }

.--invert .review__stars li {
  background: url("../../img/reviews/star-white.png"); }

.review__photo {
  grid-area: face; }
  .review__photo img {
    border-radius: 50%;
    border: 1px solid white;
    width: 48px; }

.review__bio {
  grid-area: name;
  color: #666; }
  .review__bio strong {
    color: white; }

.site-header {
  margin: 70px 0;
  grid-column: 2 / -2;
  display: grid;
  grid-template-columns: 156px 1fr 50px;
  grid-template-rows: 1fr;
  grid-column-gap: 40px; }
  @media (max-width: 46.24em) {
    .site-header {
      margin: 50px 0; } }

.site-header__logo {
  grid-template-column: 1;
  display: flex;
  align-items: center; }

.site-header__nav {
  grid-column: 2;
  display: flex; }
  .site-header__nav > ul > li > div {
    position: relative; }
  .site-header__nav > ul > li a:not([href]) {
    cursor: pointer; }
    .site-header__nav > ul > li a:not([href]):after {
      position: relative;
      display: inline-block;
      right: -8px;
      width: 8px;
      height: 4px;
      background: url(../../img/chevron-down.png) no-repeat;
      background-size: contain;
      top: -4px;
      content: ""; }
  .site-header__nav > ul > li:hover a:not([href]):after {
    background-image: url(../../img/chevron-up.png); }
  .site-header__nav > ul > li .down-arrow {
    width: 12px;
    height: 12px;
    background: #555;
    display: inline-block; }
  @media (min-width: 46.25em) {
    .site-header__nav > ul > li a:not([href]).hover:after {
      background-image: url(../../img/chevron-up.png); }
    .site-header__nav > ul > li .site-header__sub-nav {
      z-index: 100000;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      top: 0px;
      left: -1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: #FFF;
      width: auto;
      border: 1px solid #777;
      border-radius: 8px; } }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .site-header__nav > ul > li .site-header__sub-nav {
      display: none; } }
  @media (min-width: 46.25em) {
      .site-header__nav > ul > li .site-header__sub-nav li {
        overflow: hidden;
        white-space: nowrap;
        width: 100%; }
        .site-header__nav > ul > li .site-header__sub-nav li:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }
        .site-header__nav > ul > li .site-header__sub-nav li:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px; }
        .site-header__nav > ul > li .site-header__sub-nav li a {
          position: relative;
          display: block;
          padding: 0.5rem 1rem;
          transition: background 0.25s ease-in-out;
          width: 100%; }
          .site-header__nav > ul > li .site-header__sub-nav li a:hover {
            background: #E7F6FA;
            color: #000; } }
  .site-header__nav ul {
    position: relative;
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
    .site-header__nav ul li {
      height: 100%;
      justify-content: center;
      flex: 1 1 auto;
      font-weight: 600;
      flex-direction: column;
      display: flex; }
      .site-header__nav ul li .subnav {
        top: 12px; }
      .site-header__nav ul li:hover .site-header__sub-nav {
        opacity: 1;
        pointer-events: auto; }
  @media (max-width: 46.24em) {
    .site-header__nav {
      grid-column: 1 / -1;
      grid-row: 2; }
      .site-header__nav .subnav {
        top: 0px !important; }
        .site-header__nav .subnav a {
          background: #E7F6FA; }
      .site-header__nav a:not([href]):not(.hover) ~ .subnav {
        display: none; }
      .site-header__nav ul {
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        background: white; }
        .site-header__nav ul li a {
          padding: 1rem 0;
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .site-header__nav ul li a:not([href]):after {
            width: 10px;
            height: 5px;
            top: -1px !important; } }

.site-header__nav-toggle {
  display: none; }

@media (max-width: 46.24em) {
  .site-header__nav-toggle:checked ~ .site-header__nav {
    display: block; }
  .site-header__nav-toggle:not(:checked) ~ .site-header__nav {
    display: none; } }

.site-header__nav-burger {
  display: none;
  grid-column: 3;
  cursor: pointer; }
  .site-header__nav-burger label {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: url("/img/menu.png") no-repeat;
    background-size: 24px 24px;
    background-position: center;
    text-indent: -999em; }

@media (max-width: 46.24em) {
  .site-header__nav-burger {
    display: flex; } }

.site-header__search {
  grid-template-column: 3;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 46.24em) {
    .site-header__search {
      display: none; } }
  .site-header__search a, .site-header__search button {
    width: 24px;
    height: 24px;
    background: url("/img/search.png") no-repeat;
    background-size: 24px 24px;
    background-position: center;
    text-indent: -999em; }

.site-footer {
  display: grid;
  grid-template-columns: 3fr 8fr 2fr;
  padding: 110px 0 100px 0;
  grid-template-areas: "logo nav social" "copy nav social"; }
  @media (max-width: 46.24em) {
    .site-footer {
      grid-template-columns: 1fr;
      grid-template-areas: "logo" "nav" "social" "copy";
      grid-row-gap: 36px; } }
  .site-footer .border {
    width: 100%;
    height: 2px;
    background: rgba(18, 20, 26, 0.1);
    left: 0px;
    content: " ";
    display: block;
    position: relative;
    grid-column: 1 / -1;
    grid-row: 1;
    top: -60px; }
  .site-footer ul {
    list-style-type: none;
    margin: 0; }
  .site-footer h3 {
    font-size: 15px;
    font-weight: 600;
    margin: 0; }
  .site-footer li a {
    font-size: 13px;
    color: #65666A; }

.site-footer__logo {
  grid-area: logo;
  font-size: 12px;
  color: #12141A;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .site-footer__logo img {
    max-width: 155px; }

.site-footer__nav {
  grid-area: nav; }
  .site-footer__nav > ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; }
    .site-footer__nav > ul > li {
      font-weight: 600; }
    .site-footer__nav > ul > li > ul > li {
      font-weight: 400; }
    @media (max-width: 46.24em) {
      .site-footer__nav > ul {
        grid-template-columns: 1fr; }
        .site-footer__nav > ul > li > ul {
          display: none; }
        .site-footer__nav > ul > li {
          margin-bottom: 16px;
          grid-column: 1 !important; } }
    .site-footer__nav > ul > li:nth-child(1) {
      grid-column: 1; }
    .site-footer__nav > ul > li:nth-child(2) {
      grid-column: 2; }
    .site-footer__nav > ul > li:nth-child(3) {
      grid-column: 3; }
    .site-footer__nav > ul > li > a {
      font-size: 15px;
      color: black; }

/*.site-footer__nav-2 {
	grid-column: 3;
}

.site-footer__nav-3 {
	grid-column: 4;
}*/
.site-footer__social {
  grid-area: social;
  display: flex;
  align-items: flex-end; }
  .site-footer__social ul {
    display: flex;
    flex-direction: row; }
    .site-footer__social ul li {
      width: 32px;
      height: 32px;
      background: #ACACAC;
      margin-right: 16px;
      border-radius: 5px; }
      .site-footer__social ul li a {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 20px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        /*&:after {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0px;
					top: 0px;
					display: block;
					content: " ";
					background-repeat: no-repeat;
					background-position: center;

				}*/ }
      .site-footer__social ul li.--li a {
        background-image: url("/img/li.png"); }
      .site-footer__social ul li.--yt a {
        background-image: url("/img/yt.png"); }
      .site-footer__social ul li.--fb a {
        background-image: url("/img/fb.png"); }
      .site-footer__social ul li.--tw a {
        background-image: url("/img/tw.png"); }

.site-footer__copyright {
  grid-area: copy;
  font-size: 12px;
  color: #12141A;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  @media (max-width: 46.24em) {
    .stats {
      flex-direction: column; } }

.stats__stat {
  list-style-type: none;
  text-align: center; }
  .stats__stat .stat__value {
    font-size: 65px;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 600;
    line-height: 75px;
    margin-bottom: 16px;
    color: #000; }
  .stats__stat .stat__text {
    font-size: 22px;
    line-height: 26px;
    color: #222; }

.story-records {
  display: flex;
  flex-direction: column;
  padding: 0 150px; }

.--our-story-intro {
  margin: 70px 0 0 0;
  text-align: center; }

.story-record {
  align-items: center;
  min-height: 200px;
  display: grid;
  grid-template-columns: 1fr 96px 1fr;
  grid-template-areas: "desc year image";
  grid-template-rows: 1fr;
  grid-column-gap: 35px;
  padding: 16px 0; }

.story-record__description {
  grid-area: desc;
  font-weight: bold; }

.story-record__year {
  text-align: center;
  grid-area: year;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 0 1 96px;
  position: relative;
  align-items: center;
  background: url("../../img/our-story/year.png") no-repeat;
  background-size: 100%;
  background-position: center;
  color: white;
  font-weight: bold;
  line-height: 100px; }
  .story-record__year:after, .story-record__year:before {
    content: "";
    display: block;
    flex: 1 1 auto;
    height: 100%;
    width: 1px;
    background: url("../../img/our-story/line.svg"); }

.story-record__image {
  grid-area: image;
  max-height: 250px;
  display: flex; }
  .story-record__image img {
    height: auto;
    object-fit: contain; }

.story-record:first-child .story-record__year:before {
  background: none; }

.story-record:last-child .story-record__year:after {
  background: none; }

.story-record:nth-child(2n+1) {
  grid-template-areas: "image year desc"; }

@media (max-width: 46.24em) {
  body.ourStory .story-records {
    padding: 0; }
  body.ourStory .story-record {
    height: 530px;
    /* There's a strange bug on Safari/iOS that breaks the grid layout if this isn't here */
    grid-template-areas: "year image" "year desc" !important;
    grid-template-columns: 64px 1fr;
    grid-template-rows: 1fr auto; }
  body.ourStory .story-record__image {
    height: auto;
    object-fit: cover;
    width: 100%; }
  body.ourStory .story-record__year {
    width: 64px;
    flex: 0 1 64px;
    background-size: 100%; } }

body.ourStory .home__blog-and-events {
  margin-top: 100px;
  background: #FFF !important; }
  body.ourStory .home__blog-and-events:before, body.ourStory .home__blog-and-events:after {
    background: #FFF; }
  body.ourStory .home__blog-and-events h3 {
    text-align: center; }

.grid-row.our-story-history {
  margin-top: calc(100vw * (145/1600));
  margin-bottom: calc(100vw * (139/1600));
  background: #E7F6FA; }
  .grid-row.our-story-history:before, .grid-row.our-story-history:after {
    content: "";
    display: block;
    position: relative;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: contain; }
  .grid-row.our-story-history:before {
    height: calc(100vw * (145/1600));
    top: calc(0 - 100vw * (145/1600));
    background-image: url("../../img/our-story/blue-top.svg"); }
  .grid-row.our-story-history:after {
    height: calc(100vw * (139/1600));
    background-image: url("../../img/our-story/blue-bottom.svg");
    bottom: calc(0 - 100vw * (139/1600)); }
  .grid-row.our-story-history h3 {
    margin: -80px 0 0 0px;
    font-size: 36px;
    text-align: center; }
    @media (max-width: 46.24em) {
      .grid-row.our-story-history h3 {
        margin: 20px 0 40px 0;
        font-size: 25px; } }

.the-team {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 60px 0; }

.the-team__person {
  align-items: center; }

.the-team__person {
  text-align: center;
  max-width: 200px;
  margin: 0 40px;
  flex: 0 0 auto;
  display: flex;
  position: relative;
  cursor: pointer;
  flex-direction: column; }
  .the-team__person:first-child {
    margin-left: 0; }
  .the-team__person:last-child {
    margin-right: 0; }
  .the-team__person img {
    border-radius: 144px;
    max-width: 144px;
    max-height: 144px; }
  .the-team__person dt {
    display: none; }
  .the-team__person dd, .the-team__person dt {
    flex: 0 0 auto;
    margin-bottom: 5px; }

.person__name {
  font-size: 14px; }

.person__position {
  font-size: 12px;
  color: rgba(44, 93, 144, 0.7); }

.person__photo {
  width: 144px;
  position: relative; }
  .person__photo:after {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    top: 110px;
    width: 30px;
    height: 30px;
    background: url(../../img/more.svg) no-repeat;
    background-size: contain;
    z-index: 1999; }

@media (max-width: 46.24em) {
  .the-team {
    flex-direction: column;
    align-items: center; }
  .the-team__person {
    flex: 0 1 50%;
    margin: 0 0 25px 0; } }

body, p, li, input, select, dd, dt {
  color: #2C5D90;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

h1, h2, h3, h4, h5, h6 {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: #12141A; }
  h1.--blue, h2.--blue, h3.--blue, h4.--blue, h5.--blue, h6.--blue {
    color: #002868; }

a {
  color: #012D6C; }

p, li {
  color: #000;
  font-size: 16px; }
  @media (max-width: 46.24em) {
    p, li {
      font-size: 15px;
      line-height: 22px; } }

dl {
  display: flex;
  flex-wrap: wrap; }
  dl dt, dl dd {
    flex: 0 0 50%;
    padding: 0;
    margin: 0; }
  dl dt {
    font-size: 20px;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  dl dd {
    font-size: 20px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    margin-bottom: 20px; }

.bold {
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

.textAlign--center {
  text-align: center; }

.heading-set .pretitle {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 115%;
  color: #21CBFF;
  margin-bottom: 0.3em; }

.heading-set h2, .heading-set h3, .heading-set h4, .heading-set h5, .heading-set h6 {
  font-weight: 800;
  font-size: 3em;
  line-height: 105%;
  margin-top: 0; }

.heading-set h4, .heading-set h5, .heading-set h6 {
  font-size: 2.375em;
  line-height: 115%; }

.grid-row__cell header.--center h2, .grid-row__cell header.--center h3 {
  text-align: center; }

.section-subtitle, header > h4, header > h5 {
  font-weight: 800;
  font-size: 18px;
  color: #21CBFF;
  line-height: 1.3;
  margin: 0; }
  @media (max-width: 46.24em) {
    .section-subtitle, header > h4, header > h5 {
      font-size: 20px;
      line-height: 26px;
      margin: 20px 0 5px 0; } }

.section-title, header > h2.large {
  font-weight: 800;
  font-size: 40px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 68px;
  color: black; }
  @media (max-width: 46.24em) {
    .section-title, header > h2.large {
      font-size: 38px;
      line-height: 43.7px; } }

.section-title.--small {
  font-size: 38px;
  line-height: 44px; }

.section-title.--extra-spacing {
  margin: 2rem 0; }

.section-small-title, header > h2 {
  font-weight: 800;
  font-size: 32px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 1.2;
  color: black;
  margin: 0 0 0.5rem 0; }

.section-h3 {
  font-size: 38px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 1;
  color: black; }

.blog-title, .single-title {
  font-size: 36px;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 1.2;
  color: black; }
  @media (max-width: 46.24em) {
    .blog-title, .single-title {
      font-size: 38px; } }

.single__header header {
  padding: 100px 0 48px 0; }

.blog-meta {
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #65666A; }

header > h3 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.3;
  margin: 0;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 115%;
  color: black; }
  @media (max-width: 46.24em) {
    header > h3 {
      font-size: 24px;
      line-height: 28px;
      margin: 0; } }

header.page-title {
  margin-bottom: 100px;
  margin-top: 50px; }
  header.page-title h1, header.page-title h2, header.page-title h3 {
    color: #000;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 42px;
    font-weight: 800;
    line-height: 1;
    margin: 0; }
    @media (max-width: 81.24em) {
      header.page-title h1, header.page-title h2, header.page-title h3 {
        font-size: 40px; } }
    @media (max-width: 46.24em) {
      header.page-title h1, header.page-title h2, header.page-title h3 {
        font-size: 38px; } }

.blue {
  color: #21CBFF; }

.grey {
  color: #65666A; }

.text.--invert {
  color: white; }

/* popup tooltip */
body .protip-container {
  background: #012d6c !important;
  line-height: 1.3 !important;
  font-size: 13px !important;
  font-weight: bold !important; }

body .protip-skin-default--scheme-pro[data-pt-position="right"] .protip-arrow {
  border-right-color: #012d6c !important; }

body .protip-skin-default--scheme-pro[data-pt-position="top"] .protip-arrow {
  border-top-color: #012d6c !important; }

body .protip-skin-default--scheme-pro[data-pt-position="bottom"] .protip-arrow {
  border-bottom-color: #012d6c !important; }

body .protip-skin-default--scheme-pro[data-pt-position="left"] .protip-arrow {
  border-left-color: #012d6c !important; }

.university-header {
  padding-top: 5.2em; }
  .university-header p {
    font-weight: bold;
    font-size: 1.5em;
    color: #21CBFF;
    margin-bottom: 0; }
    @media (max-width: 46.24em) {
      .university-header p {
        margin-bottom: .5em; } }
  .university-header h1 {
    margin-top: 0;
    font-weight: 800;
    font-size: 4em;
    line-height: 105%;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
    @media (max-width: 81.24em) {
      .university-header h1 {
        font-size: 55px; } }
    @media (max-width: 46.24em) {
      .university-header h1 {
        font-size: 38px;
        line-height: 115%; } }
  .university-header .button {
    margin-bottom: 1rem; }

.at-a-glance {
  background: url(../../img/bg-ataglance.svg) center center no-repeat;
  background-size: 100% 100%; }
  @media (max-width: 46.24em) {
    .at-a-glance {
      background-size: cover; } }

.js-content {
  display: none; }
  .js-content.active {
    display: block; }

.student-experiences header {
  margin-bottom: 32px; }

.student-testimonials .reviews {
  margin: 64px 0; }

.student-testimonials .button {
  align-self: center; }

.student-experiences__reel-content {
  display: none;
  max-width: 90vw; }
  .student-experiences__reel-content ul {
    display: flex;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding: 0;
    margin: 0; }
  .student-experiences__reel-content li {
    display: inline;
    white-space: normal; }
    .student-experiences__reel-content li .tiktok-embed {
      width: auto;
      display: inline-block;
      vertical-align: middle;
      margin: 1em; }
      @media (max-width: 46.24em) {
        .student-experiences__reel-content li .tiktok-embed {
          margin: 1em 0; } }
  .student-experiences__reel-content.active {
    display: block; }

.testimonials {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1em;
  margin: 0 auto;
  width: 100%;
  max-width: 1184px;
  list-style: none; }
  .testimonials li {
    width: 100%;
    max-width: 352px;
    margin-bottom: 2em; }
  .testimonials .testimonial {
    font-style: italic;
    margin-bottom: 1em; }
  .testimonials .testimonial__meta {
    display: flex; }
    .testimonials .testimonial__meta .testimonial__meta-left {
      max-width: 48px;
      margin-right: 1em; }
      .testimonials .testimonial__meta .testimonial__meta-left img {
        border: 1.5px solid;
        border-radius: 100%; }
    .testimonials .testimonial__meta .testimonial__meta-right p {
      font-size: 1em;
      margin: 0;
      font-weight: bold; }
      .testimonials .testimonial__meta .testimonial__meta-right p.testimonial__location {
        opacity: 0.6;
        font-weight: normal; }

#map {
  width: 100% !important;
  margin-bottom: 2.6em; }
  #map:before, #map:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: 401;
    background-position: 50% 0;
    background-size: cover; }
  #map:before {
    background-image: url("/img/map-border-top.svg");
    top: 0;
    height: 77px; }
  #map:after {
    background-image: url("/img/map-border-bottom.svg");
    bottom: 0;
    height: 78px; }

body.universityListing .listing-sidebar {
  display: flex;
  padding-bottom: 64px;
  padding-top: 0; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    body.universityListing .listing-sidebar {
      display: none; }
      body.universityListing .listing-sidebar.visible {
        display: flex; } }

body.universityListing .button {
  align-self: center;
  margin-top: 0px; }

@media (min-width: 46.25em) {
  .--similar-universities {
    margin: 128px 0; } }

.--similar-universities header {
  margin-bottom: 64px; }

.university__social ul {
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  list-style-type: none; }
  .university__social ul li a {
    background: #4fadf2;
    border-radius: 100%;
    color: #fff;
    margin: 10px;
    padding: 10px;
    transition: all .25s ease-in-out;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px; }
    .university__social ul li a:hover {
      background: #2C5D90; }

.university__about {
  display: flex;
  flex-direction: column; }
  .university__about h1 {
    font-size: 28px;
    margin: 1rem 0; }

@inculde mq(from: $mobile) {
  .university__about {
    padding: 43px 87px 20px 87px; } }
  .university__about figure {
    width: auto;
    display: inline-block;
    padding: 0;
    margin: 20px auto; }

.university__arrow {
  display: flex;
  justify-content: center;
  margin: 64px 0; }
  .university__arrow img {
    height: auto;
    width: auto; }

.university-link a {
  font-weight: bold;
  font-size: 24px;
  line-height: 115%;
  color: #4FADF2;
  text-decoration: none;
  display: inline-block;
  margin: 2.6em 0 1em 0; }
  .university-link a:hover {
    text-decoration: none; }

.university__report-links {
  display: flex;
  justify-content: center;
  margin: 0 0 2.6em 0;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .university__report-links a {
    text-decoration: underline;
    display: inline-block;
    margin: 0 12px;
    color: #012D6C;
    opacity: 0.4; }

.university__about {
  position: relative; }
  @media (max-width: 46.24em) {
    .university__about, .university__about figure, .university__about iframe {
      width: calc(100vw - 40px) !important; } }
  .university__about.readmore {
    height: 400px;
    overflow: hidden; }
    .university__about.readmore:after {
      display: block;
      content: "";
      height: 150px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+91 */
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 91%, white 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 91%, white 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 91%, white 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
      /* IE6-9 */
      position: absolute;
      bottom: 0px;
      width: 100%; }

@media (max-width: 46.24em) {
  .student-academics .form__select.--heading {
    flex-direction: column; }
    .student-academics .form__select.--heading select {
      margin: 15px 0;
      text-indent: 30px; } }

.university-website-preview {
  width: 100vw;
  height: calc(100vh - 92px); }

.instagram-stack {
  position: relative;
  max-width: 1344px;
  height: 807px;
  top: -140px;
  margin-bottom: -140px;
  pointer-events: none; }
  @media (max-width: 46.24em) {
    .instagram-stack {
      height: 600px !important; } }
  @media (max-width: 61.24em) {
    .instagram-stack {
      overflow-x: hidden; } }
  .instagram-stack:after {
    content: '';
    background: url(../../img/blog/splash.png) center center no-repeat;
    width: 100%;
    max-width: 1302px;
    height: 807px;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
    @media (max-width: 46.24em) {
      .instagram-stack:after {
        height: 600px !important; } }
  .instagram-stack * {
    pointer-events: all; }

.instagram-stack__photo {
  max-width: 254px;
  position: absolute;
  transition: all 0.26s ease;
  transform: rotate(10deg);
  transform-origin: center; }
  .instagram-stack__photo:first-child {
    z-index: 5; }
  .instagram-stack__photo:hover {
    transform: rotate(0deg) !important;
    z-index: 9; }
    .instagram-stack__photo:hover > .instagram-stack__photo-tap {
      display: inline; }
  .instagram-stack__photo .instagram-source {
    display: none; }
  .instagram-stack__photo.active {
    transform: scale(2) rotate(0deg) !important;
    z-index: 10; }
    .instagram-stack__photo.active > .instagram-stack__photo-tap {
      display: none; }
    .instagram-stack__photo.active:hover > .instagram-stack__photo-tap {
      display: none; }
    .instagram-stack__photo.active .instagram-source {
      display: inline-block;
      text-align: center;
      position: absolute;
      bottom: -2.6em;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.6);
      border-radius: 42px;
      font-size: 7px;
      font-weight: 600;
      letter-spacing: -0.3959px;
      color: #F3F0EB;
      padding: 0.3em 1em; }
  .instagram-stack__photo > .instagram-stack__photo-tap {
    position: absolute;
    display: none;
    bottom: 1.2em;
    left: 0;
    right: 0;
    text-align: center; }
    .instagram-stack__photo > .instagram-stack__photo-tap > .instagram-stack__photo-tap-caption {
      background: rgba(0, 0, 0, 0.6);
      border-radius: 42px;
      font-weight: bold;
      font-size: 1em;
      letter-spacing: -0.3959px;
      color: #F3F0EB;
      padding: 0.3em 1em; }
  .instagram-stack__photo:nth-child(1) {
    left: 0px;
    top: 174.02px;
    transform: scale(0.689) rotate(6.07deg); }
  .instagram-stack__photo:nth-child(2) {
    left: 113.1px;
    top: 193px;
    transform: scale(0.5079) rotate(7.61deg); }
    .instagram-stack__photo:nth-child(2):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(3) {
    left: 341px;
    top: 363.17px;
    transform: scale(0.4567) rotate(-22.48deg); }
    .instagram-stack__photo:nth-child(3):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(4) {
    left: 175px;
    top: 362.52px;
    transform: scale(0.7402) rotate(-5.35deg); }
  .instagram-stack__photo:nth-child(5) {
    left: 328px;
    top: 185.27px;
    transform: rotate(-8.44deg); }
  .instagram-stack__photo:nth-child(6) {
    left: 431.18px;
    top: 424.66px;
    transform: scale(0.378) rotate(-13.53deg); }
    .instagram-stack__photo:nth-child(6):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(7) {
    left: 567px;
    top: 168.7px;
    transform: scale(0.689) rotate(3.34deg); }
  .instagram-stack__photo:nth-child(8) {
    left: 567px;
    top: 351.28px;
    transform: rotate(-2.64deg); }
  .instagram-stack__photo:nth-child(9) {
    left: 764px;
    top: 247.24px;
    transform: scale(0.8819) rotate(-14.57deg); }
    .instagram-stack__photo:nth-child(9):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(10) {
    left: 833.64px;
    top: 424.59px;
    transform: scale(0.2913) rotate(21.6deg); }
    .instagram-stack__photo:nth-child(10):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(11) {
    left: 907px;
    top: 173.44px;
    transform: scale(0.6535) rotate(13.47deg); }
  .instagram-stack__photo:nth-child(12) {
    left: 909.86px;
    top: 358px;
    transform: scale(0.5079) rotate(9.29deg); }
    .instagram-stack__photo:nth-child(12):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(13) {
    left: 769.14px;
    top: 318px;
    transform: scale(0.3228) rotate(19.32deg); }
    .instagram-stack__photo:nth-child(13):hover > .instagram-stack__photo-tap {
      display: none; }
  .instagram-stack__photo:nth-child(14) {
    left: 1093.31px;
    top: 162px;
    transform: scale(0.8819) rotate(11.44deg); }
  .instagram-stack__photo:nth-child(15) {
    left: 1103.14px;
    top: 395px;
    transform: scale(0.3228) rotate(19.32deg); }
    .instagram-stack__photo:nth-child(15):hover > .instagram-stack__photo-tap {
      display: none; }

.docked-bar {
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 0px 54px 80px rgba(0, 0, 0, 0.07), 0px 16.2794px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.035), 0px 2.44555px 3.62304px rgba(0, 0, 0, 0.0243888);
  padding: 1em;
  z-index: 999; }
  .docked-bar > .container, .docked-bar > .instagram-stack {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .docked-bar p, .docked-bar h2 {
    margin: 0; }
  .docked-bar p {
    color: #ACACAC; }
  .docked-bar h2 {
    font-weight: bold;
    font-size: 1.5em; }

@media (min-width: 20em) and (max-width: 46.24em) {
  .grid-row__cell.--center-vertical.at-a-glance {
    align-items: flex-start !important; } }

.at-a-glance {
  padding: 6em;
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: row;
  text-align: left; }
  .at-a-glance dt, .at-a-glance dd, .at-a-glance li, .at-a-glance p, .at-a-glance li > a {
    color: #000; }
  .at-a-glance dt {
    flex: 0 0 55%; }
  .at-a-glance dd {
    flex: 0 0 45%; }
  .at-a-glance dt.empty {
    flex: 0; }
    .at-a-glance dt.empty + dd {
      flex-basis: 100%; }
  .at-a-glance h3 {
    margin-top: 0;
    font-weight: 800;
    font-size: 2.25em;
    letter-spacing: -0.3959px;
    margin-bottom: 0; }
  .at-a-glance table {
    width: 100%;
    max-width: 540px;
    margin: 1em auto;
    text-align: left; }
    .at-a-glance table .heading {
      font-weight: bold;
      font-size: 1.25em; }
    .at-a-glance table .content {
      font-size: 1.3125em; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .at-a-glance {
      flex-direction: column;
      padding: 2em 2em 4em;
      width: calc(100% + 64px);
      margin-left: -32px;
      margin-bottom: 170px; } }
  @media (max-width: 46.24em) {
    .at-a-glance {
      margin-bottom: 30px !important; } }
  .at-a-glance .at-a-glance__menu {
    flex: 1 0 40%; }
    @media (min-width: 20em) and (max-width: 46.24em) {
      .at-a-glance .at-a-glance__menu {
        flex: 1 0 auto;
        margin: 15px 0; } }
    .at-a-glance .at-a-glance__menu ul {
      list-style-type: none;
      padding: 0;
      margin: 0px; }
    .at-a-glance .at-a-glance__menu li {
      opacity: 0.4;
      cursor: pointer;
      transition: opacity 0.25s ease-in-out; }
      .at-a-glance .at-a-glance__menu li a {
        font-size: 20px;
        font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
        .at-a-glance .at-a-glance__menu li a:after {
          position: relative;
          display: block;
          width: 100%;
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
          height: 24px;
          content: "";
          background: url("../../img/underline.png") no-repeat;
          background-size: contain; }
      .at-a-glance .at-a-glance__menu li:hover, .at-a-glance .at-a-glance__menu li.active {
        opacity: 1; }
        .at-a-glance .at-a-glance__menu li:hover a:after, .at-a-glance .at-a-glance__menu li.active a:after {
          opacity: 1; }
        .at-a-glance .at-a-glance__menu li:hover .at-a-glance__subheading, .at-a-glance .at-a-glance__menu li.active .at-a-glance__subheading {
          opacity: 1; }
  .at-a-glance .at-a-glance__content {
    flex: 1 0 60%;
    position: relative;
    display: grid;
    grid-template-areas: "content";
    grid-template-columns: 1fr;
    grid-template-rows: auto; }
  .at-a-glance .at-a-glance__subheading {
    font-size: 18px;
    margin: 10px 0 0 0;
    opacity: 0.2;
    transition: all 0.25s ease-in-out; }
  .at-a-glance .at-a-glance__heading {
    font-size: 36px;
    color: #000;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    margin-bottom: 20px; }
  .at-a-glance .at-a-glance__tab {
    grid-area: content; }
  .at-a-glance .at-a-glance__tab:not(:first-child) {
    display: none; }

.tabs {
  display: inline-block;
  background: #EFEFEF;
  margin-bottom: 2em;
  border-radius: 6px; }
  .tabs > li {
    display: inline; }
    .tabs > li > a {
      display: inline-block;
      padding: 0.3em 0.6em;
      border-radius: 4px;
      font-weight: bold;
      font-size: 1.1875em;
      transition: all 0.16s ease;
      color: #000; }
      @media (max-width: 46.24em) {
        .tabs > li > a {
          display: block; } }
      .tabs > li > a.active, .tabs > li > a:hover {
        background: #21CBFF;
        color: #FFFFFF; }

.logo-reel {
  margin-bottom: 2.6em; }
  .logo-reel li {
    display: inline; }
    .logo-reel li a, .logo-reel li img {
      display: inline-block;
      vertical-align: middle;
      margin: 0 2em;
      width: auto; }

.cards {
  list-style: none; }

.card {
  background: #FFFFFF;
  outline: 3px solid #EFEFEF;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  position: relative; }
  .card .fave-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px; }
  .card.--shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06); }
  .card.--home {
    border: none;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.1); }
  .card a > img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .card .card__image {
    height: 134px;
    box-sizing: content-box;
    position: relative;
    display: block; }
    .card .card__image img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .card .card__meta {
    display: flex;
    height: 74px;
    align-items: center;
    box-sizing: content-box;
    padding: 1em 1em; }
    @media (max-width: 46.24em) {
      .card .card__meta {
        height: auto; } }
    .card .card__meta .card__meta-left {
      max-width: 64px;
      width: 100%;
      height: 100%;
      margin-right: 16px; }
      .card .card__meta .card__meta-left img {
        object-fit: contain;
        width: 100%;
        height: 100%; }
    .card .card__meta .card__meta-right h4, .card .card__meta .card__meta-right h5 {
      font-weight: bold;
      font-size: 16px;
      line-height: 130%;
      color: #12141A;
      margin: 0; }
    .card .card__meta .card__meta-right p {
      margin-top: 0;
      font-size: 0.875em;
      color: #ACACAC; }
      .card .card__meta .card__meta-right p.--clean {
        margin: 0; }
    .card .card__meta .card__meta-right .small-meta {
      color: #ACACAC;
      font-size: 14px;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .card.--events .card__image, .card.--blog .card__image {
    height: 204px;
    padding: 30px 32px 16px; }
    .card.--events .card__image img, .card.--blog .card__image img {
      border-radius: 5px; }
  .card.--events .card__meta, .card.--blog .card__meta {
    padding: 0 32px 32px;
    min-height: 96px; }
    @media (max-width: 46.24em) {
      .card.--events .card__meta, .card.--blog .card__meta {
        min-height: 0px; } }
  .card.--events .card__meta-left, .card.--blog .card__meta-left {
    min-height: 96px;
    margin-right: 32px; }
    @media (max-width: 46.24em) {
      .card.--events .card__meta-left, .card.--blog .card__meta-left {
        min-height: 64px;
        margin-right: 16px; } }
  .card.--events .card__meta-right h5, .card.--blog .card__meta-right h5 {
    font-size: 20px;
    margin: 8px 0; }
    @media (max-width: 46.24em) {
      .card.--events .card__meta-right h5, .card.--blog .card__meta-right h5 {
        font-size: 16px; } }
  .card.--tight .card__image {
    padding: 24px 22px 14px; }
  .card.--tight .card__meta {
    padding: 0 24px 24px; }
  .card.--blog .card__meta {
    align-items: center; }
  .card .event-date {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    align-items: center;
    border-style: solid;
    border-color: lightgray;
    border-radius: 10px;
    border-width: 2px; }
    .card .event-date .event-date__month {
      color: #F00;
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
    .card .event-date .event-date__date {
      color: black;
      font-size: 30px;
      line-height: 1;
      font-weight: 600;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 40px; }
  @media (max-width: 46.24em) {
    .card-grid {
      grid-template-columns: 1fr; } }

.card-shuffle {
  background: url("/img/home/photobg.png") no-repeat;
  background-fit: contain;
  height: 100%; }
  .card-shuffle .card {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 60%;
    margin-bottom: 20px; }
    @media (max-width: 46.24em) {
      .card-shuffle .card {
        width: 90%; } }
    .card-shuffle .card:nth-child(3n + 1) {
      transform: rotate(2deg); }
      @media (max-width: 81.24em) {
        .card-shuffle .card:nth-child(3n + 1) {
          transform: rotate(2deg); } }
    .card-shuffle .card:nth-child(3n + 2) {
      transform: rotate(-4deg) translate(25%, -10%); }
      @media (max-width: 81.24em) {
        .card-shuffle .card:nth-child(3n + 2) {
          transform: rotate(-4deg); } }
    .card-shuffle .card:nth-child(3n + 3) {
      transform: rotate(3deg) translate(-25%, -20%); }
      @media (max-width: 81.24em) {
        .card-shuffle .card:nth-child(3n + 3) {
          transform: rotate(4deg); } }

.card-shuffle, .content-grid__list {
  margin: 24px 0; }

.filter__search .search-container {
  border-style: solid; }

.blog__index-header {
  background: url("../../img/blog/splash.png") no-repeat;
  background-size: contain;
  background-position: center;
  padding-bottom: 0; }
  .blog__index-header .button {
    display: block;
    margin: 40px auto 70px auto; }
    @media (min-width: 20em) and (max-width: 46.24em) {
      .blog__index-header .button {
        margin: 24px auto 24px auto; } }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .blog__index-header {
      background: transparent;
      flex-direction: column;
      align-items: stretch; } }

.blogPost .--blog-header, .event .--blog-header {
  padding-top: 4em; }
  @media (max-width: 46.24em) {
    .blogPost .--blog-header, .event .--blog-header {
      padding-top: 2em; } }

.blog [data-view] {
  display: content; }

.blog__toggle-hidden {
  display: none !important; }

.blog__entry header {
  padding: 100px 0 48px 0; }

.blog__content, .blog__entry {
  grid-template-columns: auto minmax(min-content, 768px) auto;
  grid-column-gap: 0px; }
  .blog__content figure, .blog__entry figure {
    margin: 20px 0; }
  .blog__content .button, .blog__entry .button {
    margin-top: 32px;
    align-self: flex-start; }
  .blog__content figure, .blog__content img, .blog__entry figure, .blog__entry img {
    border-radius: 10px; }
  .blog__content .grid-row__cell, .blog__entry .grid-row__cell {
    grid-column: 2; }

.blog__index-header-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 112px; }
  @media (max-width: 46.24em) {
    .blog__index-header-controls {
      flex-direction: column !important; } }
  .blog__index-header-controls .page-title {
    margin: 0; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .blog__index-header-controls {
      flex-direction: row;
      align-items: flex-start;
      flex: 1 1 100%;
      margin-bottom: 36px; }
      .blog__index-header-controls header {
        margin-top: 50px;
        margin-bottom: 100px; } }
  .blog__index-header-controls .toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 20em) and (max-width: 61.24em) {
      .blog__index-header-controls .toggle {
        display: none; } }
    .blog__index-header-controls .toggle label {
      cursor: pointer;
      position: relative;
      padding: 0 50px;
      border-radius: 24px;
      height: 48px;
      display: flex;
      align-items: center;
      min-width: 160px;
      color: #222;
      background: white;
      font-size: 19px;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      z-index: 10; }
      .blog__index-header-controls .toggle label a {
        color: inherit; }
    .blog__index-header-controls .toggle input {
      position: absolute;
      left: -999em; }
    .blog__index-header-controls .toggle label[for=events] {
      left: -40px;
      z-index: 5;
      padding-left: 60px; }
    .blog__index-header-controls .toggle input:checked + label {
      background: #4FADF2;
      color: white; }

@media (min-width: 61.25em) {
  .blog__card-shuffle {
    min-height: 800px;
    position: relative; }
    .blog__card-shuffle > div {
      position: absolute; }
    .blog__card-shuffle > div:nth-child(1) {
      top: 0px;
      z-index: 10;
      transform: rotate(5deg) translateX(50%); }
    .blog__card-shuffle > div:nth-child(2) {
      top: 350px;
      z-index: 8;
      transform: rotate(-10deg) translateX(25%) scale(0.8); }
    .blog__card-shuffle > div:nth-child(3) {
      top: 380px;
      z-index: 9;
      transform: rotate(10deg) translateX(90%) scale(0.6); } }

.blog__top-five {
  margin-bottom: 100px; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .blog__top-five {
      display: none; } }
  .blog__top-five header {
    display: contents; }
  .blog__top-five .grid-row__cell {
    margin: 0 calc(50% - 100px - 736px / 2);
    padding: 80px 100px 120px;
    background: url("../../img/blog/top5.png") no-repeat;
    background-position: 50% 30%;
    background-size: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 16px;
    grid-column-gap: 16px; }
    .blog__top-five .grid-row__cell header h2 {
      grid-column: 1/-1;
      font-size: 38px;
      margin: 0 0 2rem;
      color: #000;
      text-align: center; }
    .blog__top-five .grid-row__cell > div {
      grid-column: span 2;
      max-width: 310px;
      align-content: flex-start; }
      .blog__top-five .grid-row__cell > div:nth-of-type(1) {
        transform: translate(75px, -25px); }
      .blog__top-five .grid-row__cell > div:nth-of-type(2) {
        transform: translate(75px, 0px); }
      .blog__top-five .grid-row__cell > div:nth-of-type(3) {
        transform: translate(14px, -11px); }
      .blog__top-five .grid-row__cell > div:nth-of-type(4) {
        transform: translate(0, 0); }
      .blog__top-five .grid-row__cell > div:nth-of-type(5) {
        transform: translate(0, 0); }
    .blog__top-five .grid-row__cell > div:nth-child(6) {
      grid-column: 2 / 4; }

@media (min-width: 20em) and (max-width: 46.24em) {
  .blog__featured {
    display: none; } }

.blog-listing {
  display: grid;
  grid-row-gap: 32px;
  grid-template-columns: 1fr; }
  .blog-listing:after {
    background: #fff;
    bottom: 0px;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px; }
  .blog-listing.--two-col {
    grid-template-columns: 1fr 1fr;
    margin-top: 40px; }
    .blog-listing.--two-col > div:nth-child(2n + 1) {
      padding-right: 32px; }
    .blog-listing.--two-col > div:nth-child(2n) {
      padding-left: 32px; }

/* Filter styling */
.filter {
  color: #000;
  display: flex;
  flex-direction: row;
  flex-direction: space-between;
  width: 100%;
  position: relative;
  /*.form__show-filters {
		@include mq(mobile, tablet) {
			position: absolute;
			top: 0px;
			right: 0px;
		}
	}*/ }
  .filter form {
    display: contents; }
  @media (min-width: 20em) and (max-width: 61.24em) {
    .filter {
      flex-direction: column; } }
  .filter .mobile-float:before, .filter .mobile-float:after {
    background: #E7F6FA; }
  .filter > div {
    flex: 0 1 50%; }
  .filter select {
    flex: 0 1 auto;
    background: none;
    border: none;
    font-size: 20px;
    height: 48px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: space-between; }
  .filter .filter__select {
    flex: 0 1 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .filter .filter__select .button {
      color: white;
      margin-top: 48px; }
    .filter .filter__select > div:not(.mobile-only) {
      font-size: 24px;
      font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center; }
    .filter .filter__select [data-mobile-toggle-target] {
      color: #000; }
    @media (min-width: 20em) and (max-width: 46.24em) {
      .filter .filter__select {
        flex: 0 1 100%;
        background: #E7F6FA;
        padding-bottom: 64px;
        flex-direction: column; }
        .filter .filter__select > .title {
          display: none; }
        .filter .filter__select select {
          height: 64px;
          border-bottom: 1px solid #D0D0D0; }
        .filter .filter__select * {
          font-size: 20px !important;
          color: #002868; } }
  .filter .filter__search {
    flex: 0 1 60%;
    display: flex;
    flex-direction: row; }
    @media (max-width: 46.24em) {
      .filter .filter__search {
        flex-direction: column;
        align-items: flex-start; }
        .filter .filter__search .button {
          align-self: flex-start !important; } }
    @media (min-width: 61.25em) {
      .filter .filter__search {
        justify-content: flex-end; } }
    .filter .filter__search .button {
      align-self: center; }

.blog__index-header-controls .search .button {
  margin: 0 !important;
  align-self: center !important;
  min-width: 0px; }

.blog__index-header-controls .search form {
  display: flex;
  align-items: flex-start;
  flex-direction: row; }

.search, .search-container {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .search input, .search-container input {
    border: none;
    background: transparent;
    font-size: 18px;
    height: 48px;
    display: flex;
    align-items: center; }
  .search:before, .search-container:before {
    position: relative;
    left: 0px;
    top: 0px;
    height: 48px;
    width: 32px;
    content: "";
    background: url("../../img/search.png") no-repeat;
    background-position: left center; }

.blog-filter {
  flex: 1 1 70%; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .blog-filter {
      display: none; }
      .blog-filter.visible {
        display: flex; } }

.home__how-it-works .grid-row__cell:nth-child(1) p {
  max-width: 525px; }

.home__how-it-works .content-grid {
  display: grid;
  grid-template-areas: "arrow .    . " "arrow list list  " "arrow .   button";
  grid-template-columns: 120px 30px auto;
  grid-template-rows: 20px auto auto;
  grid-row-gap: 20px; }
  @media (max-width: 46.24em) {
    .home__how-it-works .content-grid {
      display: none; } }

.home__how-it-works .content-grid__arrow {
  grid-area: arrow;
  background: url("/img/home/how-it-works-arrow.png") no-repeat;
  background-size: contain;
  background-position: center calc(100% - 30px);
  /*background-position: left bottom;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: baseline;*/ }
  @media (max-width: 46.24em) {
    .home__how-it-works .content-grid__arrow {
      display: none; } }

.home__how-it-works .content-grid__list {
  margin-bottom: 24px;
  grid-area: list;
  position: relative; }
  @media (max-width: 46.24em) {
    .home__how-it-works .content-grid__list {
      display: none; } }

.home__how-it-works .content-grid__button {
  grid-area: button; }

.home__how-it-works .button {
  position: relative; }
  @media (max-width: 46.24em) {
    .home__how-it-works .button {
      margin-top: 40px; } }

.home__mid-page-cloud {
  height: 250px; }
  @media (max-width: 81.24em) {
    .home__mid-page-cloud {
      height: 125px; }
      .home__mid-page-cloud .grid-row__cell {
        display: none; } }
  @media (max-width: 61.24em) {
    .home__mid-page-cloud {
      height: 50px; } }
  .home__mid-page-cloud .photo-cloud__photo:last-child {
    opacity: 0; }

.home__for-students .grid-row__cell img {
  position: relative;
  top: -80px;
  width: 100%; }

@media (max-width: 46.24em) {
  .home__for-students .grid-row__cell img {
    top: 30px;
    width: 70vw;
    right: -5vw; }
  .home__for-students .grid-row__cell:nth-child(1) {
    order: 2; }
  .home__for-students .grid-row__cell:nth-child(2) {
    order: 1; } }

.home__for-students .content-grid {
  display: grid;
  grid-template-areas: ".    .      . arrow" "list list   . arrow" ".    button . arrow";
  grid-template-columns: auto auto 33px 230px;
  grid-template-rows: fit-content 80px; }
  @media (max-width: 46.24em) {
    .home__for-students .content-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 0px;
      grid-template-areas: "list" "button" "arrow"; }
      .home__for-students .content-grid ol {
        margin: 0;
        padding: 0; } }

.home__for-students .content-grid__arrow {
  grid-area: 1 / 3 / 3 / 5;
  background: url("/img/home/for-students-arrow.png") no-repeat;
  background-size: contain;
  background-position: center calc(100% - 30px);
  /*background-position: left bottom;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: baseline;*/ }

.home__for-students .content-grid__list {
  grid-area: 1 / 1 / 1 / 5;
  position: relative; }
  @media (min-width: 46.25em) {
    .home__for-students .content-grid__list ol {
      padding: 40px 0; } }

.home__for-students .content-grid__button {
  grid-area: button; }

.home__for-universities-and-agents {
  text-align: center;
  padding-bottom: 100px; }
  .home__for-universities-and-agents .section__body {
    max-width: 512px; }

.home__blog-and-events {
  padding-bottom: 150px; }

.home__wave-divider {
  height: 225px;
  margin-top: -225px;
  background: url("/img/home/wave.png");
  background-size: cover;
  background-position: top right; }
  @media (max-width: 46.24em) {
    .home__wave-divider {
      margin-top: -100px;
      height: 150px; } }

.page__header {
  margin: 85px 0 284px 0; }
  .page__header header {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center; }
    .page__header header form {
      width: 100%;
      max-width: 640px; }
    .page__header header input {
      width: 100%;
      height: 64px;
      line-height: 64px;
      text-indent: 16px;
      border: 2px solid #EFEFEF;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802); }
      @media (max-width: 46.24em) {
        .page__header header input {
          text-align: left; } }
  .page__header.--small {
    margin: 40px 0; }
  @media (max-width: 81.24em) {
    .page__header {
      margin: 20px 0 150px 0; } }
  @media (max-width: 61.24em) {
    .page__header {
      margin: 20px 0 50px 0; } }
  .page__header header > img {
    display: inline-block;
    height: 96px;
    width: 128px;
    margin-bottom: 29px; }
  .page__header .help {
    margin-top: 32px;
    font-size: 18px; }
    @media (max-width: 46.24em) {
      .page__header .help {
        text-align: center;
        font-size: 15px; } }

.page__header h1 {
  text-align: center; }

.page__header h1, header h1 {
  font-size: 72px;
  line-height: 1.1;
  font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 800;
  color: black;
  margin: 0; }
  @media (max-width: 81.24em) {
    .page__header h1, header h1 {
      font-size: 55px; } }
  @media (max-width: 46.24em) {
    .page__header h1, header h1 {
      font-size: 37px;
      line-height: 1.1;
      text-align: center; } }

body.how-it-works .grid-container.--header .page-header {
  grid-template-columns: 4fr 6fr; }

.how-it-works__3-tier {
  padding: 0px 60px; }
  .how-it-works__3-tier h2 {
    font-size: 48px;
    line-height: 50.4px;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: #000;
    max-width: 564px; }
    @media (max-width: 46.24em) {
      .how-it-works__3-tier h2 {
        font-size: 38px; } }
  .how-it-works__3-tier ul {
    display: grid;
    grid-template-areas: "explore explore . meet meet . get get";
    grid-template-columns: 1fr 2fr 100px 1fr 2fr 100px 1fr 2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    width: 100%;
    list-style-type: none; }
    .how-it-works__3-tier ul li {
      display: flex;
      flex-direction: column; }
      .how-it-works__3-tier ul li p, .how-it-works__3-tier ul li h4 {
        margin: 3px 0; }
      .how-it-works__3-tier ul li h4 {
        color: #222;
        font-size: 24px;
        line-height: 130%; }
      .how-it-works__3-tier ul li p {
        color: rgba(44, 93, 144, 0.7); }
      .how-it-works__3-tier ul li:before {
        width: 72px;
        height: 72px;
        display: block;
        content: "";
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #4FADF2;
        background-repeat: no-repeat;
        background-position: center; }
    .how-it-works__3-tier ul li.explore {
      grid-area: explore; }
      .how-it-works__3-tier ul li.explore:before {
        background-image: url("../../img/how-it-works/explore.png"); }
    .how-it-works__3-tier ul li.meet {
      grid-area: meet;
      padding: 100px 0; }
      .how-it-works__3-tier ul li.meet:before {
        background-image: url("../../img/how-it-works/meet.png"); }
    .how-it-works__3-tier ul li.get {
      grid-area: get; }
      .how-it-works__3-tier ul li.get:before {
        background-image: url("../../img/how-it-works/get.png"); }
      @media screen and (min-width: 980px) {
        .how-it-works__3-tier ul li.get {
          justify-content: flex-start;
          margin-top: 11em; } }
    .how-it-works__3-tier ul:before, .how-it-works__3-tier ul:after {
      display: block;
      content: "";
      position: relative;
      background-size: 80%;
      background-position: 50% 0%;
      background-repeat: no-repeat;
      grid-row: 1; }
    .how-it-works__3-tier ul:before {
      grid-column: 2 / 4;
      background-image: url("/img/how-it-works/path1.png");
      background-position: 50% 10px; }
    .how-it-works__3-tier ul:after {
      grid-column: 5 / 7;
      background-image: url("/img/how-it-works/path2.png");
      background-position: 50% 150px; }
  @media (max-width: 61.24em) {
    .how-it-works__3-tier {
      padding: 0; }
      .how-it-works__3-tier h4 {
        font-size: 24px;
        line-height: 28px; }
      .how-it-works__3-tier ul {
        grid-template-areas: "explore" "meet" "get";
        grid-template-columns: 1fr; }
        .how-it-works__3-tier ul:before, .how-it-works__3-tier ul:after {
          display: none; }
        .how-it-works__3-tier ul li {
          padding: 32px 0 0 0 !important; }
        .how-it-works__3-tier ul li:before {
          width: 64px;
          height: 64px;
          margin-bottom: 16px; } }

.how-it-works__unis {
  margin: 50px 0 160px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 50px; }
  @media (max-width: 46.24em) {
    .how-it-works__unis {
      margin: 50px 0 90px 0; } }
  .how-it-works__unis img {
    width: 100%; }
  .how-it-works__unis img {
    width: 100%;
    height: auto;
    filter: grayscale(100%);
    opacity: 0.5;
    object-position: center;
    object-fit: contain; }
  @media (min-width: 46.25em) and (max-width: 61.24em) {
    .how-it-works__unis {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto auto; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .how-it-works__unis {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(1fr, 3); } }

.how-it-works__where {
  padding-top: 105px; }
  @media (max-width: 46.24em) {
    .how-it-works__where .grid-row__cell {
      align-items: flex-start; }
    .how-it-works__where div:first-child {
      order: 2; }
      .how-it-works__where div:first-child .button {
        align-self: center; }
    .how-it-works__where div:nth-child(2) {
      order: 1;
      padding-bottom: 64px; } }

.how-it-works__stats {
  padding-top: 105px; }
  @media (min-width: 81.25em) {
    .how-it-works__stats {
      padding-left: 100px;
      padding-right: 100px; } }

.how-it-works__why {
  margin-top: 133px; }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .how-it-works__why .button {
      margin-top: 50px; } }

.how-it-works__banner {
  background-image: url("../../img/how-it-works/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 240px;
  margin-bottom: 128px;
  padding-top: 128px;
  padding-bottom: 160px; }
  @media (max-width: 46.24em) {
    .how-it-works__banner {
      padding-top: 90px;
      padding-bottom: 120px;
      margin-bottom: 20px; } }
  @media (min-width: 20em) and (max-width: 46.24em) {
    .how-it-works__banner {
      margin-top: 100px; } }
  .how-it-works__banner h2 {
    font-size: 48px;
    line-height: 50px;
    margin: 0 0 30px 0;
    font-family: InterBold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    text-align: center; }
    @media (max-width: 46.24em) {
      .how-it-works__banner h2 {
        font-size: 38px; } }
  .how-it-works__banner p {
    margin: 0 0 40px 0;
    text-align: center;
    max-width: 530px; }
  .how-it-works__banner .grid__cell {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .how-it-works__banner .button {
    margin-bottom: 60px;
    align-self: center; }

.become-an-agent__what {
  margin-top: 200px; }
  @media (max-width: 46.24em) {
    .become-an-agent__what {
      margin-top: 30px; }
      .become-an-agent__what .grid-row__cell:nth-child(1) {
        order: 2; }
      .become-an-agent__what .grid-row__cell:nth-child(2) {
        order: 1; } }

.become-an-agent__why {
  margin-top: 175px; }
  @media (max-width: 46.24em) {
    .become-an-agent__why {
      margin-top: 100px; }
      .become-an-agent__why .grid-row__cell:nth-child(1) {
        order: 2; }
      .become-an-agent__why .grid-row__cell:nth-child(2) {
        order: 1; } }

.become-an-agent__benefits {
  margin-top: 114px; }
  .become-an-agent__benefits header * {
    text-align: center; }
  .become-an-agent__benefits img {
    width: auto; }

.become-an-agent__reviews {
  margin-top: 144px; }
  .become-an-agent__reviews p {
    color: black; }

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none; }

ul {
  margin: 0;
  padding: 0; }

img {
  width: 100%;
  height: auto; }

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
