


body, p, li, input, select, dd, dt {
	color: $darkblue;
	font-family: $sans;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $sans;	
  color: $dark;
  &.--blue {
    color: $darkestblue;
  }
}

a {
	color: $logoblue;
}

p, li {
	color: #000;
	font-size: 16px;
	@include mq($until: tablet) {
		font-size: 15px;
		line-height: 22px;
	}
}

dl {
	display: flex;
	flex-wrap: wrap;
	dt, dd {
		flex: 0 0 50%;
		padding: 0;
		margin: 0;
	}
	dt {
		font-size: 20px;
		font-family: $sansBold;
	}
	dd {
		font-size: 20px;
		font-family: $sans;
		margin-bottom: 20px;
	}
}

.bold {
	font-family: $sansBold;
}


.textAlign {
	&--center {
		text-align: center;
	}
}

.heading-set {
	.pretitle {
		font-weight: bold;
		font-size: 1.5em;
		line-height: 115%;
		color: $blue;
		margin-bottom: 0.3em;
	}
	h2, h3, h4, h5, h6 {
		font-weight: 800;
		font-size: 3em;
		line-height: 105%;
		margin-top: 0;
	}
	h4, h5, h6 {
		font-size: 2.375em;
		line-height: 115%;
	}
}


.grid-row__cell header {
	&.--center {
		h2, h3, {
			text-align: center;
		}
	}
}

.section-subtitle, header > h4, header > h5 {
	font-weight: 800;
	font-size: 18px;
	color: $blue;
	line-height: 1.3;

	@include mq($until: tablet) {
		font-size: 20px;
		line-height: 26px;
		margin: 20px 0 5px 0;
	}

	
	margin: 0;
}

.section-title, header > h2.large {
	font-weight: 800;
	font-size: 40px;
	font-family: $sansBold;
	line-height: 68px;
	color: black;

	@include mq($until: tablet) {
		font-size: 38px;
		line-height: 43.7px;
	}
}

.section-title.--small {
	font-size: 38px;
	line-height: 44px;
}

.section-title.--extra-spacing {
	margin: 2rem 0;
}

.section-small-title, header > h2 {
	font-weight: 800;
	font-size: 32px;
	font-family: $sansBold;
	line-height: 1.2;
	color: black;
	margin: 0 0 0.5rem 0;
}

.section-h3 {
	font-size: 38px;
	font-family: $sansBold;
	line-height: 1;
	color: black;
	//margin: 0 0 0.5rem 0;
}

.blog-title, .single-title {
	font-size: 36px;
	font-family: $sansBold;
	line-height: 1.2;
	color: black;
	@include mq($until: tablet) {
		font-size: 38px;
	}
}

.single__header {

	header {
		padding: 100px 0 48px 0;
	}

}

.blog-meta {
	font-family: $sansBold;
	font-weight: 800;
	font-size: 18px;
	line-height: 26px;
	color: $footer-grey;
}


header > h3 {
	font-weight: 800;
	font-size: 32px;
	line-height: 1.3;
	margin:0;

	@include mq($until: tablet) {
		font-size: 24px;
		line-height: 28px;
		margin: 0;
	}
	font-family: $sansBold;
	line-height: 115%;
	color: black;
	
}


header.page-title {

	margin-bottom: 100px;
	margin-top: 50px;
	h1, h2, h3 {
		color: #000;
		font-family: $sansBold;
		font-size: 42px;
    @include mq($until: wide) {
      font-size: 40px;
    }
    @include mq($until: tablet) {
      font-size: 38px;
    }
		font-weight: 800;
		line-height: 1;
		margin: 0;
	}
}



.blue {
	color: $blue;
}

.grey {
	color: $footer-grey;
}

.text {
	&.--invert {
		color: white;
	}
}


/* popup tooltip */
body .protip-container {
		background: #012d6c !important;
    line-height: 1.3 !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

body .protip-skin-default--scheme-pro[data-pt-position="right"] .protip-arrow {
  border-right-color: #012d6c !important;
}

body .protip-skin-default--scheme-pro[data-pt-position="top"] .protip-arrow {
  border-top-color: #012d6c !important;
}

body .protip-skin-default--scheme-pro[data-pt-position="bottom"] .protip-arrow {
  border-bottom-color: #012d6c !important;
}

body .protip-skin-default--scheme-pro[data-pt-position="left"] .protip-arrow {
  border-left-color: #012d6c !important;
}