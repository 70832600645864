body .freeform {
	margin-top: 50px;
}

.freeform-alert {
	font-size: 16px;
	padding: 1rem;
	border-radius: 0.5rem;
	font-family: $sansBold;
	color: #fff;
	background: $selectblue;
	margin-bottom: 1rem;
}

.freeform-alert.freeform-alert-success ~ div {
  display: none;
}

body .freeform-row {

	margin-bottom: 32px;

	.freeform-column {
		padding: 0;
	}

	.freeform-column .freeform-label.freeform-required::after {
		font-family: $sans;
		color: #012D6C;
	}


	@include mq(mobile, tablet) {
	
		flex-direction: column;
		margin-bottom: 0px;
	
		.freeform-column {
			margin-bottom: 20px;
		}
	}


	.freeform-input {
		height: 48px;
		font-size: 18px;
		line-height: 27px;
		
		border: 2px solid #acacac;
		border-radius: 10px;

		&[type=text], &[type=email] {
			padding: 0 16px;
			//justify-content: center;

		}

		font-family: $sans;
		color: #65666A;
	}

	label {
		padding-bottom: 8px;
		color: #012D6C;
	}

	textarea.freeform-input {
		min-height: 400px;
		padding: 32px;
	}

	.freeform-column.freeform-column-content-align-left {
		@include mq(tablet) {
			justify-content: flex-start;
		}
		@include mq(mobile, tablet) {
			justify-content: center;
		}
	}

}
