// TODO: Make mobile friendly with percentages for positioning
// TODO: Ensure order runs from left to right

.instagram-stack {
	@extend .container;
	position: relative;
	max-width: 1344px;
	height: 807px;
	@include mq($until: tablet) {
		height: 600px !important;
	}	
	top: -140px;
	margin-bottom: -140px;
	@include mq($until: desktop) {
		overflow-x: hidden;
	}

	pointer-events: none;

	&:after {
		content: '';
		background: url(../../img/blog/splash.png) center center no-repeat;
		width: 100%;
		max-width: 1302px;
		height: 807px;
		@include mq($until: tablet) {
			height: 600px !important;
		}
		background-size: contain;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}

	* {
		pointer-events: all;
	}
}

.instagram-stack__photo {
	max-width: 254px;
	position: absolute;
	transition: all 0.26s ease;
	transform: rotate(10deg);
	transform-origin: center;

	&:first-child {
		z-index: 5;
	}

	&:hover {
		transform: rotate(0deg) !important;
		z-index: 9;

		>.instagram-stack__photo-tap {
			display: inline;
		}
	}
	
	.instagram-source {
		display: none;
	}

	&.active {
		transform: scale(2) rotate(0deg) !important;
		z-index: 10;

		>.instagram-stack__photo-tap {
			display: none;
		}

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
		.instagram-source {
			display: inline-block;
			text-align: center;
			position: absolute;
			bottom: -2.6em;
			left: 50%;
			transform: translateX(-50%);
			background: fade-out(#000, 0.4);
			border-radius: 42px;
			font-size: 7px;
			font-weight: 600;
			letter-spacing: -0.3959px;
			color: #F3F0EB;
			padding: 0.3em 1em;
		}
		
	}

	>.instagram-stack__photo-tap {
		position: absolute;
		display: none;
		bottom: 1.2em;
		left: 0;
		right: 0;
		text-align: center;

		>.instagram-stack__photo-tap-caption {
			background: fade-out(#000, 0.4);
			border-radius: 42px;
			font-weight: bold;
			font-size: 1em;
			letter-spacing: -0.3959px;
			color: #F3F0EB;
			padding: 0.3em 1em;
		}
	}

	&:nth-child(1) {
		left: 0px;
		top: 174.02px;
		transform: scale(0.689) rotate(6.07deg);
	}

	&:nth-child(2) {
		left: 113.1px;
		top: 193px;
		transform: scale(0.5079) rotate(7.61deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(3) {
		left: 341px;
		top: 363.17px;
		transform: scale(0.4567) rotate(-22.48deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(4) {
		left: 175px;
		top: 362.52px;
		transform: scale(0.7402) rotate(-5.35deg);
	}

	&:nth-child(5) {
		left: 328px;
		top: 185.27px;
		transform: rotate(-8.44deg);
	}

	&:nth-child(6) {
		left: 431.18px;
		top: 424.66px;
		transform: scale(0.378) rotate(-13.53deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(7) {
		left: 567px;
		top: 168.7px;
		transform: scale(0.689) rotate(3.34deg);
	}

	&:nth-child(8) {
		left: 567px;
		top: 351.28px;
		transform: rotate(-2.64deg);
	}

	&:nth-child(9) {
		left: 764px;
		top: 247.24px;
		transform: scale(0.8819) rotate(-14.57deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(10) {
		left: 833.64px;
		top: 424.59px;
		transform: scale(0.2913) rotate(21.6deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(11) {
		left: 907px;
		top: 173.44px;
		transform: scale(0.6535) rotate(13.47deg);
	}

	&:nth-child(12) {
		left: 909.86px;
		top: 358px;
		transform: scale(0.5079) rotate(9.29deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(13) {
		left: 769.14px;
		top: 318px;
		transform: scale(0.3228) rotate(19.32deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}

	&:nth-child(14) {
		left: 1093.31px;
		top: 162px;
		transform: scale(0.8819) rotate(11.44deg);
	}

	&:nth-child(15) {
		left: 1103.14px;
		top: 395px;
		transform: scale(0.3228) rotate(19.32deg);

		&:hover>.instagram-stack__photo-tap {
			display: none;
		}
	}
}