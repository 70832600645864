.autocomplete-suggestions {
    box-sizing: border-box;
    border: 1px solid #999;
    background: #FFF;
    cursor: default;
    overflow: auto;
    display: block;
    max-width: 640px;
    top: 100%;
}

.autocomplete-suggestion {
    padding: 0.1rem 0.3rem;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    min-height: 40px;
    color: rgba(44, 93, 144, 1);
    .subheading {
        color: rgba(44, 93, 144, 0.75);
    }
    font-size: 18px;
    font-family: $sansBold !important;
    text-align: left;
    margin: 10px;
    border-radius: 7px;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
        background: #4FADF2;
        color: rgba(255, 255, 255, 1);
        .subheading {
            color: rgba(255, 255, 255, 0.75);
        }
        strong {
            color: #FFF;
        }
    }
}

.autocomplete-no-suggestion {
    padding: 0.1rem 0.3rem;
}

.autocomplete-selected {
    background: #F0F0F0;
}

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #21cbff;
    transition: all 0.25s ease-in-out;
}

.autocomplete-group {
    padding: 0.1rem 0.3rem;
    font-weight: bold;
    font-size: 1rem;
    color: #000;
    display: block;
    border-bottom: 1px solid #000;
}

.autocomplete-suggestion .subheading {
    font-size: 0.85rem;
    font-weight: normal;
}

.js-search-wrapper {

    margin-top: 40px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    button {
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1rem;
        text-indent: -999rem;
        height: calc(100% - 2rem);
        padding: 0 1rem;
        color: #FFF;
        font-family: $sansBold !important;
        font-size: .8rem;
        border: none;
        background: url(../../img/search.png) no-repeat center center;
    }

    &.searching {

    }

}


.autocomplete-suggestion {
    display: grid;
    grid-template-columns: 55px 1fr;
    grid-column-gap: 20px;
    grid-template-areas:
        "image title"
        "image location"
        "image course";
}

.autocomplete-suggestion__image {
    grid-area: image;
    img {
        width: 55px;
        height: 55px;
        object-fit: contain;
    }
}

.autocomplete-suggestion__title {
    margin-top: -6px;
    grid-area: title;
}

.autocomplete-suggestion__location {
    grid-area: location;
}

.autocomplete-suggestion__course {
    grid-area: course;
}

.js-search-wrapper {
    position: relative;
    max-width: 640px;
    &:after {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 3rem;
        top: 18px;
        content: "";
        display: block;
        background-size: contain;
    }

    &.searching:after {
        background-image: url(../../img/spinner.svg);
    }
}