.faves {

	background: rgba(#E7F6FA, 0.8);
	backdrop-filter: blur(50px);
	padding: 16px;
	width: 100%;

	/* Note: backdrop-filter has minimal browser support */
	border-radius: 16px;

	

	&__fave {

		a {
			display: contents;
		}

		display: grid;
		position: relative;
		grid-template-columns: 89px 1fr;
		grid-column-gap: 24px;
		grid-template-areas: "img content";
		border-radius: 10px;
		overflow: hidden;
		background: white;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__img {
		width: 96px;
		height: 96px;
		object-fit: cover;
		grid-area: img;
	}

	&__content {
		grid-area: content;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__title {
		font-size: 14px;
		margin: 0;
		color: #12141A;
		max-width: 80%;
		line-height: 1.2;
		margin-bottom: 0.3em;
	}

	&__location {
		font-size: 12px;
		line-height: 1.2;
		font-weight: 600;
		margin: 0;
		color: #12141A;
		opacity: 0.4;
		max-width: 80%;
	}

	&__close {
		position: absolute;
		display: block;
		text-indent: -999em;
		color: white;
		width: 16px;
		height: 16px;
		right: 16px;
		top: 16px;
		border-radius: 16px;
		background: #ACACAC;
		background-image: url('../../img/universities/x.png');
		background-position: center center;
		background-repeat: no-repeat;

	}

}
